import { HStack, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { ChangeMonthYearButton } from './changeButton';
import { MONTH_LABELS } from '../../../consts';
import { monthYearControlStyling } from '../styles';

interface Props {
  currentDate: Date;
  setCurrentDate: (currentDate: Date) => void;
}

export const MonthYearControl: FC<Props> = ({
  currentDate,
  setCurrentDate,
}) => (
  <HStack sx={{ ...monthYearControlStyling.container }}>
    <ChangeMonthYearButton
      aria-label="prev-year"
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
    />
    <Heading sx={{ ...monthYearControlStyling.header }}>
      {`${MONTH_LABELS[currentDate.getMonth()]} ${currentDate.getFullYear()}`}
    </Heading>
    <ChangeMonthYearButton
      aria-label="next-year"
      isForNextYear={true}
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
    />
  </HStack>
);
