import { colors } from '../../styles/colors';

const baseBorderStyling = {
  border: `0.0625rem solid ${colors.borders.financeCard}`,
  padding: '1rem',
  minWidth: '17.5rem',
  borderRadius: '0.75rem',
};

const baseContainer = {
  marginTop: '0.3125rem',
  marginBottom: '0.625rem',
  borderLeft: `0.0625rem solid ${colors.borders.financeCard}`,
  paddingLeft: '0.625rem',
};

export const financeCardStyling = {
  financeCardContainer: {
    ...baseBorderStyling,
    backgroundColor: 'white',
    display: 'block',
  },
  financeCardContainerAlternate: {
    ...baseBorderStyling,
    display: 'block',
    backgroundColor: 'blue.800',
    color: 'white',
  },
  header: {
    fontSize: '1rem',
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  subHeader: {
    fontSize: '12px',
  },
  numericField: {
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    fontWeight: 600,
  },
  smallText: {
    fontSize: '0.75rem',
    marginBottom: '0.3125rem',
    fontWeight: 400,
  },
  lighterText: { fontWeight: 400 },
  confirmedContainerMain: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginTop: '0.3125rem',
    marginBottom: '0.625rem',
    minHeight: '4.0625rem',
    minWidth: '50%',
    paddingRight: '0.625rem',
  },
  potentialContainerMain: {
    fontSize: '1.5rem',
    fontWeight: '600',
    minHeight: '4.0625rem',
    ...baseContainer,
  },
  confirmedContainer: {
    fontSize: '1rem',
    fontWeight: '600',
    marginTop: '0.3125rem',
    marginBottom: '0.625rem',
    minWidth: '50%',
    paddingRight: '0.625rem',
  },
  potentialContainer: {
    fontSize: '1rem',
    fontWeight: '400',
    ...baseContainer,
  },
};

export const financeCardsContainer = {
  alignSelf: 'baseline',
};
export const financeCardsFlexSubContainer = {
  marginTop: '1rem',
  marginBottom: '1rem',
};
