import { colors } from '../../styles/colors';

export const monthWorkingHoursStyles = {
  container: {
    border: `0.0625rem solid ${colors.borders.financeCard}`,
    padding: '1rem',
    maxWidth: '11.25rem',
    borderRadius: '0.75rem',
    backgroundColor: 'white',
    display: 'block',
  },
  header: {
    fontSize: '1rem',
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '1.5rem',
    fontWeight: '600',
    justifyContent: 'space-between',
  },
};
