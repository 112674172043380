import { colors } from '../../styles/colors';
import { StyleProps } from '@chakra-ui/react';

const baseChartStyling = {
  container: {
    border: `1px solid ${colors.borders.financeCard}`,
    padding: '1rem',
    borderRadius: '0.75rem',
    backgroundColor: 'white',
    display: 'block',
    maxWidth: '100%',
    width: 'max-content',
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: '0.5rem',
  },
  header: { marginRight: '1rem' },
  toggleContainer: { alignItems: 'center', flexWrap: 'wrap' },
  toggleTextContainer: { marginLeft: '0.5rem', fontSize: '0.75rem' },
};

export const pieChartStyling = {
  ...baseChartStyling,
  chart: { width: '18.75rem' },
};

export const barChartStyling = {
  ...baseChartStyling,
  chart: { width: '46.875rem' },
};

export const doughnutPieChartStyling = {
  ...baseChartStyling,
  chart: { width: '18.75rem', position: 'relative' },
  pie: function (twoCharts: boolean): StyleProps {
    return {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '50%',
      top: twoCharts ? '40%' : '45%',
      left: '50%',
    };
  },
};
