export const MONTH_LABELS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

export const MAX_BILLABLE_VALUE = 100;

export const CELL_KEYS_BLACKLIST = ['e', 'E', '+', '-'];

export const NUMERIC_MONTH_LABELS = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
];

export const LOADING = 'Ładuje...';
export const NO_DATA = 'Brak danych';
