import { QueryFunctionContext } from 'react-query';
import { MonthlyQueryKey } from '../../types/MonthlyQueryKey';
import { PersonnelCostForFetch } from './types/personnelCost';
import client from '../../api/axiosConfig';
import { personnelCostsApiUrl } from '../../utils/paths';

export const fetchPersonnelCosts = (
  context: QueryFunctionContext<MonthlyQueryKey>
) => {
  const [, year = '', month = ''] = context.queryKey;

  return client.get<{ results: PersonnelCostForFetch[] }>(
    `${personnelCostsApiUrl}?month=${month}&year=${year}`
  );
};
