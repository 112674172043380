import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { MdAdd } from 'react-icons/md';
import { drawerButtonStyling } from './styles';

interface Props {
  text: string;
  onClick: () => void;
}

const DrawerOpeningButton: FC<Props> = ({ text, onClick }) => {
  return (
    <Box>
      <Button
        sx={drawerButtonStyling.button}
        onClick={onClick}
        leftIcon={<Icon sx={drawerButtonStyling.icon} as={MdAdd} />}
      >
        <Text sx={drawerButtonStyling.text}>{text}</Text>
      </Button>
    </Box>
  );
};

export default DrawerOpeningButton;
