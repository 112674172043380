import { useQuery } from 'react-query';
import client from '../../../../api/axiosConfig';
import { Employee } from 'pages/personnelCosts/types/addEmployee';
import { employeeApiUrl } from 'utils/paths';

const useEmployeeQuery = () =>
  useQuery(
    'employees',
    () => client.get<{ results: Array<Employee> }>(employeeApiUrl),
    {}
  );

export default useEmployeeQuery;
