import { Text, VStack } from '@chakra-ui/react';
import { Column as ReactColumnType } from '@tanstack/react-table';
import { SelectOption } from 'types/selectOptions';
import { Select } from 'components/Select';
import { filtersStyling } from 'components/mainTable/styles';

interface SelectFilterProps<T> {
  column: ReactColumnType<T>;
  options: Array<SelectOption>;
  label?: string;
}

const SelectFilter = <T,>({ column, options, label }: SelectFilterProps<T>) => {
  const filterValue = column.getFilterValue()
    ? (column.getFilterValue() as string)
    : '';

  return (
    <VStack>
      <Text sx={{ ...filtersStyling.header }}>{label}</Text>
      <Select
        currentValue={options.find((option) => option.value === filterValue)}
        options={options}
        onChange={(value) => {
          if (value?.value) {
            column.setFilterValue(value.value);
          }
        }}
        variant="filter"
      />
    </VStack>
  );
};

export default SelectFilter;
