// request paths
const financesPrefix = 'finances/';

export const categoryApiUrl = `${financesPrefix}categories/`;
export const contractTypeApiUrl = `${financesPrefix}contract-types/`;
export const employeeApiUrl = `${financesPrefix}employee/`;
export const nonPersonnelCostsApiUrl = `${financesPrefix}non-personnel-costs/`;
export const nonPersonnelCostsBulkApiUrl = `${financesPrefix}non-personnel-costs-bulk/`;
export const nonPersonnelCostsAnnualApiUrl = `${financesPrefix}non-personnel-costs/annual`;
export const personnelCostsApiUrl = `${financesPrefix}personnel-costs/`;
export const personnelCostsBulkApiUrl = `${financesPrefix}personnel-costs-bulk/`;
export const personnelCostsAnnualApiUrl = `${financesPrefix}personnel-costs/annual`;
export const personnelIncomesApiUrl = `${financesPrefix}personnel-incomes/`;
export const personnelIncomesBulkApiUrl = `${financesPrefix}personnel-incomes-bulk/`;
export const personnelIncomesAnnualByEmployeeApiUrl = `${financesPrefix}personnel-incomes/annual/employees`;
export const personnelIncomesAnnualByProjectApiUrl = `${financesPrefix}personnel-incomes/annual/projects`;
export const positionApiUrl = `${financesPrefix}positions/`;
export const recurringIncomesApiUrl = `${financesPrefix}recurring-incomes/`;
export const recurringIncomesBulkApiUrl = `${financesPrefix}recurring-incomes-bulk/`;
export const recurringIncomesAnnualApiUrl = `${financesPrefix}recurring-incomes/annual`;
export const synchronizeWorkingHoursApiUrl = `/${financesPrefix}synchronize-working-hours/`;
export const workingHoursApiUrl = `${financesPrefix}working-hours/`;
export const projectsApiUrl = `${financesPrefix}projects/`;
export const loggedUserApiUrl = 'users/user/';

// page paths
export const basePath = '/';
export const nonPersonnelCostsPath = `/${financesPrefix}non-personnel-costs/`;
export const nonPersonnelCostsAnnualPath = `/${financesPrefix}non-personnel-costs/annual/`;
export const personnelIncomePath = `/${financesPrefix}personnel-incomes/`;
export const personnelIncomesAnnualByProjectPath = `/${financesPrefix}personnel-incomes/annual/projects/`;
export const personnelIncomesAnnualByEmployeePath = `/${financesPrefix}personnel-incomes/annual/employees/`;
export const personnelCostsPath = `/${financesPrefix}personnel-costs/`;
export const personnelCostsAnnualPath = `/${financesPrefix}personnel-costs/annual/`;
export const recurringIncomesPath = `/${financesPrefix}recurring-incomes/`;
export const recurringIncomesAnnualPath = `/${financesPrefix}recurring-incomes/annual/`;
export const settingsPath = `/${financesPrefix}settings/`;
export const summaryAnnualPath = `/${financesPrefix}summary-annual/`;
export const logoutPath = `/logout/`;
export const unauthorizedPath = `/unauthorized/`;
