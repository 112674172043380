import { HStack, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { ChangeYearButton } from './changeButton';
import { YearControlProps } from '../../../pages/settings/pageComponents/workingHours/types';
import { monthYearControlStyling } from '../styles';

export const YearControl: FC<YearControlProps> = ({
  currentYear,
  setCurrentYear,
}) => (
  <HStack sx={{ ...monthYearControlStyling.container }}>
    <ChangeYearButton
      aria-label="prev-year"
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
    />
    <Heading sx={{ ...monthYearControlStyling.header }}>
      {`${currentYear}`}
    </Heading>
    <ChangeYearButton
      aria-label="next-year"
      currentYear={currentYear}
      isForNextYear={true}
      setCurrentYear={setCurrentYear}
    />
  </HStack>
);
