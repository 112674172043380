import { Tbody, Tr, Td, Icon, Box } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
import { tableBaseStyling } from '../styles';
import { BodyProps } from '../types';

export const MainTableBody = <T,>({
  rows,
  checkIsPrediction,
  checkIsDeleted,
  checkIsAmountHasEverChanged,
  showDeletedItems,
  isExpandable,
  columnIdToChangeColor,
}: BodyProps<T>) => (
  <Tbody>
    {rows.map((row) => {
      if (showDeletedItems || !checkIsDeleted(row)) {
        return (
          <Tr
            sx={tableBaseStyling.tr(
              checkIsPrediction(row),
              row.getIsSelected()
            )}
            key={row.id}
          >
            {row.getVisibleCells().map((cell, index) => (
              <Td
                key={cell.id}
                color={
                  cell.column.id === columnIdToChangeColor &&
                  checkIsAmountHasEverChanged(row)
                    ? 'text.active'
                    : 'inherit'
                }
                sx={tableBaseStyling.td}
              >
                <>
                  {index === 1 && row.getCanExpand() && isExpandable && (
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                      }}
                    >
                      <Box sx={tableBaseStyling.iconContainer}>
                        <Icon
                          fontSize="1.5rem"
                          as={
                            row.getIsExpanded() ? MdExpandMore : MdChevronRight
                          }
                        />
                      </Box>
                    </button>
                  )}
                  <>
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      dynamicRowIndex: index,
                    })}
                  </>
                </>
              </Td>
            ))}
          </Tr>
        );
      }
    })}
  </Tbody>
);
