import { ChartOptions } from 'chart.js/dist/types';
import { Box } from '@chakra-ui/react';
import { Pie } from 'react-chartjs-2';
import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { PieChartData, PieChartTypes } from './types';
import { pieChartStyling } from '../styles';
import { CHART_COLORS, NO_BORDER_WIDTH } from '../consts';
import { sortPieChartData } from './utils';

const usePieChart = (
  pieChartData: PieChartData,
  reversedShowingOfOtherPieChart: boolean,
  mainHeader: string | undefined,
  pieChartSubHeader: string | undefined,
  secondPieChartData: PieChartData | undefined,
  secondPieChartSubHeader: string | undefined,
  alternateSecondPieChartSubHeader: string | undefined,
  alternateSecondPieChartData: PieChartData | undefined
) => {
  const isSecondPieChartVisible = useCallback(
    () =>
      secondPieChartData &&
      !isEqual(
        sortPieChartData(pieChartData),
        sortPieChartData(secondPieChartData)
      ),
    [pieChartData, secondPieChartData]
  );

  const getDataObj = useCallback(
    (chartData: PieChartData) => ({
      labels: chartData.labels,
      datasets: [
        {
          backgroundColor: CHART_COLORS,
          data: chartData.income,
        },
      ],
    }),
    []
  );

  const getPieChartSubheader = useCallback(
    (pieChartType: PieChartTypes) => {
      switch (pieChartType) {
        case 'main':
          return pieChartSubHeader;
        case 'second':
          return secondPieChartSubHeader;
        case 'alternateSecond':
          return alternateSecondPieChartSubHeader;
      }
    },
    [
      alternateSecondPieChartSubHeader,
      pieChartSubHeader,
      secondPieChartSubHeader,
    ]
  );

  const isPieChartDataValid = (pieChartData: PieChartData) =>
    pieChartData.income.some((value) => value !== 0);

  const isOtherPieChartVisible = useMemo(() => {
    if (alternateSecondPieChartData) return true;

    return !!(secondPieChartData && isSecondPieChartVisible());
  }, [
    alternateSecondPieChartData,
    isSecondPieChartVisible,
    secondPieChartData,
  ]);

  // This returns an object used to configure the pieChart, like the position of the legend,
  // whether should the chart subheader be visible etc.
  const getOptionsObj = useCallback(
    (pieChartType: PieChartTypes): ChartOptions<'pie'> => ({
      aspectRatio: 0.75,
      plugins: {
        title: {
          display: isOtherPieChartVisible,
          text: getPieChartSubheader(pieChartType),
          position: 'bottom',
        },
        legend: {
          display: true,
          position: 'bottom',
        },
      },
      ...NO_BORDER_WIDTH,
    }),
    [getPieChartSubheader, isOtherPieChartVisible]
  );

  const getPieChartToDisplay = useCallback(
    (pieChartData: PieChartData, optionsObjType: PieChartTypes) => (
      <Box sx={pieChartStyling.chart}>
        <Pie
          datasetIdKey="pie-filtered"
          options={getOptionsObj(optionsObjType)}
          data={getDataObj(pieChartData)}
        />
      </Box>
    ),
    [getDataObj, getOptionsObj]
  );

  const getSecondPieChart = useCallback(() => {
    if (!reversedShowingOfOtherPieChart) {
      if (alternateSecondPieChartData) {
        return getPieChartToDisplay(
          alternateSecondPieChartData,
          'alternateSecond'
        );
      }
      if (secondPieChartData && isSecondPieChartVisible()) {
        return getPieChartToDisplay(secondPieChartData, 'second');
      }
    } else {
      if (secondPieChartData && isSecondPieChartVisible()) {
        return getPieChartToDisplay(secondPieChartData, 'second');
      }
      if (alternateSecondPieChartData) {
        return getPieChartToDisplay(
          alternateSecondPieChartData,
          'alternateSecond'
        );
      }
    }
  }, [
    alternateSecondPieChartData,
    getPieChartToDisplay,
    isSecondPieChartVisible,
    reversedShowingOfOtherPieChart,
    secondPieChartData,
  ]);

  const isToggleButtonVisible = (
    alternateToggleSourceValue?: boolean,
    alternateToggleFunction?: (arg: boolean) => void
  ) => {
    if (alternateToggleSourceValue !== undefined && alternateToggleFunction) {
      return true;
    }

    return (
      secondPieChartData &&
      !isEqual(
        sortPieChartData(pieChartData),
        sortPieChartData(secondPieChartData)
      ) &&
      alternateSecondPieChartData
    );
  };

  const getToggleButtonText = useCallback(() => {
    if (reversedShowingOfOtherPieChart) {
      return 'Pokaż dla zaznaczonych';
    } else {
      return 'Pokaż dla wyfiltrowanych';
    }
  }, [reversedShowingOfOtherPieChart]);

  return {
    isToggleButtonVisible,
    getToggleButtonText,
    getOptionsObj,
    getDataObj,
    getSecondPieChart,
    isPieChartDataValid,
    sortPieChartData,
  };
};

export default usePieChart;
