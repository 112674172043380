import { useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Index } from './pages';
import { theme } from './styles/theme';
import CurrencyProvider from './contexts/CurrencyContext/currencyProvider';
import UserContext from './contexts/UserContext/userContext';
import UserProvider from './contexts/UserContext/userProvider';
import { CenteredSpinner } from 'components/centeredSpinner';
import { Logout } from 'pages/logout';
import { Unauthorized } from 'pages/unauthorized';
import { ToastContainer } from 'react-toastify';
import { ApiError } from 'types/apiError';
import { handleError } from 'utils/handleError';
import { summaryAnnualPath, unauthorizedPath } from 'utils/paths';
import { AdminRoutes } from './routes/admin';
import './assets/translations';
import '@fontsource/roboto/400.css';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError(err) {
        handleError(err as ApiError);
      },
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <UserProvider>
        <CurrencyProvider>
          <AuthenticatedApp />
          <ToastContainer />
        </CurrencyProvider>
      </UserProvider>
    </ChakraProvider>
  </QueryClientProvider>
);

const AuthenticatedApp = () => {
  const { loggedUser, isLoading } = useContext(UserContext);
  const defaultUrl = loggedUser?.isCompanyAdmin
    ? summaryAnnualPath
    : unauthorizedPath;

  if (isLoading) return <CenteredSpinner />;

  return (
    <Router>
      <Routes>
        {!loggedUser && <Route path="/" element={<Index />} />}
        {loggedUser && (
          <Route path="/unauthorized" element={<Unauthorized />} />
        )}
        {loggedUser && <Route path="/logout" element={<Logout />} />}
        {loggedUser?.isCompanyAdmin && (
          <Route path="/finances/*" element={<AdminRoutes />} />
        )}
        <Route path="*" element={<Navigate to={defaultUrl} />} />
      </Routes>
    </Router>
  );
};

export default App;
