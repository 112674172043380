import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AnnualQueryKey } from '../../../types/AnnualQueryKey';
import { fetchRecurringIncomesAnnual } from '../utils';

export const useRecurringIncomesAnnualQuery = (
  year: number,
  onlyAccepted: boolean
) => {
  const queryKey: AnnualQueryKey = useMemo(
    () => ['recurringIncomesAnnual', year, onlyAccepted],
    [onlyAccepted, year]
  );

  return useQuery(queryKey, fetchRecurringIncomesAnnual, {});
};
