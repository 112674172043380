import { Row as ReactRowType } from '@tanstack/react-table';
import { useCallback } from 'react';
import { DataFromCellEdit } from '../../../components/mainTable/types';
import { AxiosMutationResult, EntityWithId } from '../../../types';
import {
  PersonnelCostForCreate,
  PersonnelCostForEdit,
  PersonnelCostForFetch,
} from '../types/personnelCost';

export const usePersonnelCostActions = (
  remove: AxiosMutationResult<EntityWithId, PersonnelCostForFetch>,
  change: AxiosMutationResult<PersonnelCostForEdit, PersonnelCostForFetch>,
  create: AxiosMutationResult<PersonnelCostForCreate, PersonnelCostForFetch>,
  afterAction: () => void = () => undefined
) => {
  const mutateFromCellEdit = useCallback(
    (data: DataFromCellEdit<PersonnelCostForFetch>) => {
      const isNewValueNotNumeric = isNaN(+data.newValue);
      if (data.editedColumnId === 'amountAmount' && isNewValueNotNumeric)
        return;

      const dataForMutate: Record<string, string> = {};

      if (data.editedColumnId === 'lastNameFirstName') {
        const [lastName, firstName] = data.newValue.split(' ', 2);

        dataForMutate['employeeFirstName'] = firstName || '';
        dataForMutate['employeeLastName'] = lastName;
      } else {
        dataForMutate[data.editedColumnId] = data.newValue;
      }

      if (data.originalRow.id) {
        change.mutate({ id: data.originalRow.id, ...dataForMutate });
      } else {
        create.mutate({ ...data.originalRow, ...dataForMutate });
      }

      afterAction();
    },
    [afterAction, change, create]
  );

  const changeIsDeleted = useCallback(
    (
      row: ReactRowType<PersonnelCostForFetch>,
      data: { isDeleted: boolean }
    ) => {
      if (row.original.id) {
        change.mutate({
          id: row.original.id,
          isDeleted: data.isDeleted,
        });
      } else {
        create.mutate({
          ...row.original,
          ...data,
        });
      }

      afterAction();
    },
    [afterAction, change, create]
  );

  const accept = useCallback(
    (row: ReactRowType<PersonnelCostForFetch>) => {
      create.mutate({
        ...row.original,
      });

      afterAction();
    },
    [afterAction, create]
  );

  const unaccept = useCallback(
    (row: ReactRowType<PersonnelCostForFetch>) => {
      if (row.original.id) {
        remove.mutate({
          id: row.original.id,
        });
      }

      afterAction();
    },
    [afterAction, remove]
  );

  return { mutateFromCellEdit, changeIsDeleted, accept, unaccept };
};
