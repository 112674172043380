import { Numeral } from 'numeral';
import { useContext, useMemo } from 'react';
import { FinanceCardToDisplay } from '../../components/financeCard/types';
import CurrencyContext from '../../contexts/CurrencyContext/currencyContext';
import { SumsByCurrency } from '../../types/AnnualViews';
import { getValuesFromNumeral } from '../../utils/annualViews';

export const useFinanceCardsToDisplay = (
  dataByCurrency: SumsByCurrency,
  filteredDataByCurrency: SumsByCurrency,
  totalAmounts?: (Numeral | null | undefined)[],
  totalAmountsFiltered?: (Numeral | null | undefined)[],
  anyFilteredData?: boolean
) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const sumAll: FinanceCardToDisplay = useMemo(
    () => ({
      id: 1,
      header: 'Suma całkowita w roku',
      income: totalAmounts ? getValuesFromNumeral(totalAmounts) : [],
      filteredIncome: totalAmountsFiltered
        ? getValuesFromNumeral(totalAmountsFiltered)
        : [],
      selectedIncome: [],
      alt: true,
    }),
    [totalAmounts, totalAmountsFiltered]
  );

  const sumPLN: FinanceCardToDisplay = useMemo(
    () => ({
      id: 2,
      header: 'Suma roczna PLN',
      income: dataByCurrency.pln,
      filteredIncome: anyFilteredData ? filteredDataByCurrency.pln : [],
      selectedIncome: [],
    }),
    [anyFilteredData, dataByCurrency.pln, filteredDataByCurrency.pln]
  );

  const sumEUR: FinanceCardToDisplay = useMemo(
    () => ({
      id: 3,
      header: 'Suma roczna EUR',
      currencyData: currencyDataEUR,
      currencyDataPassed: true,
      income: dataByCurrency.eur,
      filteredIncome: anyFilteredData ? filteredDataByCurrency.eur : [],
      selectedIncome: [],
    }),
    [
      anyFilteredData,
      currencyDataEUR,
      dataByCurrency.eur,
      filteredDataByCurrency.eur,
    ]
  );

  const sumUSD: FinanceCardToDisplay = useMemo(
    () => ({
      id: 4,
      header: 'Suma roczna USD',
      currencyData: currencyDataUSD,
      currencyDataPassed: true,
      income: dataByCurrency.usd,
      filteredIncome: anyFilteredData ? filteredDataByCurrency.usd : [],
      selectedIncome: [],
    }),
    [
      anyFilteredData,
      currencyDataUSD,
      dataByCurrency.usd,
      filteredDataByCurrency.usd,
    ]
  );

  return [sumPLN, sumEUR, sumUSD, sumAll];
};
