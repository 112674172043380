import { cloneDeep } from 'lodash';
import numeral from 'numeral';
import { useMemo } from 'react';
import { sumNumeralArrays, sumNumerals } from '../../../utils';
import {
  AnnualPersonnelIncomeByEmployee,
  AnnualPersonnelIncomeByEmployeeRow,
} from '../types/annualPersonnelIncomesEmployee';
import { getAverageBillable } from '../utils';

export const useSumsForEmployees = (
  data?: AnnualPersonnelIncomeByEmployee[]
): AnnualPersonnelIncomeByEmployeeRow[] | undefined =>
  useMemo(
    () =>
      data?.map((income) => {
        const sums = sumNumeralArrays(
          cloneDeep(
            income.projects.map((project) =>
              project.amount.map(({ amountPLN }) => amountPLN || numeral(0))
            )
          )
        );

        return {
          ...income.employee,
          averageBillable: getAverageBillable(income),
          totalAnnualAmount: sumNumerals(sums),
          amount: sums.map((sum, index) => ({
            amount: null,
            billable:
              income.employee.billable[index] === undefined
                ? null
                : income.employee.billable[index],
            currency: null,
            amountPLN: sum,
          })),
          projects: income.projects,
        };
      }),
    [data]
  );
