import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { BarChartData } from '../../../components/Charts/BarChart/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';
import { sumArrays } from '../../../utils';
import { combineBarChartData } from '../utils';

export const useCostsBarChartData = (
  personnel: BarChartData,
  nonPersonnel: BarChartData
) =>
  useMemo(() => {
    const combined = combineBarChartData(personnel, nonPersonnel);

    const personnelBarChartData = cloneDeep(personnel);

    const nonPersonnelBarChartData = {
      horizontalAxislabels: NUMERIC_MONTH_LABELS,
      datasets: [
        {
          label: 'Suma miesięczna',
          data: sumArrays(nonPersonnel.datasets.map(({ data }) => data)),
        },
      ],
    };

    return {
      combined,
      personnel: personnelBarChartData,
      nonPersonnel: nonPersonnelBarChartData,
    };
  }, [nonPersonnel, personnel]);
