import { cloneDeep } from 'lodash';
import numeral from 'numeral';
import { useMemo } from 'react';
import { sumNumeralArrays } from '../../../utils';
import { AnnualPersonnelIncomeByProjectRow } from '../types/annualPersonnelIncomeByProject';

export const useSumsForProjects = (
  data?: AnnualPersonnelIncomeByProjectRow[]
): AnnualPersonnelIncomeByProjectRow[] | undefined =>
  useMemo(
    () =>
      data?.map((income) => {
        const sums = sumNumeralArrays(
          cloneDeep(
            income.subRows.map((subRow) =>
              subRow.amount.map(({ amountPLN }) => amountPLN || numeral(0))
            )
          )
        );

        return {
          ...income,
          amount: sums.map((sum) => ({
            amount: null,
            billable: null,
            currency: null,
            amountPLN: sum,
          })),
        };
      }),
    [data]
  );
