import { MAX_BILLABLE_VALUE } from '../../consts';

export const requiredValidation = {
  required: 'Pole jest wymagane',
} as const;

export const defaultValidation = {
  required: false,
} as const;

export const amountRequiredValidation = {
  ...requiredValidation,
  min: {
    value: 0,
    message: 'Kwota musi być liczbą dodatnią',
  },
};

export const billableRequiredValidation = {
  ...amountRequiredValidation,
  max: {
    value: MAX_BILLABLE_VALUE,
    message: 'Kwota nie może być większa niż 100',
  },
};
