import { HStack, IconButton } from '@chakra-ui/react';
import {
  MdOutlineDeleteSweep,
  MdOutlinePlaylistAddCheck,
  MdRemoveDone,
} from 'react-icons/md';
import { GeneralTableButtonTooltip } from './generalTableButtonTooltip';
import { UpdateFnForMainButtonInput } from '../types';
import { buttonIconStyling } from '../../../pages/settings/settingsTable/styles';

interface Props<T> {
  dataForUpdate: T[];
  updateFn: (dataForUpdate: UpdateFnForMainButtonInput<T>) => void;
  show?: boolean;
}

export const EditSelectedRowsButtons = <T,>({
  dataForUpdate,
  updateFn,
  show,
}: Props<T>) => {
  if (!show) return null;

  return (
    <HStack alignSelf="flex-start">
      <GeneralTableButtonTooltip label="Zaakceptuj wybrane">
        <IconButton
          aria-label="accept-selected"
          sx={{ ...buttonIconStyling }}
          icon={<MdOutlinePlaylistAddCheck />}
          onClick={() =>
            updateFn({
              dataForUpdate: dataForUpdate,
              updateTo: true,
              actionToPerfom: 'make_accepted_or_unaccepted',
            })
          }
        />
      </GeneralTableButtonTooltip>
      <GeneralTableButtonTooltip label="Odakceptuj wybrane">
        <IconButton
          aria-label="unaccept-selected"
          sx={{ ...buttonIconStyling }}
          icon={<MdRemoveDone />}
          onClick={() =>
            updateFn({
              dataForUpdate: dataForUpdate,
              updateTo: false,
              actionToPerfom: 'make_accepted_or_unaccepted',
            })
          }
        />
      </GeneralTableButtonTooltip>
      <GeneralTableButtonTooltip label="Ukryj/usuń wybrane">
        <IconButton
          aria-label="delete-selected"
          sx={{ ...buttonIconStyling }}
          icon={<MdOutlineDeleteSweep />}
          onClick={() =>
            updateFn({
              dataForUpdate: dataForUpdate,
              updateTo: true,
              actionToPerfom: 'delete',
            })
          }
        />
      </GeneralTableButtonTooltip>
    </HStack>
  );
};
