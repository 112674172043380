import { Box, Checkbox, CheckboxProps } from '@chakra-ui/react';
import { FC, useRef, useEffect } from 'react';

interface Props extends CheckboxProps {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox: FC<Props> = ({
  indeterminate,
  ...rest
}) => {
  const ref = useRef<{ isIndeterminate: boolean }>();

  const isChild = /\d+\./i.test(rest.id as string);
  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.isIndeterminate = !rest.isChecked && indeterminate;
    }
  }, [ref, indeterminate, rest.isChecked]);

  return (
    <Box width={'0.5rem'}>
      <Checkbox marginLeft={isChild ? '3rem' : '0'} {...ref} {...rest} />
    </Box>
  );
};
