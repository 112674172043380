import { FC } from 'react';
import { HStack, Switch as ChakraSwitch, Text } from '@chakra-ui/react';
import { CustomSwitchProps } from './types';

const Switch: FC<CustomSwitchProps> = ({
  label,
  labelFontSize,
  isChecked,
  onChange,
}) => (
  <HStack>
    <ChakraSwitch
      id="custom-switch"
      isChecked={isChecked}
      onChange={onChange}
    />
    <Text fontSize={labelFontSize}>{label}</Text>
  </HStack>
);

export default Switch;
