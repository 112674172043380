import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AnnualQueryKey } from '../../../types/AnnualQueryKey';
import { fetchPersonnelIncomesAnnualEmployee } from '../utils';

export const usePersonnelIncomesAnnualEmployeeQuery = (
  year: number,
  onlyAccepted: boolean
) => {
  const queryKey: AnnualQueryKey = useMemo(
    () => ['personnelIncomesAnnualEmployee', year, onlyAccepted],
    [onlyAccepted, year]
  );

  return useQuery(queryKey, fetchPersonnelIncomesAnnualEmployee, {});
};
