import numeral, { Numeral } from 'numeral';
import { UseQueryResult } from 'react-query';
import { BarChartData } from '../../components/Charts/BarChart/types';
import { FinanceCardToDisplay } from '../../components/financeCard/types';
import { NUMERIC_MONTH_LABELS } from '../../consts';
import { formatAmountNumeral, sumArrays, sumNumerals } from '../../utils';
import { AnnualNonPersonnelCost } from '../nonPersonnelCostsAnnual/types';
import { AnnualPersonnelCost } from '../personnelCostsAnnual/types/annualPersonnelCost';
import { AnnualPersonnelIncomeByProjectRow } from '../personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { AnnualRecurringIncome } from '../recurringIncomeAnnual/types/annualRecurringIncome';
import {
  AnnualSummaryCost,
  AnnualSummaryIncome,
  CostCategory,
  IncomeCategory,
} from './types';

export const mapToSummaryCost = (
  personnel?: AnnualPersonnelCost[],
  nonPersonnel?: AnnualNonPersonnelCost[]
): AnnualSummaryCost[] | undefined => {
  const personnelSummary =
    personnel?.map((cost) => ({
      name: `${cost.employeeLastName} ${cost.employeeFirstName}`,
      category: CostCategory.personnel,
      displayTotalAnnualAmount: cost.totalAnnualAmount,
      amount: cost.amountAmount.map((value) => ({
        amount: numeral(value),
        displayAmount: value,
      })),
    })) || [];

  const nonPersonnelSummary =
    nonPersonnel?.map((cost) => ({
      name: cost.costName,
      category: CostCategory.nonPersonnel,
      displayTotalAnnualAmount: cost.displayTotalAnnualAmount,
      amount: cost.amount.map(({ displayAmountPLN, amountPLN }) => ({
        amount: amountPLN,
        displayAmount: displayAmountPLN,
      })),
    })) || [];

  return [...personnelSummary, ...nonPersonnelSummary];
};

export const mapToSummaryIncome = (
  personnel?: AnnualPersonnelIncomeByProjectRow[],
  reccuring?: AnnualRecurringIncome[]
): AnnualSummaryIncome[] | undefined => {
  const personnelSummary =
    personnel?.map((income) => ({
      name: income.project?.name || '',
      category: IncomeCategory.personnel,
      displayTotalAnnualAmount: formatAmountNumeral(
        sumNumerals(
          income.subRows.map(({ totalAnnualAmount }) => totalAnnualAmount)
        )
      ),
      amount: income.amount.map(({ displayAmountPLN, amountPLN }) => ({
        amount: amountPLN,
        displayAmount: displayAmountPLN,
      })),
    })) || [];

  const reccuringSummary =
    reccuring?.map((income) => ({
      name: income.name,
      category: IncomeCategory.reccuring,
      displayTotalAnnualAmount: income.displayTotalAnnualAmount,
      amount: income.amount.map(({ displayAmountPLN, amountPLN }) => ({
        amount: amountPLN,
        displayAmount: displayAmountPLN,
      })),
    })) || [];

  return [...personnelSummary, ...reccuringSummary];
};

export const combineBarChartData = (
  firstData: BarChartData,
  secondData: BarChartData
): BarChartData => ({
  horizontalAxislabels: NUMERIC_MONTH_LABELS,
  datasets: [
    {
      label: 'Suma miesięczna',
      data: sumArrays([
        ...firstData.datasets.map(({ data }) => data),
        ...secondData.datasets.map(({ data }) => data),
      ]),
    },
  ],
});

export const combineFinanceCardToDisplay = (
  firstData: FinanceCardToDisplay,
  secondData: FinanceCardToDisplay,
  newHeader: string
): FinanceCardToDisplay => ({
  id: 1,
  header: newHeader,
  income: sumArrays(matchArrayLengths(firstData.income, secondData.income)),
  filteredIncome: [],
  selectedIncome: [],
  alt: true,
});

/** Pad passed arrays, so that they are the same length, fill any difference with zeros. */
export const matchArrayLengths = (
  firstArray: number[],
  secondArray: number[]
) => {
  const tempFirstArray = [...firstArray];
  const tempSecondArray = [...secondArray];

  if (tempFirstArray.length > tempSecondArray.length) {
    return [
      tempFirstArray,
      [
        ...tempSecondArray,
        ...Array<number>(tempFirstArray.length - tempSecondArray.length).fill(
          0
        ),
      ],
    ];
  } else if (tempFirstArray.length < tempSecondArray.length) {
    return [
      [
        ...tempFirstArray,
        ...Array<number>(tempSecondArray.length - tempFirstArray.length).fill(
          0
        ),
      ],
      tempSecondArray,
    ];
  } else {
    return [tempFirstArray, tempSecondArray];
  }
};

export const areQueriesLoading = (queries: UseQueryResult[]) =>
  queries.some(({ isLoading }) => isLoading);

export const getNormalProfit = (incomes: Numeral[], costs: Numeral[]) =>
  incomes.map((value, index) =>
    costs[index] ? value.subtract(costs[index].value()) : value
  );
