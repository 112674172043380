import { useMemo } from 'react';
import { BarChartData } from '../../../components/Charts/BarChart/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';
import { sumArrays } from '../../../utils';
import { combineBarChartData } from '../utils';

export const useIncomesBarChartData = (
  personnel: BarChartData,
  reccuring: BarChartData
) =>
  useMemo(() => {
    const combined = combineBarChartData(personnel, reccuring);

    const personnelBarChartData = {
      horizontalAxislabels: NUMERIC_MONTH_LABELS,
      datasets: [
        {
          label: 'Suma miesięczna',
          data: sumArrays(personnel.datasets.map(({ data }) => data)),
        },
      ],
    };

    const reccuringBarChartData = {
      horizontalAxislabels: NUMERIC_MONTH_LABELS,
      datasets: [
        {
          label: 'Suma miesięczna',
          data: sumArrays(reccuring.datasets.map(({ data }) => data)),
        },
      ],
    };

    return {
      combined,
      personnel: personnelBarChartData,
      reccuring: reccuringBarChartData,
    };
  }, [personnel, reccuring]);
