import { DoughnutPieChartData } from './types';

const isDataIdentical = (
  data: DoughnutPieChartData<number>,
  secondaryData?: DoughnutPieChartData<number>
) => {
  const pie = data.pieData.data.every(
    (value, index) => value === secondaryData?.pieData.data[index]
  );
  const doughnut = data.doughnutData.data.every(
    (value, index) => value === secondaryData?.doughnutData.data[index]
  );

  return pie && doughnut;
};

export const isSecondChartVisible = (
  data: DoughnutPieChartData<number>,
  secondaryData?: DoughnutPieChartData<number>
) => !!secondaryData && !isDataIdentical(data, secondaryData);
