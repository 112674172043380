import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NonPersonnelCosts } from '../pages/nonPersonnelCosts';
import NonPersonnelCostsAnnual from '../pages/nonPersonnelCostsAnnual';
import { PersonnelCosts } from '../pages/personnelCosts';
import { PersonnelCostsAnnual } from '../pages/personnelCostsAnnual';
import { PersonnelIncomesByEmployeeAnnual } from '../pages/personnelIncomesByEmployeeAnnual';
import { PersonnelIncomesByProjectAnnual } from '../pages/personnelIncomesByProjectAnnual';
import { RecurringIncomes } from '../pages/recurringIncome';
import { RecurringIncomeAnnual } from '../pages/recurringIncomeAnnual';
import { Settings } from '../pages/settings/pageFiles';
import { SummaryAnnual } from '../pages/summaryAnnual';
import { PersonnelIncomes } from 'pages/personnelIncomes';

export const AdminRoutes: FC = () => (
  <Routes>
    <Route path="/personnel-costs" element={<PersonnelCosts />} />
    <Route path="/personnel-costs/annual" element={<PersonnelCostsAnnual />} />
    <Route path="/recurring-incomes" element={<RecurringIncomes />} />
    <Route
      path="/recurring-incomes/annual"
      element={<RecurringIncomeAnnual />}
    />
    <Route path="/non-personnel-costs" element={<NonPersonnelCosts />} />
    <Route
      path="/non-personnel-costs/annual"
      element={<NonPersonnelCostsAnnual />}
    />
    <Route path="/personnel-incomes" element={<PersonnelIncomes />} />
    <Route
      path="/personnel-incomes/annual/projects"
      element={<PersonnelIncomesByProjectAnnual />}
    />
    <Route
      path="/personnel-incomes/annual/employees"
      element={<PersonnelIncomesByEmployeeAnnual />}
    />
    <Route path="/summary-annual" element={<SummaryAnnual />} />
    <Route path="/settings" element={<Settings />} />
  </Routes>
);
