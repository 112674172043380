import { useContext, useState } from 'react';
import CurrencyContext from '../../contexts/CurrencyContext/currencyContext';
import { FinanceCardsNonPersonnelCostsData } from '../../pages/nonPersonnelCosts/types';
import { FinanceCardToDisplay } from './types';

export const useFinanceCards = () => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const [financeCardsData, setFinanceCardsData] = useState<
    FinanceCardsNonPersonnelCostsData | undefined
  >();
  const [financeCardsFilteredData, setFinanceCardsFilteredData] = useState<
    FinanceCardsNonPersonnelCostsData | undefined
  >();
  const [financeCardsSelectedData, setFinanceCardsSelectedData] = useState<
    FinanceCardsNonPersonnelCostsData | undefined
  >();

  const sumFinanceCardToDisplay: FinanceCardToDisplay[] = [
    {
      id: 1,
      header: `Suma całkowita w PLN`,
      currencyData: undefined,
      income: financeCardsData?.PLN.totalIncomeConvertedToPLN ?? [],
      incomePotential:
        financeCardsData?.PLN.totalIncomeConvertedToPLNPotential ?? [],
      filteredIncome:
        financeCardsFilteredData?.PLN.totalIncomeConvertedToPLN ?? [],
      filteredIncomePotential:
        financeCardsFilteredData?.PLN.totalIncomeConvertedToPLNPotential ?? [],
      selectedIncome:
        financeCardsSelectedData?.PLN.totalIncomeConvertedToPLN ?? [],
      selectedIncomePotential:
        financeCardsSelectedData?.PLN.totalIncomeConvertedToPLNPotential ?? [],
      alt: true,
    },
  ];

  const financeCardsToDisplay: FinanceCardToDisplay[] = [
    {
      id: 1,
      header: `Suma PLN`,
      currencyData: undefined,
      income: financeCardsData?.PLN.income ?? [],
      incomePotential: financeCardsData?.PLN.incomePotential ?? [],
      filteredIncome: financeCardsFilteredData?.PLN.income ?? [],
      filteredIncomePotential:
        financeCardsFilteredData?.PLN.incomePotential ?? [],
      selectedIncome: financeCardsSelectedData?.PLN.income ?? [],
      selectedIncomePotential:
        financeCardsSelectedData?.PLN.incomePotential ?? [],
      alt: undefined,
    },
    {
      id: 2,
      header: `Suma ${currencyDataEUR?.code ?? 'EUR'}`,
      currencyData: currencyDataEUR,
      income: financeCardsData?.EUR.income ?? [],
      incomePotential: financeCardsData?.EUR.incomePotential ?? [],
      filteredIncome: financeCardsFilteredData?.EUR.income ?? [],
      filteredIncomePotential:
        financeCardsFilteredData?.EUR.incomePotential ?? [],
      selectedIncome: financeCardsSelectedData?.EUR.income ?? [],
      selectedIncomePotential:
        financeCardsSelectedData?.EUR.incomePotential ?? [],
      alt: undefined,
      currencyDataPassed: true,
    },
    {
      id: 3,
      header: `Suma ${currencyDataUSD?.code ?? 'USD'}`,
      currencyData: currencyDataUSD,
      income: financeCardsData?.USD.income ?? [],
      incomePotential: financeCardsData?.USD.incomePotential ?? [],
      filteredIncome: financeCardsFilteredData?.USD.income ?? [],
      filteredIncomePotential:
        financeCardsFilteredData?.USD.incomePotential ?? [],
      selectedIncome: financeCardsSelectedData?.USD.income ?? [],
      selectedIncomePotential:
        financeCardsSelectedData?.USD.incomePotential ?? [],
      alt: undefined,
      currencyDataPassed: true,
    },
    {
      id: 4,
      header: `Suma całkowita w PLN`,
      currencyData: undefined,
      income: financeCardsData?.PLN.totalIncomeConvertedToPLN ?? [],
      incomePotential:
        financeCardsData?.PLN.totalIncomeConvertedToPLNPotential ?? [],
      filteredIncome:
        financeCardsFilteredData?.PLN.totalIncomeConvertedToPLN ?? [],
      filteredIncomePotential:
        financeCardsFilteredData?.PLN.totalIncomeConvertedToPLNPotential ?? [],
      selectedIncome:
        financeCardsSelectedData?.PLN.totalIncomeConvertedToPLN ?? [],
      selectedIncomePotential:
        financeCardsSelectedData?.PLN.totalIncomeConvertedToPLNPotential ?? [],
      alt: true,
    },
  ];

  return {
    financeCardsData,
    setFinanceCardsData,
    financeCardsSelectedData,
    setFinanceCardsSelectedData,
    financeCardsFilteredData,
    setFinanceCardsFilteredData,
    financeCardsToDisplay,
    sumFinanceCardToDisplay,
  };
};
