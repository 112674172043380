import { Box, Button } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../api/axiosConfig';
import { FC } from 'react';
import { WorkingHour } from './types';
import { synchronizeWorkingHoursApiUrl } from '../../../../utils/paths';

interface Props {
  selectedYear: number;
}

export const SynchronizeWorkingHours: FC<Props> = ({ selectedYear }) => {
  const queryClient = useQueryClient();

  const synchronizeWorkingHours = useMutation(
    (selectedYear: number) => {
      return client.post<{ results: WorkingHour }>(
        `${synchronizeWorkingHoursApiUrl}?year=${selectedYear}`
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(`workingHours - ${selectedYear}`, data);
      },
    }
  );

  return (
    <Box top={'0'} right={'0'} position={'fixed'}>
      <Button
        background={'active'}
        color={'white'}
        onClick={() => synchronizeWorkingHours.mutate(selectedYear)}
      >
        Synchronizuj ten rok
      </Button>
    </Box>
  );
};
