import { useEffect, useState } from 'react';
import { Getter, Row } from '@tanstack/react-table';
import { Select } from '../../Select';
import { DataFromCellEdit } from '../types';
import { SelectOption } from '../../../types/selectOptions';

interface Props<T> {
  getValue: Getter<T>;
  originalRow: Row<T>;
  columnId: string;
  id: string;
  onCellBlur: (dataFromCellEdit: DataFromCellEdit<T>) => void;
  options: SelectOption[];
}

export const SelectFieldEditableTableCell = <T,>({
  getValue,
  originalRow,
  columnId,
  id,
  onCellBlur,
  options,
}: Props<T>) => {
  const initialValue = getValue() as unknown as string;
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const isDisabled = !!originalRow.subRows.length;
  return (
    <Select
      id={id}
      options={options}
      isDisabled={isDisabled}
      onChange={(changedValue) => {
        if (changedValue?.value) {
          setValue(changedValue.value);
          onCellBlur({
            originalRow: originalRow.original,
            editedColumnId: columnId,
            newValue: changedValue.value,
          });
        }
      }}
      currentValue={options.find((option) => option.value === value)}
      variant="tableField"
    />
  );
};
