import { useMemo } from 'react';
import { AnnualPersonnelIncomeByProjectRow } from '../../personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { AnnualRecurringIncome } from '../../recurringIncomeAnnual/types/annualRecurringIncome';
import { mapToSummaryIncome } from '../utils';

export const useMappedAnnualSummaryIncome = (
  personnel?: AnnualPersonnelIncomeByProjectRow[],
  reccuring?: AnnualRecurringIncome[]
) =>
  useMemo(
    () => mapToSummaryIncome(personnel, reccuring),
    [personnel, reccuring]
  );
