export const loginHeading = {
  textAlign: 'center',
  fontWeight: 300,
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
};

export const loginButton = {
  backgroundColor: 'light',
  borderRadius: '32px',
  padding: '6px 4px 6px 4px',
  marginBottom: '8px',
  boxShadow: '0 3px 6px #00000029',
};
