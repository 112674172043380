import { iconSize } from 'styles/global';

export const drawerButtonStyling = {
  button: {
    marginLeft: 'auto',
    w: '16rem',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'blue.600',
    color: 'white',
    _hover: { backgroundColor: 'blue.600' },
    _focus: { backgroundColor: 'blue.600' },
  },
  icon: iconSize,
  text: { lineHeight: '1' },
};
