import { QueryFunctionContext } from 'react-query';
import { MonthlyQueryKey } from '../../types/MonthlyQueryKey';
import { recurringIncomesApiUrl } from '../../utils/paths';
import client from '../../api/axiosConfig';
import { RecurringIncomeForFetch } from './types';

export const fetchRecurringIncome = (
  context: QueryFunctionContext<MonthlyQueryKey>
) => {
  const [, year = '', month = ''] = context.queryKey;

  return client.get<{ results: RecurringIncomeForFetch[] }>(
    `${recurringIncomesApiUrl}?month=${month}&year=${year}`
  );
};
