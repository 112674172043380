import { Row as ReactRowType } from '@tanstack/react-table';
import { BaseRecord } from '../types';

export const checkIsPrediction = <T extends BaseRecord>(
  row: ReactRowType<T>
): boolean => !row.original.id;

export const checkIsDeleted = <T extends BaseRecord>(
  row: ReactRowType<T>
): boolean => row.original.isDeleted;

export const checkIsAmountHasEverChanged = <T extends BaseRecord>(
  row: ReactRowType<T>
): boolean => row.original.isAmountChanged;
