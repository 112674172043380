import { Box } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { CHART_COLORS } from '../consts';
import { barChartStyling } from '../styles';
import { BAR_CHART_OPTIONS } from './consts';
import { BarChartData, BaseBarChartData } from './types';

const getDataObj = (
  chartData: BarChartData | BaseBarChartData,
  shiftColorIndex = 0
) => ({
  labels:
    'horizontalAxislabels' in chartData
      ? chartData.horizontalAxislabels
      : undefined,
  datasets: chartData.datasets.map((dataset, index) => ({
    ...dataset,
    stack: 'Stack 0',
    backgroundColor: CHART_COLORS[index + shiftColorIndex],
  })),
});

const getDoubleBarChartDataObj = (
  firstChartData: BarChartData,
  secondChartData: BaseBarChartData
) => {
  const firstChartDataObj = getDataObj(firstChartData);
  const secondChartDataObj = getDataObj(
    secondChartData,
    firstChartData.datasets.length
  );

  return {
    labels: firstChartDataObj.labels,
    datasets: [
      ...firstChartDataObj.datasets.map((dataset) => ({
        ...dataset,
        stack: 'Stack 0',
      })),
      ...secondChartDataObj.datasets.map((dataset) => ({
        ...dataset,
        stack: 'Stack 1',
      })),
    ],
  };
};

// This function checks, whether given barChartData should be passed to Bar component.
// We don't want to display chart with datasets containing only zeros
// or without any datasets at all.
export const isBarChartDataValid = (barChartData: BaseBarChartData) => {
  if (!barChartData.datasets.length) return false;

  const allZeros = barChartData.datasets.every(({ data }) =>
    data.every((value) => value === 0)
  );

  return !allZeros;
};

export const getBarChartToDisplay = (
  firstBarChartData: BarChartData,
  secondBarChartData?: BaseBarChartData
) => {
  const tempFirstData = cloneDeep(firstBarChartData);
  const tempSecondData = cloneDeep(secondBarChartData);

  const isSecondBarChartDataValid =
    tempSecondData && isBarChartDataValid(tempSecondData);

  return (
    <Box sx={barChartStyling.chart}>
      <Bar
        datasetIdKey="bar-filtered"
        options={BAR_CHART_OPTIONS}
        data={
          tempSecondData && isSecondBarChartDataValid
            ? getDoubleBarChartDataObj(tempFirstData, tempSecondData)
            : getDataObj(tempFirstData)
        }
      />
    </Box>
  );
};

export const isBarChartDataIdentical = (
  firstBarChartData: BaseBarChartData,
  secondBarChartData: BaseBarChartData
) =>
  firstBarChartData.datasets.every(({ data }, datasetIndex) =>
    data.every(
      (value, dataIndex) =>
        value === secondBarChartData.datasets[datasetIndex]?.data[dataIndex]
    )
  );
