export const CONTAINERS_PADDING = '1rem';

export const iconSize = { fontSize: '1.5rem' };

export const basicContainer = {
  alignSelf: 'flex-start',
  width: '100%',
  paddingX: CONTAINERS_PADDING,
};

export const h1Typography = {
  fontSize: '1.5rem',
  fontStyle: '400',
};

export const mainVStack = {
  alignSelf: 'flex-start',
  // 80px is the width of the sidebar (56px) + margin on mainVStack (8px) + padding on mainVStack (16px)
  width: 'calc(100% - 80px)',
  padding: `0 0 ${CONTAINERS_PADDING} ${CONTAINERS_PADDING}`,
  marginInline: '0',
};

export const subHStack = { width: '100%', gap: '1%', alignItems: 'flex-start' };

export const pageControlsHStack = {
  width: '100%',
  justifyContent: 'space-between',
};
