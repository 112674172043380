import { useEffect, useMemo, useState } from 'react';
import { Box, HStack, Table, TableContainer, VStack } from '@chakra-ui/react';
import {
  ColumnFiltersState,
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row as ReactRowType,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { EditSelectedRowsButtons } from './components/editSelectedRowsButtons';
import { GeneralTableActionsButtons } from './components/generalTableActionsButtons';
import StringFilter from './Filters/StringFilter';
import { Filters } from './Filters';
import ActiveFilterTags from './Filters/ActiveFilterTags';
import { MainTableProps } from './types';
import {
  filtersStyling,
  hstackStyling,
  tableBaseStyling,
  tableContainerStyling,
} from './styles';
import { flattenColumnInfo } from './utils';
import { useColumns, useKeys, useSortingByIsAccepted } from './hooks';
import { MainTableHead } from './components/mainTableHead';
import { MainTableBody } from './components/mainTableBody';

/**
 * Main table reusable component, note that the table allows displaying nested data
 */
export const MainTable = <T,>({
  data,
  columnInfo,
  tabFocusableColumnId,
  deleteInstance,
  acceptInstance,
  unacceptInstance,
  checkIsPrediction = () => false,
  checkIsDeleted = () => false,
  checkIsAmountHasEverChanged = () => false,
  updateFnForMainButton = () => undefined,
  updateFnForCellEdit = () => undefined,
  columnIdToChangeColor,
  onClickAcceptAllButton = () => undefined,
  isModalOpen,
  initialSorting,
  setCurrentTableRows,
  setSelectedTableRows,
  isExpandable = false,
  getSubRowsPassed,
  tableExtraButton,
  rowsEditableByButtons = true,
}: MainTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [showDeletedItems, setShowDeletedItems] = useState(false);
  const [focusedRow, setFocusedRow] = useState(-1);
  const columnHelper = createColumnHelper<T>();
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const flatColumnInfo = useMemo(
    () => flattenColumnInfo(columnInfo),
    [columnInfo]
  );

  const { columns } = useColumns(
    columnInfo,
    flatColumnInfo,
    columnHelper,
    setFocusedRow,
    updateFnForCellEdit,
    checkIsPrediction,
    checkIsDeleted,
    rowsEditableByButtons,
    deleteInstance,
    acceptInstance,
    unacceptInstance,
    tableExtraButton,
    tabFocusableColumnId,
    isModalOpen,
    isExpandable
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
      expanded,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => (getSubRowsPassed ? getSubRowsPassed(row) : undefined),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { sortedTableRows, onClickSortByAcceptedButton, sortingByIsAccepted } =
    useSortingByIsAccepted(table.getRowModel().rows, checkIsPrediction);

  const columnWithSearch = useMemo(() => {
    const info = flatColumnInfo.find(
      (columnFilter) => columnFilter.filterFn === 'includesString'
    );

    if (!info) return;

    return table.getColumn(info.id);
  }, [flatColumnInfo, table]);

  const columnsWithFilters = useMemo(
    () => flatColumnInfo.some((info) => info.filterFn !== undefined),
    [flatColumnInfo]
  );

  useEffect(() => {
    if (setCurrentTableRows) {
      setCurrentTableRows(sortedTableRows);
    }
  }, [setCurrentTableRows, sortedTableRows]);

  useEffect(() => {
    const selectedRowIds = Object.keys(rowSelection);
    if (selectedRowIds && selectedRowIds.length > 0) {
      const filteredRows: ReactRowType<T>[] = [];
      selectedRowIds.forEach((selectedRowId) => {
        const foundRow = sortedTableRows.find(
          (sortedTableRow) => sortedTableRow.id === selectedRowId
        );
        if (foundRow) {
          filteredRows.push(foundRow);
        }
      });
      if (setSelectedTableRows) {
        setSelectedTableRows(filteredRows);
      }
    } else {
      if (setSelectedTableRows) {
        setSelectedTableRows([]);
      }
    }
  }, [sortedTableRows, rowSelection, setSelectedTableRows]);

  useKeys(setFocusedRow, sortedTableRows.length, isModalOpen);

  useEffect(() => {
    const tabFocusableInputs = document.querySelectorAll<HTMLInputElement>(
      '.tab-focusable-input'
    );
    tabFocusableInputs[focusedRow]?.focus();
  }, [focusedRow]);

  useEffect(() => {
    if (table.toggleAllRowsExpanded) {
      table.toggleAllRowsExpanded();
    }
  }, [table]);

  return (
    <VStack width="100%">
      {columnsWithFilters && (
        <Box sx={tableContainerStyling.filters}>
          <Filters<T> columnInfo={flatColumnInfo} getColumn={table.getColumn} />
          <ActiveFilterTags
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        </Box>
      )}

      <VStack sx={tableContainerStyling.table}>
        <HStack sx={hstackStyling(rowsEditableByButtons)}>
          {rowsEditableByButtons && (
            <HStack>
              <GeneralTableActionsButtons
                onClickAcceptAllButton={onClickAcceptAllButton}
                showDeletedItems={showDeletedItems}
                onClickShowHideButton={() =>
                  setShowDeletedItems(!showDeletedItems)
                }
                onClickSortByAcceptedButton={() =>
                  onClickSortByAcceptedButton(sortingByIsAccepted)
                }
              />
              <EditSelectedRowsButtons
                dataForUpdate={table
                  .getSelectedRowModel()
                  .flatRows.map((flatRow) => flatRow.original)}
                updateFn={(dataForUpdate) => {
                  updateFnForMainButton(dataForUpdate);
                  table.resetRowSelection();
                }}
                show={
                  table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                }
              />
            </HStack>
          )}

          <Box sx={filtersStyling.searchFieldContainer}>
            {columnWithSearch && <StringFilter column={columnWithSearch} />}
          </Box>
        </HStack>

        <TableContainer sx={tableBaseStyling.container}>
          <Table sx={tableBaseStyling.table} variant="unstyled">
            <MainTableHead headers={table.getHeaderGroups()} />
            <MainTableBody
              rows={sortedTableRows}
              checkIsPrediction={checkIsPrediction}
              checkIsAmountHasEverChanged={checkIsAmountHasEverChanged}
              checkIsDeleted={checkIsDeleted}
              showDeletedItems={showDeletedItems}
              isExpandable={isExpandable}
              columnIdToChangeColor={columnIdToChangeColor}
            />
          </Table>
        </TableContainer>
      </VStack>
    </VStack>
  );
};
