import numeral from 'numeral';
import { useContext, useMemo } from 'react';
import { CurrencyCodes, sumNumerals } from '../../../utils';
import { AnnualRecurringIncome } from '../types/annualRecurringIncome';
import CurrencyContext from 'src/contexts/CurrencyContext/currencyContext';

export const useAmountsInPLN = (data?: AnnualRecurringIncome[]) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const newData: AnnualRecurringIncome[] | undefined = useMemo(
    () =>
      data?.map((annualRecurringIncome) => {
        const amountPLN = annualRecurringIncome.amount.map((amountValue) => {
          if (
            amountValue.currency === CurrencyCodes.PolishZloty ||
            amountValue.currency === null
          ) {
            return numeral(amountValue.amount);
          } else if (
            amountValue.currency === CurrencyCodes.UnitedStatesDollar
          ) {
            return currencyDataUSD
              ? numeral(amountValue.amount).multiply(currencyDataUSD.bid)
              : undefined;
          } else if (amountValue.currency === CurrencyCodes.Euro) {
            return currencyDataEUR
              ? numeral(amountValue.amount).multiply(currencyDataEUR.bid)
              : undefined;
          }
        });

        return {
          ...annualRecurringIncome,
          amount: annualRecurringIncome.amount.map((value, index) => ({
            ...value,
            amountPLN: amountPLN[index],
          })),
          totalAnnualAmount: sumNumerals(amountPLN),
        };
      }),
    [currencyDataEUR, currencyDataUSD, data]
  );

  return newData;
};
