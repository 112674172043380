import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { pl } from './languages';

const TRANSLATION_MISSING_KEY = 'No translation for key';

const resources = {
  pl: { translation: pl },
};

void i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: 'v3',
  lng: 'pl',
  fallbackLng: 'pl',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  parseMissingKeyHandler: (key: string) => `${TRANSLATION_MISSING_KEY} ${key}`,
});

export default i18n;
