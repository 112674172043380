import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AnnualQueryKey } from '../../../types/AnnualQueryKey';
import { fetchpersonnelCostsAnnual } from '../utils';

export const usePersonnelCostsAnnualQuery = (
  year: number,
  onlyAccepted: boolean
) => {
  const queryKey: AnnualQueryKey = useMemo(
    () => ['personnelCostsAnnual', year, onlyAccepted],
    [onlyAccepted, year]
  );

  return useQuery(queryKey, fetchpersonnelCostsAnnual, {});
};
