import { useMemo } from 'react';
import { PersonnelCostForFetch } from '../types/personnelCost';
import { ColumnInfo } from '../../../components/mainTable/types';
import { ContractType } from '../../settings/pageComponents/contractType/types';
import { Position } from '../../settings/pageComponents/position/types';
import { AxiosQueryResult } from '../../../types';

export const usePersonnelCostsColumnDef = (
  positions: AxiosQueryResult<Position>,
  contractTypes: AxiosQueryResult<ContractType>
) => {
  const columnInfo: Array<ColumnInfo<PersonnelCostForFetch>> = useMemo(
    () => [
      {
        id: 'lastNameFirstName',
        header: 'Nazwisko i imię',
        getRow: (row: PersonnelCostForFetch) =>
          `${row.employeeLastName} ${row.employeeFirstName}`,
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
        editable: 'textInputField' as const,
      },
      {
        id: 'positionName',
        header: 'Klasa',
        getRow: (row: PersonnelCostForFetch) => row.positionName,
        sortType: 'text' as const,
        columnOptions: positions.data?.data.results.map(({ name }) => ({
          value: name,
          label: name,
        })),
        editable: 'select' as const,
      },
      {
        id: 'billable',
        header: 'Billable',
        getRow: (row: PersonnelCostForFetch) =>
          row.positionBillable ? `${row.positionBillable}%` : '-',
        sortType: 'alphanumeric' as const,
      },
      {
        id: 'type',
        header: 'Typ',
        getRow: (row: PersonnelCostForFetch) => row.type,
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Typ umowy',
        columnOptions: contractTypes.data?.data.results.map(({ name }) => ({
          value: name,
          label: name,
        })),
        editable: 'textInputField' as const,
      },
      {
        id: 'amountAmount',
        header: 'Kwota',
        getRow: (row: PersonnelCostForFetch) => row.amountAmount,
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
        editable: 'numericInputField' as const,
      },
    ],
    [contractTypes.data?.data.results, positions.data?.data.results]
  );

  return { columnInfo };
};
