export const exchangeRateTableCEndpointURL =
  'https://api.nbp.pl/api/exchangerates/tables/c/';

export interface ExchangeRatesTableCResponse {
  effectiveDate: string;
  no: string;
  rates: CurrencyData[];
}

export interface CurrencyData {
  currency: string;
  code: string;
  bid: number;
  ask: number;
}
