import { useMemo } from 'react';
import { AnnualNonPersonnelCost } from '../../nonPersonnelCostsAnnual/types';
import { AnnualPersonnelCost } from '../../personnelCostsAnnual/types/annualPersonnelCost';
import { mapToSummaryCost } from '../utils';

export const useMappedAnnualSummaryCost = (
  personnel?: AnnualPersonnelCost[],
  nonPersonnel?: AnnualNonPersonnelCost[]
) =>
  useMemo(
    () => mapToSummaryCost(personnel, nonPersonnel),
    [nonPersonnel, personnel]
  );
