export const emptyStateStyles = {
  container: {
    width: '100%',
    backgroundColor: 'white',
    padding: '3.75rem',
    borderWidth: '0.0625rem',
    borderRadius: '0.75rem',
    borderColor: 'borders.container',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '2.25rem',
  },
  text: { fontSize: '1.25rem' },
};
