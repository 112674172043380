import { useMutation } from 'react-query';
import { UpdateFnForMainButtonInput } from '../../../components/mainTable/types';
import { nonPersonnelCostsBulkApiUrl } from '../../../utils/paths';
import {
  NonPersonnelCostForFetch,
  NonPersonnelCostBulk,
  NonPersonnelCostQueryResult,
} from '../types';
import client from '../../../api/axiosConfig';

export const useMultipleNonPersonnelCostsMutations = (
  query: NonPersonnelCostQueryResult
) => {
  const editSelectedMutation = useMutation(
    (bulkUpdate: UpdateFnForMainButtonInput<NonPersonnelCostForFetch>) => {
      const mappedNonPersonnelCostBulk: NonPersonnelCostBulk = {
        nonPersonnelCostDtos: bulkUpdate.dataForUpdate,
        updateTo: bulkUpdate.updateTo,
        actionToPerfom: bulkUpdate.actionToPerfom,
      };

      return client.post<{
        results: NonPersonnelCostBulk;
      }>(nonPersonnelCostsBulkApiUrl, mappedNonPersonnelCostBulk);
    },
    {
      onSuccess: () => {
        query.refetch().catch((e) => console.error(e));
      },
    }
  );

  const acceptAllMutation = useMutation(
    () => {
      const mappedPersonnelCostBulk: NonPersonnelCostBulk = {
        nonPersonnelCostDtos: query.data ? query.data.data.results : [],
        updateTo: true,
        actionToPerfom: 'make_accepted_or_unaccepted',
      };

      return client.post<{
        results: NonPersonnelCostBulk;
      }>(nonPersonnelCostsBulkApiUrl, mappedPersonnelCostBulk);
    },
    {
      onSuccess: () => {
        query.refetch().catch((e) => console.error(e));
      },
    }
  );

  return { editSelectedMutation, acceptAllMutation };
};
