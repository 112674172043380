import { Thead, Tr, Th, HStack, Icon, Text } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { tableBaseStyling, tableHeadInnerStyling } from '../styles';
import { HeadProps } from '../types';

export const MainTableHead = <T,>({ headers }: HeadProps<T>) => (
  <Thead sx={tableBaseStyling.head}>
    {headers.map((headerGroup) => (
      <Tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <Th key={header.id} colSpan={header.colSpan} sx={tableBaseStyling.th}>
            {header.isPlaceholder ? null : (
              <>
                <HStack
                  onClick={
                    header.column.columnDef.sortingFn
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                >
                  {typeof header.column.columnDef.header === 'string' ? (
                    <Text sx={tableHeadInnerStyling}>
                      {header.column.columnDef.header}
                    </Text>
                  ) : (
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )
                  )}
                  {header.column.columnDef.sortingFn &&
                  header.column.columnDef.sortingFn !== 'auto' ? (
                    <Icon
                      as={
                        header.column.getIsSorted() === 'desc' ||
                        !header.column.getIsSorted()
                          ? MdArrowDropDown
                          : MdArrowDropUp
                      }
                    />
                  ) : undefined}
                </HStack>
              </>
            )}
          </Th>
        ))}
      </Tr>
    ))}
  </Thead>
);
