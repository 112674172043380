import {
  MdOutlineDashboard,
  MdDashboard,
  MdOutlineRealEstateAgent,
  MdRealEstateAgent,
  MdOutlineRequestQuote,
  MdRequestQuote,
  MdOutlinePeopleAlt,
  MdPeopleAlt,
  MdOutlineContactPage,
  MdContactPage,
  MdOutlineSettings,
  MdSettings,
  MdOutlineLogout,
} from 'react-icons/md';
import {
  nonPersonnelCostsPath,
  nonPersonnelCostsAnnualPath,
  recurringIncomesPath,
  recurringIncomesAnnualPath,
  personnelCostsPath,
  personnelCostsAnnualPath,
  personnelIncomePath,
  personnelIncomesAnnualByProjectPath,
  personnelIncomesAnnualByEmployeePath,
  summaryAnnualPath,
  logoutPath,
  settingsPath,
} from '../../../utils/paths';
import { RedirectList, GrouppedRedirectList } from '../../layout/types';

export const SIDEBAR_ACTIONS: Array<RedirectList | GrouppedRedirectList> = [
  {
    name: 'dashboard',
    text: 'Dashboard',
    icon: MdOutlineDashboard,
    path: summaryAnnualPath,
    activeIcon: MdDashboard,
  },
  {
    name: 'personnel-costs',
    text: 'Koszty osobowe',
    icon: MdOutlinePeopleAlt,
    activeIcon: MdPeopleAlt,
    items: [
      {
        name: 'personnel-costs-monthly',
        text: 'na miesiąc',
        path: personnelCostsPath,
      },
      {
        name: 'personnel-costs-annual',
        text: 'na rok',
        path: personnelCostsAnnualPath,
      },
    ],
  },
  {
    name: 'non-personnel-costs',
    text: 'Koszty nieosobowe',
    icon: MdOutlineRealEstateAgent,
    activeIcon: MdRealEstateAgent,
    items: [
      {
        name: 'non-personnel-costs-monthly',
        text: 'na miesiąc',
        path: nonPersonnelCostsPath,
      },
      {
        name: 'non-personnel-costs-yearly',
        text: 'na rok',
        path: nonPersonnelCostsAnnualPath,
      },
    ],
  },
  {
    name: 'recurring-incomes',
    text: 'Przychody stałe',
    icon: MdOutlineRequestQuote,
    activeIcon: MdRequestQuote,
    items: [
      {
        name: 'recurring-incomes-monthly',
        text: 'na miesiąc',
        path: recurringIncomesPath,
      },
      {
        name: 'recurring-incomes-annual',
        text: 'na rok',
        path: recurringIncomesAnnualPath,
      },
    ],
  },
  {
    name: 'personnel-incomes',
    text: 'Przychody osobowe',
    icon: MdOutlineContactPage,
    activeIcon: MdContactPage,
    items: [
      {
        name: 'personnel-incomes-monthly',
        text: 'na miesiąc',
        path: personnelIncomePath,
      },
      {
        name: 'personnel-incomes-yearly-per-project',
        text: 'na rok per projekt',
        path: personnelIncomesAnnualByProjectPath,
      },
      {
        name: 'personnel-incomes-yearly-per-employee',
        text: 'na rok per pracownik',
        path: personnelIncomesAnnualByEmployeePath,
      },
    ],
  },
];

export const BOTTOM_SIDEBAR_LIST: Array<RedirectList> = [
  {
    name: 'settings',
    text: 'Ustawienia',
    icon: MdOutlineSettings,
    path: settingsPath,
    activeIcon: MdSettings,
  },
  {
    name: 'logout',
    text: 'Wyloguj się',
    icon: MdOutlineLogout,
    activeIcon: MdOutlineLogout,
    path: logoutPath,
  },
];
