import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ReactComponent as EmptyStateSVG } from '../images/empty.svg';
import { emptyStateStyles } from './EmptyState.styles';

const EmptyState: FC = () => {
  return (
    <Box sx={emptyStateStyles.container}>
      <EmptyStateSVG />
      <Text sx={emptyStateStyles.text}>Brak wyników</Text>
    </Box>
  );
};

export default EmptyState;
