import { colors } from 'styles/colors';

export const menuButtonStyling = (isError: boolean) => {
  return {
    borderWidth: '1px',
    borderRadius: '0.375rem',
    backgroundColor: `${colors.white}`,
    textAlign: 'left',
    textTransform: 'none',
    height: '40px',
    width: '100%',
    borderColor: isError
      ? `${colors.validationError}`
      : `${colors.inputBorder}`,
    boxShadow: isError ? `0 0 0 1px ${colors.validationError}` : 'none',
    _hover: { backgroundColor: `${colors.white}` },
  };
};

export const menuListStyling = { maxHeight: '400px', overflowY: 'auto' };
