import { useCallback, useContext, useEffect } from 'react';
import CurrencyContext from '../../../contexts/CurrencyContext/currencyContext';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { convertReactRowTypeToOriginal, CurrencyCodes } from '../../../utils';
import { RecurringIncomeForFetch } from '../types';
import { FinanceCardsData } from '../../../components/financeCard/types';
import { useFinanceCards } from '../../../components/financeCard/useFinanceCards';
import { prepareDataForCurrency } from '../../../components/financeCard/utils';

/**
 * Custom hook to be used on the recurring income page, it accepts unprocessed income data both filtered and unfiltered
 * and returns values that can be used by the pieChart component and financeCard component.
 */
export const useRecurringIncome = (
  recurringIncomeData?: RecurringIncomeForFetch[],
  recurringIncomeFilteredData?: ReactRowType<RecurringIncomeForFetch>[],
  recurringIncomeSelectedData?: ReactRowType<RecurringIncomeForFetch>[]
) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const {
    setFinanceCardsData,
    setFinanceCardsSelectedData,
    setFinanceCardsFilteredData,
    financeCardsToDisplay,
  } = useFinanceCards();

  const prepareDataForFinanceCards = useCallback(
    (data: RecurringIncomeForFetch[]) => {
      const financeCardsData: FinanceCardsData = {
        PLN: {
          income: [],
          incomePotential: [],
          totalIncomeConvertedToPLN: [],
          totalIncomeConvertedToPLNPotential: [],
        },
        EUR: { income: [], incomePotential: [] },
        USD: { income: [], incomePotential: [] },
      };

      const { PLN, EUR, USD } = financeCardsData;
      data.forEach((income) => {
        if (!income.isDeleted) {
          switch (income.amountCurrency) {
            case CurrencyCodes.PolishZloty:
              prepareDataForCurrency(
                income.id,
                PLN.income,
                PLN.incomePotential,
                income.amountAmount,
                PLN.totalIncomeConvertedToPLN,
                PLN.totalIncomeConvertedToPLNPotential
              );
              break;
            case CurrencyCodes.UnitedStatesDollar:
              prepareDataForCurrency(
                income.id,
                USD.income,
                USD.incomePotential,
                income.amountAmount,
                PLN.totalIncomeConvertedToPLN,
                PLN.totalIncomeConvertedToPLNPotential,
                currencyDataUSD
              );

              break;
            case CurrencyCodes.Euro:
              prepareDataForCurrency(
                income.id,
                EUR.income,
                EUR.incomePotential,
                income.amountAmount,
                PLN.totalIncomeConvertedToPLN,
                PLN.totalIncomeConvertedToPLNPotential,
                currencyDataEUR
              );

              break;
          }
        }
      });

      return financeCardsData;
    },
    [currencyDataEUR, currencyDataUSD]
  );

  useEffect(() => {
    if (recurringIncomeData) {
      const financeCardsData = prepareDataForFinanceCards(recurringIncomeData);
      setFinanceCardsData(financeCardsData);
    }
  }, [prepareDataForFinanceCards, recurringIncomeData, setFinanceCardsData]);

  useEffect(() => {
    if (recurringIncomeFilteredData && recurringIncomeFilteredData.length > 0) {
      const financeCardsFilteredData = prepareDataForFinanceCards(
        convertReactRowTypeToOriginal(recurringIncomeFilteredData)
      );
      if (financeCardsFilteredData) {
        setFinanceCardsFilteredData(financeCardsFilteredData);
      }
    } else {
      setFinanceCardsFilteredData(undefined);
    }
  }, [
    prepareDataForFinanceCards,
    recurringIncomeFilteredData,
    setFinanceCardsFilteredData,
  ]);

  useEffect(() => {
    if (recurringIncomeSelectedData && recurringIncomeSelectedData.length > 0) {
      const financeCardsSelectedData = prepareDataForFinanceCards(
        convertReactRowTypeToOriginal(recurringIncomeSelectedData)
      );
      if (financeCardsSelectedData) {
        setFinanceCardsSelectedData(financeCardsSelectedData);
      }
    } else {
      setFinanceCardsSelectedData(undefined);
    }
  }, [
    prepareDataForFinanceCards,
    recurringIncomeSelectedData,
    setFinanceCardsSelectedData,
  ]);

  return {
    financeCardsToDisplay,
  };
};
