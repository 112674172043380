import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../../../../../api/axiosConfig';
import { ColumnInfoSettings, SettingsTable } from '../../../settingsTable';
import { AxiosResponse } from 'axios';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { CenteredSpinner } from '../../../../../components/centeredSpinner';
import { Box } from '@chakra-ui/react';
import { AddContractType } from './addContractType';
import { contractTypeApiUrl } from '../../../../../utils/paths';
import { ApiError } from '../../../../../types/apiError';
import { handleError } from '../../../../../utils/handleError';
import { DataFromCellEdit } from '../../../../../components/mainTable/types';
import { ContractType, ContractTypeForEdit } from '../types';
import EmptyState from 'components/EmptyState/EmptyState';

export const ContractTypes: FC = () => {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(
    'contractTypes',
    () => client.get<{ results: ContractType[] }>(contractTypeApiUrl),
    {}
  );

  const changeContractType = useMutation(
    (dataForEdit: ContractTypeForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: ContractType }>(
        `${contractTypeApiUrl}${id}/`,
        {
          ...data,
        }
      );
    },
    {
      onSuccess: (data) => {
        const oldData: AxiosResponse<{ results: ContractType[] }> | undefined =
          queryClient.getQueryData('contractTypes');

        if (!oldData) return;
        oldData.data.results = oldData.data.results.map((contractType) =>
          contractType.id === data.data.results.id
            ? data.data.results
            : contractType
        );
        queryClient.setQueryData('contractTypes', oldData);
      },
      onError: (error: ApiError) => {
        handleError(error);
      },
    }
  );

  const mutateContractType = (
    row: ReactRowType<ContractType>,
    data: Partial<ContractType>
  ) =>
    changeContractType.mutate({
      id: row.original.id,
      isDeleted: data.isDeleted,
    });

  const mutateFromCellEditContractType = (
    data: DataFromCellEdit<ContractType>
  ) => {
    const dataForMutate: Record<string, string> = {};

    dataForMutate[data.editedColumnId] = data.newValue;
    changeContractType.mutate({ id: data.originalRow.id, ...dataForMutate });
  };

  const filterDeletedRows = (
    table: ReactRowType<ContractType>[]
  ): ReactRowType<ContractType>[] => {
    return table.filter((row) => !row.original.isDeleted);
  };

  const columnInfo: ColumnInfoSettings<ContractType>[] = [
    {
      id: 'name',
      header: 'Rodzaj umowy',
      sortType: 'text',
      editable: 'textInputField',
      getRow: (row: ContractType) => `${row.name}`,
    },
  ];

  return isLoading || !data ? (
    <CenteredSpinner />
  ) : (
    <Box>
      <AddContractType />
      {!data.data.results.length ? (
        <EmptyState />
      ) : (
        <SettingsTable<ContractType>
          data={data.data.results}
          columnInfo={columnInfo}
          updateFnForRowEdit={mutateContractType}
          updateFnForCellEdit={mutateFromCellEditContractType}
          filterDeletedRows={filterDeletedRows}
        />
      )}
    </Box>
  );
};
