import { Grid } from '@chakra-ui/react';

import RangeFilter from './RangeFilter';
import SelectFilter from './SelectFilter';

import { filtersStyling } from '../styles';
import { FiltersProps } from './Filters.types';

export const Filters = <T,>({ columnInfo, getColumn }: FiltersProps<T>) => {
  return (
    <Grid sx={{ ...filtersStyling.container }}>
      {columnInfo.map((column, index) => {
        if (column.filterFn === 'inNumberRange') {
          const columnObject = getColumn(column.id);

          return (
            columnObject && (
              <RangeFilter
                key={index}
                column={columnObject}
                label={column.filterFieldLabel}
              />
            )
          );
        } else if (
          (column.filterFn === 'equalsString' ||
            column.filterFn === 'includesString' ||
            column.filterFn === 'arrIncludes') &&
          column.columnOptions
        ) {
          const columnObject = getColumn(column.id);

          return (
            columnObject && (
              <SelectFilter
                key={index}
                column={columnObject}
                options={column.columnOptions}
                label={column.filterFieldLabel}
              />
            )
          );
        }
      })}
    </Grid>
  );
};
