import { useCallback, useState } from 'react';
import { Getter } from '@tanstack/react-table';
import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react';
import { DataFromCellEdit } from 'components/mainTable/types';
import { CELL_KEYS_BLACKLIST } from 'consts/index';

interface Props<T> {
  getValue: Getter<T>;
  originalRow: T;
  columnId: string;
  tabFocusableColumnId?: string;
  onCellBlur: (dataFromCellEdit: DataFromCellEdit<T>) => void;
  isModalOpen?: boolean;
  isForNumericValues?: boolean;
}

export const InputFieldEditableTableCell = <T,>({
  getValue,
  originalRow,
  columnId,
  tabFocusableColumnId,
  onCellBlur,
  isModalOpen,
  isForNumericValues,
}: Props<T>) => {
  const initialValue = getValue<string>();
  const [value, setValue] = useState(initialValue);

  const onSubmitHandler = useCallback(
    (newValue: string) => {
      if (initialValue !== newValue) {
        onCellBlur({
          originalRow,
          editedColumnId: columnId,
          newValue,
        });
      }
    },
    [columnId, initialValue, onCellBlur, originalRow]
  );

  return (
    <Editable defaultValue={initialValue} onSubmit={onSubmitHandler}>
      <EditablePreview
        className={
          columnId === tabFocusableColumnId ? 'tab-focusable-input' : undefined
        }
      />
      <EditableInput
        type={isForNumericValues ? 'number' : 'text'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          if (!isModalOpen) {
            onCellBlur({
              originalRow: originalRow,
              editedColumnId: columnId,
              newValue: value,
            });
          }
        }}
        onKeyDown={(e) =>
          isForNumericValues &&
          CELL_KEYS_BLACKLIST.includes(e.key) &&
          e.preventDefault()
        }
      />
    </Editable>
  );
};
