import { CurrencyData } from '../api/currencyExchangeRates';
import numeral, { Numeral } from 'numeral';
import {
  BaseAnnualData,
  GroupedSums,
  SumsByCurrency,
} from '../types/AnnualViews';
import { CurrencyCodes, formatAmountNumeral } from './index';

export const convertToCurrency = (
  amounts: number[],
  currencyData: CurrencyData
) => amounts.map((value) => numeral(value).multiply(currencyData.bid));

export const getValuesFromNumeral = (array: (Numeral | null | undefined)[]) =>
  array
    .map((value) => value?.value())
    .filter((value) => value != null) as number[];

export const getSumsForPieChart = (data: GroupedSums) => ({
  labels: Object.keys(data),
  income: Object.values(data).map((value) => value.value() || 0),
});

export const getSumsForDoughnutPieChart = (data: GroupedSums) => ({
  labels: Object.keys(data),
  data: Object.values(data).map((value) => value.value() || 0),
});

const getInitialArray = (length: number) =>
  Array<number>(length)
    .fill(0)
    .map((value) => numeral(value));

export const getSumsByCurrency = (data?: BaseAnnualData[]): SumsByCurrency => {
  const sums = {
    [CurrencyCodes.PolishZloty]: getInitialArray(12),
    [CurrencyCodes.UnitedStatesDollar]: getInitialArray(12),
    [CurrencyCodes.Euro]: getInitialArray(12),
  };

  data?.forEach((income) =>
    income.amount.forEach(({ amount, currency }, index) => {
      if (!currency) return;

      if (currency in sums) {
        sums[currency as CurrencyCodes][index].add(amount);
      }
    })
  );

  return {
    pln: sums[CurrencyCodes.PolishZloty].map((value) => value.value() || 0),
    usd: sums[CurrencyCodes.UnitedStatesDollar].map(
      (value) => value.value() || 0
    ),
    eur: sums[CurrencyCodes.Euro].map((value) => value.value() || 0),
  };
};

export const getDisplaySums = <T extends BaseAnnualData>(data?: T[]) =>
  data?.map((cost) => ({
    ...cost,
    displayTotalAnnualAmount: formatAmountNumeral(cost.totalAnnualAmount),
    amount: cost.amount.map((value) => ({
      ...value,
      displayAmountPLN: formatAmountNumeral(value.amountPLN),
    })),
  }));
