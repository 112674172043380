import { MonthlyQueryKey } from '../../../types/MonthlyQueryKey';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchNonPersonnelCosts } from '../utils';

export const useNonPersonnelCostsQuery = (year: number, month: number) => {
  const queryKey: MonthlyQueryKey = useMemo(
    () => ['nonPersonnelCosts', year, month],
    [month, year]
  );

  return useQuery(queryKey, fetchNonPersonnelCosts, {});
};
