import { useQuery } from 'react-query';
import { synchronizeWorkingHoursApiUrl } from '../../../../utils/paths';
import { MonthWorkingHoursAPI } from './useWorkingHoursQueries.types';
import client from '../../../../api/axiosConfig';
import { useMemo } from 'react';
import { LOADING, NO_DATA } from '../../../../consts';

const useWorkingHoursQueries = (date: Date) => {
  const selectedYear = useMemo(() => date.getFullYear(), [date]);
  const selectedMonth = useMemo(() => date.getMonth(), [date]);

  const query = useQuery(['monthWorkingHours', selectedYear], () =>
    client.post<{ results: MonthWorkingHoursAPI[] }>(
      `${synchronizeWorkingHoursApiUrl}?year=${selectedYear}`
    )
  );

  const { data, isLoading } = query;

  const monthHours = useMemo(() => {
    const results = data?.data?.results ?? [];

    return isLoading
      ? LOADING
      : results[selectedMonth]?.internalNumberOfWorkingHours ?? NO_DATA;
  }, [data?.data?.results, isLoading, selectedMonth]);

  return { query, monthHours };
};

export default useWorkingHoursQueries;
