import numeral from 'numeral';
import { useContext, useMemo } from 'react';
import CurrencyContext from '../../../contexts/CurrencyContext/currencyContext';
import { CurrencyCodes, sumNumerals } from '../../../utils';
import { AnnualPersonnelIncomeByEmployee } from '../types/annualPersonnelIncomesEmployee';

export const useAmountsInPLN = (data?: AnnualPersonnelIncomeByEmployee[]) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const newData: AnnualPersonnelIncomeByEmployee[] | undefined = useMemo(
    () =>
      data?.map((annualRecurringIncome) => ({
        ...annualRecurringIncome,
        projects: annualRecurringIncome.projects.map((project) => {
          const amountPLN = project.amount.map((amountValue) => {
            if (
              amountValue.currency === CurrencyCodes.PolishZloty ||
              amountValue.currency === null
            ) {
              return numeral(amountValue.amount);
            } else if (
              amountValue.currency === CurrencyCodes.UnitedStatesDollar
            ) {
              return currencyDataUSD
                ? numeral(amountValue.amount).multiply(currencyDataUSD.bid)
                : undefined;
            } else if (amountValue.currency === CurrencyCodes.Euro) {
              return currencyDataEUR
                ? numeral(amountValue.amount).multiply(currencyDataEUR.bid)
                : undefined;
            }
          });

          return {
            ...project,
            amount: project.amount.map((value, index) => ({
              ...value,
              amountPLN: amountPLN[index],
            })),
            totalAnnualAmount: sumNumerals(amountPLN),
          };
        }),
      })),
    [currencyDataEUR, currencyDataUSD, data]
  );

  return newData;
};
