import { Numeral } from 'numeral';
import { Nullable } from '../../types/nullable';

export enum CostCategory {
  personnel = 'Koszty osobowe',
  nonPersonnel = 'Koszty nieosobowe',
}

export enum IncomeCategory {
  personnel = 'Przychody osobowe',
  reccuring = 'Przychody stałe',
}

export enum ProfitCategory {
  normal = 'Dochód normalny',
  cumulative = 'Dochód narastający',
}

export interface SummaryAmount {
  amount?: Nullable<Numeral>;
  displayAmount?: string;
}

export interface AnnualSummaryCost {
  category: CostCategory;
  name: string;
  displayTotalAnnualAmount?: string;
  amount: SummaryAmount[];
}

export interface AnnualSummaryIncome {
  category: IncomeCategory;
  name: string;
  displayTotalAnnualAmount?: string;
  amount: SummaryAmount[];
}

export interface AnnualSummaryProfit {
  category: ProfitCategory;
  displayTotalAnnualAmount?: string;
  amount: SummaryAmount[];
}
