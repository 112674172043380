import { Project } from 'pages/personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { useCallback } from 'react';
import { DataFromCellEdit } from '../../../../components/mainTable/types';
import { AxiosMutationResult, EntityWithId } from '../../../../types';
import {
  PersonnelIncomeForEdit,
  PersonnelIncomeForCreate,
  PersonnelIncomeRowType,
  PersonnelIncomeRow,
  PersonnelIncomeInterface,
} from '../../types';

export const usePersonnelIncomesActions = (
  remove: AxiosMutationResult<EntityWithId, PersonnelIncomeInterface>,
  change: AxiosMutationResult<PersonnelIncomeForEdit, PersonnelIncomeInterface>,
  create: AxiosMutationResult<
    PersonnelIncomeForCreate,
    PersonnelIncomeInterface
  >,
  afterAction: () => void = () => undefined
) => {
  const changeIsDeleted = useCallback(
    (row: PersonnelIncomeRowType, data: { isDeleted: boolean }) => {
      const {
        id,
        project,
        projectAmountAmount,
        projectAmountCurrency,
        projectHours,
        employeeId,
        year,
        month,
      } = row.original;

      const { isDeleted } = data;

      if (id) {
        change.mutate({
          id,
          isDeleted,
        });
      } else {
        if (year !== null && month !== null) {
          create.mutate({
            employeeId,
            month,
            year,
            project,
            amountAmount: projectAmountAmount as string,
            amountCurrency: projectAmountCurrency as string,
            hours: projectHours,
            isDeleted,
          });
        }
      }

      afterAction();
    },
    [afterAction, change, create]
  );

  const mutateFromCellEdit = useCallback(
    (data: DataFromCellEdit<PersonnelIncomeRow>) => {
      const isNewValueNotNumeric = isNaN(+data.newValue);
      if (data.editedColumnId === 'amountAmount' && isNewValueNotNumeric)
        return;

      const dataForMutate: Record<string, string | number | object> = {};

      if (data.editedColumnId === 'lastNameFirstName') {
        const [lastName, firstName] = data.newValue.split(' ', 2);

        dataForMutate['employeeFirstName'] = firstName || '';
        dataForMutate['employeeLastName'] = lastName;
      } else if (data.editedColumnId === 'hours') {
        dataForMutate['hours'] = Number(data.newValue);
      } else if (data.editedColumnId === 'name') {
        dataForMutate['project'] = { name: data.newValue };
      } else {
        dataForMutate[data.editedColumnId] = data.newValue;
      }

      if (data.originalRow.id) {
        change.mutate({
          id: data.originalRow.id,
          ...dataForMutate,
        });
      } else {
        const {
          project,
          projectAmountAmount,
          projectAmountCurrency,
          projectHours,
          employeeId,
          year,
          month,
        } = data.originalRow;

        if (year === null || month === null) return;

        const dataToSend: PersonnelIncomeForCreate = {
          employeeId,
          month,
          year,
          project: project as Project,
          amountAmount: projectAmountAmount as string,
          amountCurrency: projectAmountCurrency as string,
          hours: projectHours,
          isDeleted: false,
        };

        create.mutate({ ...dataToSend, ...dataForMutate });
      }

      afterAction();
    },
    [afterAction, change, create]
  );

  const accept = useCallback(
    (row: PersonnelIncomeRowType) => {
      const {
        project,
        projectAmountAmount,
        projectAmountCurrency,
        projectHours,
        employeeId,
        year,
        month,
      } = row.original;

      if (year === null || month === null) return;

      const preparedData: PersonnelIncomeForCreate = {
        employeeId,
        month,
        year,
        project,
        amountAmount: projectAmountAmount as string,
        amountCurrency: projectAmountCurrency as string,
        hours: projectHours,
        isDeleted: false,
      };

      create.mutate(preparedData);

      afterAction();
    },
    [afterAction, create]
  );

  const unaccept = useCallback(
    (row: PersonnelIncomeRowType) => {
      if (row.original.id) {
        remove.mutate({ id: row.original.id });
      }

      afterAction();
    },
    [afterAction, remove]
  );

  return { mutateFromCellEdit, changeIsDeleted, accept, unaccept };
};
