import { FC } from 'react';
import { VStack } from '@chakra-ui/react';
import { basicContainer } from '../../styles/global';
import { MainTable } from '../../components/mainTable';
import { AnnualSummaryProfit } from './types';
import BarChart from '../../components/Charts/BarChart';
import { CenteredSpinner } from '../../components/centeredSpinner';
import {
  BarChartData,
  BaseBarChartData,
} from '../../components/Charts/BarChart/types';
import { useAnnualSummaryProfitColumnDef } from './hooks/useAnnualSummaryProfitColumnDef';

interface Props {
  data?: AnnualSummaryProfit[];
  dataForBarChart: BarChartData;
  secondDataForBarChart: BaseBarChartData;
  isLoading: boolean;
}

const AnnualSummaryProfits: FC<Props> = ({
  data,
  dataForBarChart,
  secondDataForBarChart,
  isLoading,
}) => {
  const { columnInfo } = useAnnualSummaryProfitColumnDef();

  if (isLoading || !data) return <CenteredSpinner />;

  return (
    <VStack sx={basicContainer}>
      <BarChart
        chartData={dataForBarChart}
        secondChartData={secondDataForBarChart}
        mainHeader={'Suma dochodów w miesiącach'}
      />
      <MainTable<AnnualSummaryProfit>
        data={data}
        columnInfo={columnInfo}
        rowsEditableByButtons={false}
      />
    </VStack>
  );
};

export default AnnualSummaryProfits;
