import { FC, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Positions } from '../pageComponents/position/pageFiles';
import { Categories } from '../pageComponents/categories/pageFiles';
import { ContractTypes } from '../pageComponents/contractType/pageFiles';
import { SettingsHeaderMode } from './types';
import { WorkingHours } from '../pageComponents/workingHours';
import { chakraTabStyling } from './styles';
import { basicContainer, h1Typography } from '../../../styles/global';

export const SettingsHeader: FC = () => {
  const [selectedModule, setSelectedModule] =
    useState<SettingsHeaderMode>('position');

  return (
    <Box sx={basicContainer}>
      <Box>
        <Text sx={{ ...h1Typography }} marginBottom={'2rem'}>
          Ustawienia
        </Text>
        <Flex marginBottom={'1rem'}>
          <Text
            sx={{ ...chakraTabStyling.base }}
            onClick={() => setSelectedModule('position')}
            borderBottom={
              selectedModule === 'position' ? '0.1rem solid text.active' : '0'
            }
            color={
              selectedModule === 'position' ? 'text.active' : 'text.unactive'
            }
          >
            Klasy
          </Text>
          <Text
            sx={{ ...chakraTabStyling.base }}
            onClick={() => setSelectedModule('categories')}
            borderBottom={
              selectedModule === 'categories' ? '0.1rem solid text.active' : '0'
            }
            color={
              selectedModule === 'categories' ? 'text.active' : 'text.unactive'
            }
          >
            Kategorie
          </Text>
          <Text
            sx={{ ...chakraTabStyling.base }}
            onClick={() => setSelectedModule('contractType')}
            borderBottom={
              selectedModule === 'contractType'
                ? '0.1rem solid text.active'
                : '0'
            }
            color={
              selectedModule === 'contractType'
                ? 'text.active'
                : 'text.unactive'
            }
          >
            Rodzaj umowy
          </Text>
          <Text
            {...chakraTabStyling.lastTab}
            onClick={() => setSelectedModule('workingHours')}
            borderBottom={
              selectedModule === 'workingHours'
                ? '0.1rem solid text.active'
                : '0'
            }
            color={
              selectedModule === 'workingHours'
                ? 'text.active'
                : 'text.unactive'
            }
          >
            Godziny robocze
          </Text>
        </Flex>
      </Box>
      {(() => {
        switch (selectedModule) {
          case 'position':
            return <Positions />;
          case 'categories':
            return <Categories />;
          case 'contractType':
            return <ContractTypes />;
          case 'workingHours':
            return <WorkingHours />;
        }
      })()}
    </Box>
  );
};
