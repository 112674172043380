import { QueryFunctionContext } from 'react-query';
import numeral from 'numeral';
import client from '../../api/axiosConfig';
import { nonPersonnelCostsAnnualApiUrl } from '../../utils/paths';
import { AnnualNonPersonnelCost } from './types';
import { AnnualQueryKey } from '../../types/AnnualQueryKey';
import { GroupedSums } from '../../types/AnnualViews';

export const fetchNonPersonnelCostsAnnual = (
  context: QueryFunctionContext<AnnualQueryKey>
) => {
  const [, year = '', onlyAccepted] = context.queryKey;
  return client.get<{ results: AnnualNonPersonnelCost[] }>(
    `${nonPersonnelCostsAnnualApiUrl}?year=${year}&include_predictions=${
      onlyAccepted ? 0 : 1
    }`
  );
};

export const getTotalAnnualSumsByCostName = (
  data?: AnnualNonPersonnelCost[]
): GroupedSums => {
  const allNames = [...new Set(data?.map(({ costName }) => costName))];

  const sums = Object.fromEntries(allNames.map((name) => [name, numeral(0)]));

  data?.forEach(({ costName, totalAnnualAmount }) => {
    if (totalAnnualAmount == null) return;

    sums[costName].add(totalAnnualAmount.value());
  });

  return sums;
};

export const getTotalAnnualSumsByCategoryName = (
  data?: AnnualNonPersonnelCost[]
): GroupedSums => {
  const allNames = [...new Set(data?.map(({ categoryName }) => categoryName))];

  const sums = Object.fromEntries(allNames.map((name) => [name, numeral(0)]));

  data?.forEach(({ categoryName, totalAnnualAmount }) => {
    if (totalAnnualAmount == null) return;

    sums[categoryName].add(totalAnnualAmount.value());
  });

  return sums;
};
