import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { useMemo } from 'react';
import { AnnualRecurringIncome } from '../types/annualRecurringIncome';
import { useDataForPieChart } from './useDataForPieChart';
import { useAmountsInPLN } from './useAmountsInPLN';
import { useDataForBarChart } from '../../../hooks/annualViews/useDataForBarChart';
import { useFinanceCardsToDisplay } from '../../../hooks/annualViews/useFinanceCardsToDisplay';
import { useSumsByCurrency } from '../../../hooks/annualViews/useSumsByCurrency';
import { useTotalAmounts } from '../../../hooks/annualViews/useTotalAmounts';
import { getDisplaySums } from '../../../utils/annualViews';

export const useAnnualRecurringIncomes = (
  data?: AnnualRecurringIncome[],
  filteredData?: ReactRowType<AnnualRecurringIncome>[]
) => {
  const filteredDataOriginal = useMemo(
    () =>
      filteredData?.map((annualPersonnelCost) => annualPersonnelCost.original),
    [filteredData]
  );

  const dataWithPLN = useAmountsInPLN(data);
  const filteredDataWithPLN = useAmountsInPLN(filteredDataOriginal);

  const dataByCurrency = useSumsByCurrency(data);
  const filteredDataByCurrency = useSumsByCurrency(filteredDataOriginal);

  const totalAmounts = useTotalAmounts(dataWithPLN);
  const totalAmountsFiltered = useTotalAmounts(filteredDataWithPLN);

  const financeCardsToDisplay = useFinanceCardsToDisplay(
    dataByCurrency,
    filteredDataByCurrency,
    totalAmounts,
    totalAmountsFiltered,
    !!filteredDataOriginal?.length
  );

  const { dataForBarChart, secondDataForBarChart } = useDataForBarChart(
    dataByCurrency,
    filteredDataByCurrency,
    !!filteredDataOriginal?.length
  );

  const { dataForPieChart, secondDataForPieChart } = useDataForPieChart(
    dataWithPLN,
    filteredDataWithPLN
  );

  const displayData = useMemo(() => getDisplaySums(dataWithPLN), [dataWithPLN]);

  return {
    displayData,
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForPieChart,
    secondDataForPieChart,
  };
};
