import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import {
  billableRequiredValidation,
  requiredValidation,
} from '../../../../../components/form/validation';
import { TextInput } from '../../../../../components/form/inputs/textInput';
import { NumberInput } from '../../../../../components/form/inputs/numberInput';
import { ApiError } from '../../../../../types/apiError';
import { handleValidationError } from '../../../../../utils/handleValidationError';
import { positionApiUrl } from '../../../../../utils/paths';
import { PositionForCreate, Position } from '../types';
import Drawer from '../../../../../components/common/drawer';
import DrawerOpeningButton from '../../../../../components/common/drawerButton';

export const AddPosition: FC = () => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const addPosition = useMutation(
    (data: PositionForCreate) => {
      return client.post<{ results: Position }>(positionApiUrl, {
        ...data,
      });
    },
    {
      onSuccess: (data) => {
        const oldData: AxiosResponse<{ results: Position[] }> | undefined =
          queryClient.getQueryData('positions');

        closeDrawer();
        if (!oldData) return;

        oldData.data.results = [...oldData.data.results, data.data.results];
        queryClient.setQueryData('positions', oldData);
      },
      onError: (error: ApiError) => {
        const fields = ['name', 'billable'];
        handleValidationError(error, methods, fields);
      },
    }
  );

  const methods = useForm<PositionForCreate>({});

  const handleSubmit = async (data: PositionForCreate) =>
    await addPosition.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj nową klasę" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj nową klasę"
          isOpen={isOpen}
          onClose={closeDrawer}
          onSubmit={handleSubmit}
        >
          <>
            <TextInput
              name={'name'}
              label={'Nazwa klasy'}
              autoFocus
              registerProps={requiredValidation}
            />
            <NumberInput
              name={'billable'}
              label={'Billable'}
              registerProps={billableRequiredValidation}
            />
          </>
        </Drawer>
      </FormProvider>
    </>
  );
};
