import { FC } from 'react';
import { useMutation } from 'react-query';
import client from '../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerOpeningButton from '../../components/common/drawerButton';
import { FormSelect } from '../../components/form/inputs/select';
import { TextInput } from '../../components/form/inputs/textInput';
import { requiredValidation } from '../../components/form/validation';
import { ApiError } from '../../types/apiError';
import { currencyOptions } from '../../utils';
import { handleError } from '../../utils/handleError';
import { nonPersonnelCostsApiUrl } from '../../utils/paths';
import {
  AddNonPersonnelCostFormProps,
  Category,
  NonPersonnelCostForCreate,
  NonPersonnelCostForFetch,
} from './types';
import Drawer from '../../components/common/drawer';
import { NumberInput } from '../../components/form/inputs/numberInput';
import { MonthlyQueryKey } from '../../types/MonthlyQueryKey';

export const AddNonPersonnelCost: FC<AddNonPersonnelCostFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  year,
  month,
  queryClient,
}) => {
  const categories =
    queryClient.getQueryData<AxiosResponse<{ results: Category[] }>>('category')
      ?.data?.results ?? [];

  const addNonPersonnelCost = useMutation(
    (dataForCreate: NonPersonnelCostForCreate) =>
      client.post<{ results: NonPersonnelCostForFetch }>(
        nonPersonnelCostsApiUrl,
        {
          ...dataForCreate,
          month,
          year,
          isDeleted: false,
        }
      ),
    {
      onSuccess: (data) => {
        const queryKey: MonthlyQueryKey = ['nonPersonnelCosts', year, month];

        const oldData:
          | AxiosResponse<{ results: NonPersonnelCostForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        oldData.data.results = [...oldData.data.results, data.data.results];

        queryClient.setQueryData(queryKey, oldData);

        closeDrawer();
      },
      onError: (error: ApiError) => {
        handleError(error);
      },
    }
  );

  const methods = useForm<NonPersonnelCostForCreate>({
    defaultValues: {
      amountCurrency: 'PLN',
    },
  });

  const handleSubmit = async (data: NonPersonnelCostForCreate) =>
    await addNonPersonnelCost.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj nowy koszt" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj nowy koszt"
          isOpen={isOpen}
          onClose={closeDrawer}
          onSubmit={handleSubmit}
        >
          <>
            <TextInput
              name={'name'}
              placeholder="Nazwa kosztu"
              autoFocus
              registerProps={requiredValidation}
            />
            <FormSelect
              options={categories.map(({ name }) => ({
                label: name,
                value: name,
              }))}
              name={'categoryName'}
              placeholder="Kategoria kosztu"
              registerProps={requiredValidation}
            />
            <FormSelect
              options={currencyOptions}
              name={'amountCurrency'}
              placeholder="Waluta"
              defaultValue={'PLN'}
              registerProps={requiredValidation}
            />
            <NumberInput
              name={'amountAmount'}
              placeholder="Kwota"
              registerProps={requiredValidation}
            />
          </>
        </Drawer>
      </FormProvider>
    </>
  );
};
