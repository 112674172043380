import { AxiosResponse } from 'axios';
import { Project } from 'pages/personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { PersonnelIncomeRowWithPLN } from './types';
import { getSumsByProjectId } from './utils';

export const useSumsByProjects = (data?: PersonnelIncomeRowWithPLN[]) => {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const projects =
      queryClient.getQueryData<AxiosResponse<{ results: Array<Project> }>>(
        'projects'
      )?.data?.results;

    if (!projects) return;

    const sums = getSumsByProjectId(data);

    const labels: Array<string> = [];
    const income: Array<number> = [];

    Object.keys(sums).forEach((projectId) => {
      labels.push(
        projects.find((project) => project.id.toString() === projectId)?.name ||
          ''
      );

      income.push(sums[projectId].value() || 0);
    });

    return { labels, income };
  }, [data, queryClient]);
};
