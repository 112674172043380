import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import {
  NonPersonnelCostForFetch,
  FinanceCardsNonPersonnelCostsData,
} from '../types';
import { useCallback, useContext, useEffect } from 'react';
import CurrencyContext from '../../../contexts/CurrencyContext/currencyContext';
import { convertReactRowTypeToOriginal, CurrencyCodes } from '../../../utils';
import { useFinanceCards } from '../../../components/financeCard/useFinanceCards';
import { prepareDataForCurrency } from '../../../components/financeCard/utils';

export const useNonPersonnelCosts = (
  nonPersonnelCostsData: NonPersonnelCostForFetch[] | undefined,
  nonPersonnelCostsFilteredData?: ReactRowType<NonPersonnelCostForFetch>[],
  nonPersonnelCostsSelectedData?: ReactRowType<NonPersonnelCostForFetch>[]
) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const {
    setFinanceCardsData,
    setFinanceCardsSelectedData,
    setFinanceCardsFilteredData,
    financeCardsToDisplay,
  } = useFinanceCards();

  const prepareDataForFinanceCards = useCallback(
    (data: NonPersonnelCostForFetch[]) => {
      const financeCardsData: FinanceCardsNonPersonnelCostsData = {
        PLN: {
          income: [],
          incomePotential: [],
          totalIncomeConvertedToPLN: [],
          totalIncomeConvertedToPLNPotential: [],
        },
        EUR: { income: [], incomePotential: [] },
        USD: { income: [], incomePotential: [] },
      };

      const { PLN, EUR, USD } = financeCardsData;
      data.forEach((income) => {
        if (!income.isDeleted) {
          switch (income.amountCurrency) {
            case CurrencyCodes.PolishZloty:
              prepareDataForCurrency(
                income.id,
                PLN.income,
                PLN.incomePotential,
                income.amountAmount,
                PLN.totalIncomeConvertedToPLN,
                PLN.totalIncomeConvertedToPLNPotential
              );
              break;
            case CurrencyCodes.UnitedStatesDollar:
              prepareDataForCurrency(
                income.id,
                USD.income,
                USD.incomePotential,
                income.amountAmount,
                PLN.totalIncomeConvertedToPLN,
                PLN.totalIncomeConvertedToPLNPotential,
                currencyDataUSD
              );

              break;
            case CurrencyCodes.Euro:
              prepareDataForCurrency(
                income.id,
                EUR.income,
                EUR.incomePotential,
                income.amountAmount,
                PLN.totalIncomeConvertedToPLN,
                PLN.totalIncomeConvertedToPLNPotential,
                currencyDataEUR
              );
              break;
          }
        }
      });

      return financeCardsData;
    },
    [currencyDataEUR, currencyDataUSD]
  );

  useEffect(() => {
    if (nonPersonnelCostsData) {
      const financeCardsData = prepareDataForFinanceCards(
        nonPersonnelCostsData
      );
      setFinanceCardsData(financeCardsData);
    }
  }, [nonPersonnelCostsData, prepareDataForFinanceCards, setFinanceCardsData]);

  useEffect(() => {
    if (nonPersonnelCostsFilteredData?.length) {
      const financeCardsFilteredData = prepareDataForFinanceCards(
        convertReactRowTypeToOriginal(nonPersonnelCostsFilteredData)
      );
      if (financeCardsFilteredData) {
        setFinanceCardsFilteredData(financeCardsFilteredData);
      }
    } else {
      setFinanceCardsFilteredData(undefined);
    }
  }, [
    nonPersonnelCostsFilteredData,
    prepareDataForFinanceCards,
    setFinanceCardsFilteredData,
  ]);

  useEffect(() => {
    if (nonPersonnelCostsSelectedData?.length) {
      const financeCardsSelectedData = prepareDataForFinanceCards(
        convertReactRowTypeToOriginal(nonPersonnelCostsSelectedData)
      );
      if (financeCardsSelectedData) {
        setFinanceCardsSelectedData(financeCardsSelectedData);
      }
    } else {
      setFinanceCardsSelectedData(undefined);
    }
  }, [
    nonPersonnelCostsSelectedData,
    prepareDataForFinanceCards,
    setFinanceCardsSelectedData,
  ]);

  return {
    financeCardsToDisplay,
  };
};
