import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  UseCounterProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  Controller,
  ControllerFieldState,
  FieldValues,
  Noop,
  RefCallBack,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { CELL_KEYS_BLACKLIST } from 'consts/index';
import { InputWrapper } from '../inputWrapper';
import { DefaultInputProps } from '../types';
import { defaultValidation } from '../validation';

interface Props extends DefaultInputProps {
  min?: number;
}

interface RenderProps {
  field: {
    onChange: UseCounterProps['onChange'];
    onBlur: Noop;
    value?: number;
    name: string;
    ref: RefCallBack;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

export const NumberInput: FC<Props> = ({
  name,
  min,
  placeholder,
  registerProps = defaultValidation,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <InputWrapper name={name} {...rest}>
      <Controller
        control={control}
        rules={registerProps}
        name={name}
        render={({ field: { onChange, value, onBlur } }: RenderProps) => (
          <ChakraNumberInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            min={min}
            data-value={value != null ? value : undefined}
            onKeyDown={(e) =>
              CELL_KEYS_BLACKLIST.includes(e.key) && e.preventDefault()
            }
          >
            <NumberInputField placeholder={placeholder} />
            <NumberInputStepper />
          </ChakraNumberInput>
        )}
      />
    </InputWrapper>
  );
};
