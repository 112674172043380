import { FC, useState } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { MainTable } from '../../components/mainTable';
import { CenteredSpinner } from '../../components/centeredSpinner';
import { MainContainer } from '../../components/mainContainer';
import { YearControl } from '../../components/dateControl/yearControl';
import { AnnualPersonnelCost } from './types/annualPersonnelCost';
import { useAnnualPersonnelCosts } from './hooks/useAnnualPersonnelCosts';
import FinanceCard from '../../components/financeCard';
import PieChart from '../../components/Charts/PieChart';
import { financeCardContainer } from '../recurringIncome/styles';
import BarChart from '../../components/Charts/BarChart';
import { useAnnualPersonnelCostsColumnDef } from './hooks/useAnnualPersonnelCostsColumnDef';
import { usePersonnelCostsAnnualQuery } from './hooks/usePersonnelCostsAnnualQuery';
import { mainVStack, pageControlsHStack, subHStack } from '../../styles/global';
import Switch from '../../components/Switch';
import PageHeading from 'components/common/pageHeading';
import EmptyState from 'components/EmptyState';

export const PersonnelCostsAnnual: FC = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [onlyIncludeAccepted, setOnlyIncludeAccepted] = useState(false);
  const [currentTableRows, setCurrentTableRows] = useState<
    ReactRowType<AnnualPersonnelCost>[]
  >([]);
  const data = usePersonnelCostsAnnualQuery(selectedYear, onlyIncludeAccepted);

  const {
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForPieChart,
    secondDataForPieChart,
  } = useAnnualPersonnelCosts(data.data?.data.results, currentTableRows);

  const { columnInfo } = useAnnualPersonnelCostsColumnDef();

  return data.isLoading || !data.data ? (
    <CenteredSpinner />
  ) : (
    <MainContainer>
      <VStack sx={mainVStack}>
        <HStack sx={pageControlsHStack}>
          <PageHeading
            text="Koszty osobowe"
            dateControl={
              <YearControl
                currentYear={selectedYear}
                setCurrentYear={setSelectedYear}
              />
            }
          ></PageHeading>
          <Switch
            label="Pokaż dla zatwierdzonych"
            isChecked={onlyIncludeAccepted}
            onChange={() => {
              setOnlyIncludeAccepted(!onlyIncludeAccepted);
              setCurrentTableRows([]);
            }}
          />
        </HStack>
        {!data.data.data.results.length ? (
          <EmptyState />
        ) : (
          <>
            <HStack sx={subHStack}>
              <Box>
                <BarChart
                  chartData={dataForBarChart}
                  secondChartData={secondDataForBarChart}
                  mainHeader="Suma kosztów zatrudnienia w miesiącach PLN"
                />
              </Box>
              <Box>
                <PieChart
                  chartData={dataForPieChart}
                  secondChartData={secondDataForPieChart}
                  mainHeader="Sumy dla klas PLN"
                  chartSubHeader="Suma roczna"
                  secondPieChartSubHeader="Suma wyfiltrowanych"
                />
              </Box>
              {financeCardsToDisplay.map((card) => (
                <Box key={card.id} sx={financeCardContainer}>
                  <FinanceCard card={card} />
                </Box>
              ))}
            </HStack>
            <MainTable<AnnualPersonnelCost>
              data={data.data.data.results}
              columnInfo={columnInfo}
              rowsEditableByButtons={false}
              setCurrentTableRows={setCurrentTableRows}
            />
          </>
        )}
      </VStack>
    </MainContainer>
  );
};
