export const monthYearControlStyling = {
  container: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
    width: 'fit-content',
  },
  header: {
    fontSize: '1.25rem',
    fontWeight: '300',
    textTransform: 'lowercase',
    textAlign: 'center',
    minWidth: '9.5rem',
  },
};
