import { PieChartData } from 'components/Charts/PieChart/types';
import { areTwoPieChartDataSetsEqual } from 'components/Charts/PieChart/utils';
import { currencyOptions } from 'utils/index';
import { PersonnelIncomeRow, PersonnelIncomeRowType } from './types';
import { Option } from 'components/form/inputs/AutocompleteInput/AutocompleteInput.types';

export const getPersonnelIncomeColumnInfo = (
  positionsColumnOptions?: Array<Option>,
  projectsColumnOptions?: Array<Option>
) => [
  {
    id: 'lastNameFirstName',
    header: 'Nazwisko i imię',
    getRow: (row: PersonnelIncomeRow) => {
      if (row.lastName && row.firstName) {
        return `${row.lastName} ${row.firstName}`;
      }
    },
    sortType: 'text' as const,
    filterFn: 'includesString' as const,
  },
  {
    id: 'positionName',
    header: 'Klasa',
    getRow: (row: PersonnelIncomeRow) =>
      row.position ? row.position.name : '-',
    sortType: 'text' as const,
    filterFn: 'equalsString' as const,
    filterFieldLabel: 'Klasa',
    columnOptions: positionsColumnOptions,
    editable: 'select' as const,
  },
  {
    id: 'billable',
    header: 'Bilowalność',
    getRow: (row: PersonnelIncomeRow) =>
      row.billable == null ? '' : `${row.billable} %`,
    sortType: 'alphanumeric' as const,
  },
  {
    id: 'name',
    header: 'Projekt',
    getRow: (row: PersonnelIncomeRow) => {
      if (row.project?.name) {
        return row.project?.name;
      }

      if (row.subRows && row.subRows.length > 0) {
        return row.subRows.map((subRow) => {
          return subRow.project?.name;
        });
      }
    },
    sortType: 'text' as const,
    filterFn: 'arrIncludes' as const,
    filterFieldLabel: 'Projekt',
    columnOptions: projectsColumnOptions,
    editable: 'select' as const,
  },
  {
    id: 'hours',
    header: 'Godziny',
    getRow: (row: PersonnelIncomeRow) => row.projectHours || '',
    sortType: 'alphanumeric' as const,
    filterFn: 'inNumberRange' as const,
    filterFieldLabel: 'Zakres godzin roboczych',
    editable: 'numericInputField' as const,
  },
  {
    id: 'amountAmount',
    header: 'Stawka',
    getRow: (row: PersonnelIncomeRow) => row.projectAmountAmount || '',
    sortType: 'alphanumeric' as const,
    filterFn: 'inNumberRange' as const,
    filterFieldLabel: 'Zakres stawek',
    editable: 'numericInputField' as const,
  },
  {
    id: 'amountCurrency',
    header: 'Waluta',
    getRow: (row: PersonnelIncomeRow) => {
      if (row.projectAmountCurrency) return row.projectAmountCurrency;

      if (row.subRows?.length)
        return row.subRows.map((subRow) => subRow.projectAmountCurrency);
    },
    sortType: 'text' as const,
    columnOptions: currencyOptions,
    editable: 'select' as const,
    filterFn: 'arrIncludes' as const,
    filterFieldLabel: 'Waluta',
  },
];

export const isEitherOneOfPieChartDataSetsComparisonsDifferent = (
  comparisonASetA: PieChartData | undefined,
  comparisonASetB: PieChartData | undefined,
  comparisonBSetA: PieChartData | undefined,
  comparisonBSetB: PieChartData | undefined
) => {
  return (
    !areTwoPieChartDataSetsEqual(comparisonASetA, comparisonASetB) ||
    !areTwoPieChartDataSetsEqual(comparisonBSetA, comparisonBSetB)
  );
};

export const doesRowHaveExtraButton = (row: PersonnelIncomeRowType) =>
  row && row.original.hasAddButton;

// Converts type returned by Table to type passed to Table
export const convertNestedPersonnelIncomeReactRowTypeToOriginal = (
  data: Array<PersonnelIncomeRowType>
) =>
  data.map((dataPortion) => {
    const elemToReturn = dataPortion.original;
    elemToReturn.subRows = dataPortion.subRows.map(({ original }) => original);
    return elemToReturn;
  });

export const isAllZero = (array: Array<number>) =>
  array.every((item) => item === 0);
