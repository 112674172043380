import { QueryFunctionContext } from 'react-query';
import client from '../../api/axiosConfig';
import { personnelIncomesAnnualByProjectApiUrl } from '../../utils/paths';
import { AnnualQueryKey } from '../../types/AnnualQueryKey';
import {
  AggregatedEmployee,
  AnnualPersonnelIncomeByProject,
  AnnualPersonnelIncomeByProjectRow,
} from './types/annualPersonnelIncomeByProject';
import { GroupedSums } from '../../types/AnnualViews';
import { sumNumeralArrays, formatAmountNumeral } from '../../utils';
import { cloneDeep } from 'lodash';
import numeral, { Numeral } from 'numeral';

export const fetchPersonnelIncomesByProjectAnnual = (
  context: QueryFunctionContext<AnnualQueryKey>
) => {
  const [, year = '', onlyAccepted] = context.queryKey;
  return client.get<{ results: AnnualPersonnelIncomeByProject[] }>(
    `${personnelIncomesAnnualByProjectApiUrl}?year=${year}&include_predictions=${
      onlyAccepted ? 0 : 1
    }`
  );
};

export const mapForTableWithSubRows = (
  data?: AnnualPersonnelIncomeByProject[]
): AnnualPersonnelIncomeByProjectRow[] | undefined =>
  data?.map((income) => ({
    project: income.project,
    amount: [],
    subRows: income.employees.map((employee) => ({ ...employee, subRows: [] })),
  }));

export const flattenRowsWithSubRows = (
  data?: AnnualPersonnelIncomeByProjectRow[]
) =>
  data
    ?.map(({ subRows }) =>
      subRows.map((subRow) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { subRows: _, ...rest } = subRow;
        return rest;
      })
    )
    .flat() as AggregatedEmployee[];

export const getDisplaySums = (data?: AnnualPersonnelIncomeByProjectRow[]) =>
  data?.map((cost) => ({
    ...cost,
    displayTotalAnnualAmount: formatAmountNumeral(cost.totalAnnualAmount),
    amount: cost.amount.map((value) => ({
      ...value,
      displayAmountPLN: formatAmountNumeral(value.amountPLN),
    })),
    subRows: cost.subRows.map((subRow) => ({
      ...subRow,
      displayTotalAnnualAmount: formatAmountNumeral(subRow.totalAnnualAmount),
      amount: subRow.amount.map((value) => ({
        ...value,
        displayAmountPLN: formatAmountNumeral(value.amountPLN),
      })),
    })),
  }));

export const getTotalAnnualSumsByName = (
  data?: AnnualPersonnelIncomeByProjectRow[]
): GroupedSums => {
  if (!data) return {};

  const sumsByNames: [string | undefined, Numeral][] = data.map((income) => {
    const sums = sumNumeralArrays(
      cloneDeep(
        income.subRows.map((subRow) =>
          subRow.amount.map(({ amountPLN }) => amountPLN || numeral(0))
        )
      )
    );

    return [
      income.project?.name,
      sums.reduce((a, b) => a.add(b?.value()), numeral(0)),
    ];
  });

  return Object.fromEntries(
    sumsByNames.filter((sum) => sum[0] !== undefined) as [string, Numeral][]
  );
};
