import { Input, HStack, Text, VStack } from '@chakra-ui/react';
import { filtersStyling } from 'components/mainTable/styles';
import ErrorLabel from '../ErrorLabel';
import { useEffect, useMemo, useState } from 'react';
import { RangeFilterProps, Range } from './RangeFilter.types';

const RangeFilter = <T,>({ column, label }: RangeFilterProps<T>) => {
  const [err, setErr] = useState('');

  const filters = useMemo(
    () => (column.getFilterValue() as Range) ?? ['', ''],
    [column]
  );

  const isCorrect = filters[0] <= filters[1];
  const areFilters = filters[0] && filters[1];

  // Clear errors
  useEffect(() => {
    if ((isCorrect || !areFilters) && err !== '') setErr('');
  }, [areFilters, err, filters, isCorrect]);

  return (
    <VStack sx={filtersStyling.inputContainer}>
      <Text sx={{ ...filtersStyling.header }}>{label}</Text>
      <HStack>
        <Input
          id={`${column.id}-number-from-filter`}
          type="number"
          value={filters[0] || ''}
          placeholder="od"
          sx={filtersStyling.input}
          onChange={({ target }) => {
            column.setFilterValue((old: Range) => [
              target.value ? Number(target.value) : '',
              old?.[1],
            ]);
          }}
          onBlur={() => {
            if (areFilters && !isCorrect) setErr("Pole 'od' musi być mniejsze");
          }}
        />
        <Text> - </Text>
        <Input
          id={`${column.id}-number-to-filter`}
          type="number"
          value={filters[1] || ''}
          placeholder="do"
          sx={filtersStyling.input}
          onChange={({ target }) => {
            column.setFilterValue((old: Range) => [
              old?.[0],
              target.value ? Number(target.value) : '',
            ]);
          }}
          onBlur={() => {
            if (areFilters && !isCorrect) setErr("Pole 'do' musi być większe");
          }}
        />
      </HStack>
      <ErrorLabel error={err} />
    </VStack>
  );
};

export default RangeFilter;
