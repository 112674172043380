import { useNonPersonnelCostsAnnualQuery } from '../../nonPersonnelCostsAnnual/hooks/useNonPersonnelCostsAnnualQuery';
import { usePersonnelCostsAnnualQuery } from '../../personnelCostsAnnual/hooks/usePersonnelCostsAnnualQuery';

export const useCostQueries = (year: number) => {
  const nonPersonnelCosts = useNonPersonnelCostsAnnualQuery(year, true);

  const potientialNonPersonnelCosts = useNonPersonnelCostsAnnualQuery(
    year,
    false
  );

  const personnelCosts = usePersonnelCostsAnnualQuery(year, true);

  const potientialPersonnelCosts = usePersonnelCostsAnnualQuery(year, false);

  return {
    nonPersonnelCosts,
    potientialNonPersonnelCosts,
    personnelCosts,
    potientialPersonnelCosts,
  };
};
