import { useAnnualPersonnelIncomesByProject } from '../../personnelIncomesByProjectAnnual/hooks/useAnnualPersonnelIncomesByProject';
import { AnnualPersonnelIncomeByProject } from '../../personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { useAnnualRecurringIncomes } from '../../recurringIncomeAnnual/hooks/useAnnualRecurringIncomes';
import { AnnualRecurringIncome } from '../../recurringIncomeAnnual/types/annualRecurringIncome';
import { useIncomesBarChartData } from './useIncomesBarChartData';
import { useIncomesFinanceCards } from './useIncomesFinanceCards';
import { useMappedAnnualSummaryIncome } from './useMappedAnnualSummaryIncome';

export const useAnnualSummaryIncomes = (
  personnel?: AnnualPersonnelIncomeByProject[],
  potentialPersonnel?: AnnualPersonnelIncomeByProject[],
  reccuring?: AnnualRecurringIncome[],
  potentialReccuring?: AnnualRecurringIncome[]
) => {
  const personnelTransformed = useAnnualPersonnelIncomesByProject(personnel);
  const reccuringTransformed = useAnnualRecurringIncomes(reccuring);

  const potentialPersonnelTransformed =
    useAnnualPersonnelIncomesByProject(potentialPersonnel);
  const potentialNonPersonnelTransformed =
    useAnnualRecurringIncomes(potentialReccuring);

  const summaryCosts = useMappedAnnualSummaryIncome(
    personnelTransformed.displayData,
    reccuringTransformed.displayData
  );

  const financeCardsToDisplay = useIncomesFinanceCards(
    personnelTransformed.financeCardsToDisplay,
    potentialPersonnelTransformed.financeCardsToDisplay,
    reccuringTransformed.financeCardsToDisplay,
    potentialNonPersonnelTransformed.financeCardsToDisplay
  );

  const dataForBarChart = useIncomesBarChartData(
    personnelTransformed.dataForBarChart,
    reccuringTransformed.dataForBarChart
  );

  return {
    data: summaryCosts,
    dataForBarChart,
    financeCardsToDisplay,
  };
};
