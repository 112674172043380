import { HStack, Heading } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props {
  text: string;
  dateControl?: ReactNode;
}

const PageHeading: FC<Props> = ({ text, dateControl }) => (
  <HStack marginTop="0.5rem" alignSelf="flex-start">
    <Heading as="h1" fontSize="1.5rem" fontWeight={400} noOfLines={1}>
      {text}
    </Heading>
    {dateControl}
  </HStack>
);

export default PageHeading;
