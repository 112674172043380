import { useMemo } from 'react';
import { AnnualPersonnelIncomeByProjectRow } from '../types/annualPersonnelIncomeByProject';
import { getDisplaySums } from '../utils';
import { useSumsForProjects } from './useSumsForProjects';

export const useDisplaySums = (data?: AnnualPersonnelIncomeByProjectRow[]) => {
  const dataWithProjectSums = useSumsForProjects(data);

  return useMemo(
    () => getDisplaySums(dataWithProjectSums),
    [dataWithProjectSums]
  );
};
