import { QueryFunctionContext } from 'react-query';
import client from '../../api/axiosConfig';
import { nonPersonnelCostsApiUrl } from '../../utils/paths';
import { NonPersonnelCostForFetch } from './types';
import { MonthlyQueryKey } from '../../types/MonthlyQueryKey';

export const fetchNonPersonnelCosts = (
  context: QueryFunctionContext<MonthlyQueryKey>
) => {
  const [, year = '', month = ''] = context.queryKey;

  return client.get<{ results: NonPersonnelCostForFetch[] }>(
    `${nonPersonnelCostsApiUrl}?month=${month}&year=${year}`
  );
};
