import { UseDisclosureReturn } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { personnelIncomesApiUrl, projectsApiUrl } from '../../utils/paths';
import { requiredValidation } from '../../components/form/validation';
import { Employee } from '../personnelCosts/types/addEmployee';
import { FormSelect } from '../../components/form/inputs/select';
import { CurrencyCodes, currencyOptions } from '../../utils';
import DrawerOpeningButton from '../../components/common/drawerButton';
import client from '../../api/axiosConfig';
import Drawer from '../../components/common/drawer';
import { NumberInput } from '../../components/form/inputs/numberInput';
import {
  FetchedPersonnelIncome,
  PersonnelIncomeForm,
  PersonnelIncomeInterface,
} from './types';
import { toNumber } from 'lodash';
import AutocompleteInput, {
  createOption,
} from '../../components/form/inputs/AutocompleteInput';
import { Project } from '../personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { AutoCompleteValue } from '../../components/form/inputs/AutocompleteInput/AutocompleteInput.types';
import { SelectOption } from 'types/selectOptions';
import { useEmployeesFromQuery } from './hooks/queries/useEmployeesFromQuery';

interface Props extends UseDisclosureReturn {
  selectedMonth: number;
  selectedYear: number;
  selectedEmployee?: string;
  setSelectedEmployee?: Dispatch<SetStateAction<string | undefined>>;
  afterIncomeCreate?: () => Promise<unknown>;
}

export const AddIncome: FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
  selectedMonth,
  selectedYear,
  selectedEmployee,
  setSelectedEmployee,
  afterIncomeCreate = () => undefined,
}) => {
  const queryClient = useQueryClient();

  const [options, setOptions] = useState<Array<SelectOption>>([]);

  const employees = useEmployeesFromQuery();

  useQuery(
    ['projects', true],
    () =>
      client.get<{ results: Project[] }>(`${projectsApiUrl}?is_billable=True`),
    {
      onSuccess: ({ data }) => {
        setOptions(
          data.results.map(({ id, name }) => ({
            value: `${id}`,
            label: name,
          }))
        );
      },
    }
  );

  const addPersonnelIncome = useMutation(
    (data: PersonnelIncomeForm) => {
      const { employeeId, project, amountAmount, amountCurrency, hours } = data;

      return client.post<{ results: Employee }>(personnelIncomesApiUrl, {
        employeeId,
        project: { id: Number(project.value), name: project.label },
        amountAmount: amountAmount ? toNumber(amountAmount) : 0,
        amountCurrency,
        hours,
        month: selectedMonth,
        year: selectedYear,
      });
    },
    {
      onSuccess: async () => {
        await afterIncomeCreate();

        closeDrawer();
      },
    }
  );

  const methods = useForm<PersonnelIncomeForm>({
    defaultValues: {
      amountCurrency: 'EUR',
    },
  });

  const handleSubmit = async (data: PersonnelIncomeForm) =>
    await addPersonnelIncome.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  useEffect(() => {
    if (selectedEmployee) {
      methods.setValue('employeeId', selectedEmployee);
    }
  }, [methods, selectedEmployee]);

  useEffect(() => {
    if (!isOpen) {
      return () => {
        if (setSelectedEmployee) {
          setSelectedEmployee(undefined);
          methods.setValue('employeeId', '');
        }
      };
    }
  }, [setSelectedEmployee, isOpen, methods]);

  const handleCreate = (inputValue: string) => {
    setOptions([...options, createOption(inputValue)]);
  };

  const onChangeHandler = (value: AutoCompleteValue) => {
    if (typeof value !== 'string') {
      const findProject = (project: PersonnelIncomeInterface) =>
        project.project.id === Number(value?.value);

      const personnelIncomes =
        queryClient.getQueryData<
          AxiosResponse<{ results: FetchedPersonnelIncome[] }>
        >(['personnelIncomes', selectedYear, selectedMonth])?.data?.results ??
        [];

      const project = personnelIncomes
        .find((income) => income.projects.some(findProject))
        ?.projects.find(findProject)?.amountCurrency;

      methods.setValue('amountCurrency', project ?? CurrencyCodes.Euro);
    }
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj nowy przychód" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj nowy przychód"
          isOpen={isOpen}
          onClose={closeDrawer}
          onSubmit={handleSubmit}
        >
          <>
            <FormSelect
              options={employees}
              name="employeeId"
              placeholder="Pracownik"
              autoFocus
              registerProps={requiredValidation}
            />
            <AutocompleteInput
              name="project"
              options={options}
              onCreateOption={handleCreate}
              registerProps={requiredValidation}
              onChangeHandler={onChangeHandler}
              noOptionsPlaceholder="Brak projektów"
            />
            <NumberInput name="amountAmount" placeholder="Stawka" />
            <FormSelect
              options={currencyOptions}
              name="amountCurrency"
              placeholder="Waluta"
              defaultValue="EUR"
            />
            <NumberInput name="hours" placeholder="Godziny" />
          </>
        </Drawer>
      </FormProvider>
    </>
  );
};
