import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Row as ReactRowType } from '@tanstack/react-table';
import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import client from '../../api/axiosConfig';
import { MainTable } from '../../components/mainTable';
import { CenteredSpinner } from '../../components/centeredSpinner';
import { MainContainer } from '../../components/mainContainer';
import { AddEmployee } from './addEmployee';
import { MonthYearControl } from '../../components/dateControl/monthYearControl';
import { contractTypeApiUrl, positionApiUrl } from '../../utils/paths';
import { PersonnelCostForFetch } from './types/personnelCost';
import { ContractType } from '../settings/pageComponents/contractType/types';
import { Position } from '../settings/pageComponents/position/types';
import { basicContainer, pageControlsHStack } from '../../styles/global';
import usePersonnelCosts from './usePersonnelCosts';
import { financeCardContainer } from '../recurringIncome/styles';
import FinanceCard from '../../components/financeCard';
import { FinanceCardsContainer } from '../../components/financeCard/financeCardsContainer';
import { usePersonnelCostsColumnDef } from './hooks/usePersonnelCostsColumnDef';
import { usePersonnelCostsQuery } from './hooks/usePersonnelCostsQuery';
import { useSinglePersonnelCostMutations } from './hooks/useSinglePersonnelCostMutations';
import { useMultiplePersonnelCostsMutations } from './hooks/useMultiplePersonnelCostsMutations';
import { usePersonnelCostActions } from './hooks/usePersonnelCostActions';
import { useYearMonth } from '../../hooks/useYearMonth';
import {
  checkIsAmountHasEverChanged,
  checkIsDeleted,
  checkIsPrediction,
} from '../../utils/table';
import PageHeading from 'components/common/pageHeading';
import EmptyState from 'components/EmptyState/EmptyState';

/*eslint-disable @typescript-eslint/no-misused-promises*/

export const PersonnelCosts: FC = () => {
  const queryClient = useQueryClient();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentTableRows, setCurrentTableRows] = useState<
    ReactRowType<PersonnelCostForFetch>[]
  >([]);
  const [selectedTableRows, setSelectedTableRows] = useState<
    ReactRowType<PersonnelCostForFetch>[]
  >([]);

  const addEmployeeDisclosure = useDisclosure();

  const { year, month } = useYearMonth(selectedDate);

  const personnelCostsQuery = usePersonnelCostsQuery(year, month);

  const positionsQuery = useQuery(
    'positions',
    () => client.get<{ results: Position[] }>(positionApiUrl),
    {}
  );

  const contractTypeQuery = useQuery(
    'contractTypes',
    () => client.get<{ results: ContractType[] }>(contractTypeApiUrl),
    {}
  );

  const { acceptAllMutation, editSelectedMutation } =
    useMultiplePersonnelCostsMutations(personnelCostsQuery);

  const { remove, change, create } = useSinglePersonnelCostMutations(
    queryClient,
    personnelCostsQuery,
    year,
    month
  );

  const afterAction = useCallback(() => {
    setCurrentTableRows([]);
    setSelectedTableRows([]);
  }, []);

  const { mutateFromCellEdit, changeIsDeleted, accept, unaccept } =
    usePersonnelCostActions(remove, change, create, afterAction);

  const { columnInfo } = usePersonnelCostsColumnDef(
    positionsQuery,
    contractTypeQuery
  );

  const { financeCardsToDisplay } = usePersonnelCosts(
    personnelCostsQuery.data?.data.results,
    currentTableRows,
    selectedTableRows
  );

  return personnelCostsQuery.isLoading || !personnelCostsQuery.data ? (
    <CenteredSpinner />
  ) : (
    <MainContainer>
      <VStack sx={basicContainer}>
        <HStack sx={pageControlsHStack}>
          <PageHeading
            text="Koszty osobowe"
            dateControl={
              <MonthYearControl
                currentDate={selectedDate}
                setCurrentDate={(currentDate) => {
                  setSelectedDate(currentDate);
                  afterAction();
                }}
              />
            }
          />
          <AddEmployee
            year={year}
            month={month}
            positions={positionsQuery}
            contractTypes={contractTypeQuery}
            personnelCostsQuery={personnelCostsQuery}
            {...addEmployeeDisclosure}
          />
        </HStack>
        {!personnelCostsQuery.data?.data.results.length ? (
          <EmptyState />
        ) : (
          <>
            <FinanceCardsContainer>
              {financeCardsToDisplay && (
                <>
                  {financeCardsToDisplay.map((financeCardToDisplay) => (
                    <Box
                      key={financeCardToDisplay.id}
                      sx={financeCardContainer}
                    >
                      <FinanceCard card={financeCardToDisplay} />
                    </Box>
                  ))}
                </>
              )}
            </FinanceCardsContainer>
            <MainTable<PersonnelCostForFetch>
              data={personnelCostsQuery.data.data.results}
              columnInfo={columnInfo}
              tabFocusableColumnId="amountAmount"
              updateFnForMainButton={editSelectedMutation.mutate}
              onClickAcceptAllButton={acceptAllMutation.mutate}
              acceptInstance={accept}
              unacceptInstance={unaccept}
              deleteInstance={changeIsDeleted}
              updateFnForCellEdit={mutateFromCellEdit}
              checkIsPrediction={checkIsPrediction}
              checkIsDeleted={checkIsDeleted}
              checkIsAmountHasEverChanged={checkIsAmountHasEverChanged}
              columnIdToChangeColor={'amountAmount'}
              isModalOpen={addEmployeeDisclosure.isOpen}
              setCurrentTableRows={setCurrentTableRows}
              setSelectedTableRows={setSelectedTableRows}
              initialSorting={[{ id: 'lastNameFirstName', desc: false }]}
            />
          </>
        )}
      </VStack>
    </MainContainer>
  );
};
