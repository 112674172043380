import { FC } from 'react';
import { SelectOption } from '../../types/selectOptions';

import { default as ReactSelect, SingleValue } from 'react-select';

import { DropdownIndicator } from './DropdownIndicator';
import { customStyles } from './styles';
import { CustomStylesI } from './types';

interface Props {
  options: SelectOption[];
  onChange: (value: SingleValue<SelectOption>) => void;
  variant: keyof CustomStylesI;
  onClose?: () => void;
  currentValue?: SingleValue<SelectOption>;
  defaultValue?: SingleValue<SelectOption>;
  isDisabled?: boolean;
  id?: string;
  error?: boolean;
  placeholder?: string;
}

export const Select: FC<Props> = ({
  options,
  onChange,
  onClose,
  currentValue,
  defaultValue,
  isDisabled,
  id,
  variant,
  error,
  placeholder = '',
}) => (
  <ReactSelect
    id={id}
    placeholder={placeholder}
    aria-invalid={error}
    defaultValue={defaultValue}
    value={currentValue}
    isMulti={false}
    autoFocus={false}
    isSearchable={false}
    options={options}
    isDisabled={isDisabled}
    onChange={onChange}
    components={{ DropdownIndicator }}
    onMenuClose={onClose}
    styles={customStyles[variant]}
  />
);
