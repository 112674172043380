export const drawerStyling = {
  container: {
    height: '100%',
    display: 'block',
    widht: '16rem',
    marginLeft: 'auto',
    padding: '1rem 1rem',
  },
  containerButton: { display: 'flex', justifyContent: 'end', gap: '0.5rem' },
};

export const saveButtonStyling = {
  color: 'white',
  backgroundColor: 'blue.600',
  _hover: { backgroundColor: 'blue.600' },
  _focus: { backgroundColor: 'blue.600' },
};

export const cancelButtonStyling = {
  color: 'blue.600',
  borderColor: 'blue.600',
  backgroundColor: 'transparent',
  _hover: { backgroundColor: 'transparent' },
  _focus: { backgroundColor: 'transparent' },
};
