import {
  PersonnelIncomeBulk,
  PersonnelIncomeQueryResult,
  PersonnelIncomeRow,
} from '../../types';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../api/axiosConfig';
import { personnelIncomesBulkApiUrl } from '../../../../utils/paths';
import { useMemo } from 'react';
import { MonthlyQueryKey } from 'types/MonthlyQueryKey';
import { UpdateFnForMainButtonInput } from 'components/mainTable/types';
import { Project } from 'pages/personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';

const useMultiplePersonnelIncomeMutations = (
  year: number,
  month: number,
  query: PersonnelIncomeQueryResult
) => {
  const queryClient = useQueryClient();

  const queryKey: MonthlyQueryKey = useMemo(
    () => ['personnelIncomes', year, month],
    [month, year]
  );

  const editSelectedMutation = useMutation(
    (bulkUpdate: UpdateFnForMainButtonInput<PersonnelIncomeRow>) => {
      const mappedPersonnelIncomeBulk: PersonnelIncomeBulk = {
        personnelIncomeDtos: bulkUpdate.dataForUpdate
          .filter((row) => !row.subRows.length)
          .map((row) => ({
            id: row.id,
            employeeId: row.employeeId,
            amountAmount: row.projectAmountAmount as string,
            amountCurrency: row.projectAmountCurrency as string,
            hours: row.projectHours,
            project: row.project as Project,
            month: row.month as number,
            year: row.year as number,
            isDeleted: row.isDeleted as boolean,
            isAmountChanged: row.isAmountChanged as boolean,
          })),
        updateTo: bulkUpdate.updateTo,
        actionToPerfom: bulkUpdate.actionToPerfom,
      };

      return client.post<{
        results: PersonnelIncomeBulk;
      }>(personnelIncomesBulkApiUrl, mappedPersonnelIncomeBulk);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const acceptAllMutation = useMutation(
    () => {
      const mappedPersonnelIncomeBulk: PersonnelIncomeBulk = {
        personnelIncomeDtos:
          query.data?.data.results.map(({ projects }) => projects).flat() || [],
        updateTo: true,
        actionToPerfom: 'make_accepted_or_unaccepted',
      };

      return client.post<{
        results: PersonnelIncomeBulk;
      }>(personnelIncomesBulkApiUrl, mappedPersonnelIncomeBulk);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return { editSelectedMutation, acceptAllMutation };
};

export default useMultiplePersonnelIncomeMutations;
