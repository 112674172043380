import { Flex, IconButton } from '@chakra-ui/react';
import {
  ColumnDef,
  Row as ReactRowType,
  Table as ReactTableType,
} from '@tanstack/react-table';
import {
  MdDone,
  MdEditOff,
  MdOutlineDeleteOutline,
  MdReplay,
} from 'react-icons/md';
import { IndeterminateCheckbox } from './indeterminateCheckbox';
import { GeneralTableButtonTooltip } from './generalTableButtonTooltip';
import { buttonIconStyling } from '../../../pages/settings/settingsTable/styles';
import { TableExtraButton } from '../types';

export function wrapGivenColumns<T>(
  givenColumns: ColumnDef<T, void>[],
  checkIsPrediction: (row: ReactRowType<T>) => boolean,
  checkIsDeleted: (row: ReactRowType<T>) => boolean,
  deleteInstance?: (row: ReactRowType<T>, data: { isDeleted: boolean }) => void,
  acceptInstance?: (row: ReactRowType<T>) => void,
  unacceptInstance?: (row: ReactRowType<T>) => void,
  tableExtraButton?: TableExtraButton<T>
): ColumnDef<T, void>[] {
  return [
    {
      id: 'select',
      header: ({ table }: { table: ReactTableType<T> }) => (
        <IndeterminateCheckbox
          id="header-checkbox"
          isChecked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }: { row: ReactRowType<T> }) => (
        <IndeterminateCheckbox
          id={`row-checkbox-${row.id}`}
          isChecked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    },
    ...givenColumns,
    {
      id: 'icons',
      cell: ({ row }: { row: ReactRowType<T> }) => (
        <Flex>
          {tableExtraButton?.doesRowHaveExtraButton &&
            tableExtraButton.doesRowHaveExtraButton(row) && (
              <GeneralTableButtonTooltip
                label={tableExtraButton.extraButtonLabel}
              >
                <IconButton
                  aria-label={'extra table button'}
                  icon={tableExtraButton.buttonIcon}
                  sx={buttonIconStyling}
                  onClick={() => {
                    tableExtraButton.extraButtonFunc(row);
                  }}
                />
              </GeneralTableButtonTooltip>
            )}
          {checkIsPrediction(row) ? (
            <GeneralTableButtonTooltip label="Akceptuj rekord">
              <IconButton
                aria-label={'accept record'}
                icon={<MdDone />}
                sx={{ ...buttonIconStyling }}
                onClick={() => {
                  if (acceptInstance) {
                    acceptInstance(row);
                  }
                }}
              />
            </GeneralTableButtonTooltip>
          ) : (
            <GeneralTableButtonTooltip label="Odakceptuj rekord">
              <IconButton
                aria-label={'unaccept record'}
                icon={<MdEditOff />}
                sx={{ ...buttonIconStyling }}
                onClick={() => {
                  if (unacceptInstance) {
                    unacceptInstance(row);
                  }
                }}
              />
            </GeneralTableButtonTooltip>
          )}
          {checkIsDeleted(row) ? (
            <GeneralTableButtonTooltip label="Przywróc rekord">
              <IconButton
                aria-label={'undelete record'}
                icon={<MdReplay />}
                sx={{ ...buttonIconStyling }}
                onClick={() => {
                  if (deleteInstance) {
                    deleteInstance(row, { isDeleted: false });
                  }
                }}
              />
            </GeneralTableButtonTooltip>
          ) : (
            <GeneralTableButtonTooltip label="Usuń rekord">
              <IconButton
                aria-label={'delete record'}
                icon={<MdOutlineDeleteOutline />}
                sx={{ ...buttonIconStyling }}
                onClick={() => {
                  if (deleteInstance) {
                    deleteInstance(row, { isDeleted: true });
                  }
                }}
              />
            </GeneralTableButtonTooltip>
          )}
        </Flex>
      ),
    },
  ];
}
