import { usePersonnelIncomesByProjectAnnualQuery } from '../../personnelIncomesByProjectAnnual/hooks/usePersonnelIncomesByProjectAnnualQuery';
import { useRecurringIncomesAnnualQuery } from '../../recurringIncomeAnnual/hooks/useRecurringIncomesAnnualQuery';

export const useIncomeQueries = (year: number) => {
  const personnelIncomes = usePersonnelIncomesByProjectAnnualQuery(year, true);

  const potientialPersonnelIncomes = usePersonnelIncomesByProjectAnnualQuery(
    year,
    false
  );

  const recurringIncomes = useRecurringIncomesAnnualQuery(year, true);

  const potientialRecurringIncomes = useRecurringIncomesAnnualQuery(
    year,
    false
  );

  return {
    personnelIncomes,
    potientialPersonnelIncomes,
    recurringIncomes,
    potientialRecurringIncomes,
  };
};
