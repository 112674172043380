import numeral from 'numeral';
import {
  PersonnelIncomeRow,
  PersonnelIncomeRowType,
} from 'pages/personnelIncomes/types';
import { GroupedSums } from 'types/AnnualViews';
import { PersonnelIncomeRowWithPLN } from './types';

export const getSumsByProjectId = (
  data?: PersonnelIncomeRowWithPLN[]
): GroupedSums => {
  const allProjects = [
    ...new Set(data?.map(({ project }) => project?.id)),
  ].filter((project) => project !== undefined) as Array<number>;

  const sums = Object.fromEntries(
    allProjects.map((id) => [id.toString(), numeral(0)])
  );

  data?.forEach(({ project, amountPLN }) => {
    if (project?.id == null || amountPLN == null) return;

    sums[project.id.toString()].add(amountPLN.value());
  });

  return sums;
};

export const getSumsByEmployeePosition = (
  data?: PersonnelIncomeRowWithPLN[]
): GroupedSums => {
  const allPositions = [
    ...new Set(data?.map(({ position }) => position?.id)),
  ].filter((position) => position !== undefined) as Array<number>;

  const sums = Object.fromEntries(
    allPositions.map((id) => [id.toString(), numeral(0)])
  );

  data?.forEach(({ position, amountPLN }) => {
    if (position?.id == null || amountPLN == null) return;

    sums[position.id.toString()].add(amountPLN.value());
  });

  return sums;
};

/** Function that accepts rows with subRows.
 * This data shouldn't come from useTable hook from react-table,
 * but rather be data that would be passed to this hook.
 * Returned data is a flat array with subRows extracted from all the rows passed to it,
 * with 'position' field value copied from the parent row. */
export const flattenPersonnelIncomeRows = (
  data?: PersonnelIncomeRow[]
): PersonnelIncomeRow[] | undefined =>
  data
    ?.filter((row) => !!row.subRows.length)
    ?.map((row) =>
      row.subRows.map((subRow) => ({
        ...subRow,
        position: row.position,
      }))
    )
    .flat(1);

/** Function that accepts rows with subRows.
 * This data comes from useTable hook from react-table (this are usually selected or filtered rows).
 * Returned data is a flat array with subRows extracted from all the rows passed to it,
 * with 'position' field value copied from the parent row. */
export const flattenPersonnelIncomeTableRows = (
  data?: PersonnelIncomeRowType[]
): PersonnelIncomeRow[] | undefined =>
  data
    ?.filter((row) => !row.original.subRows.length)
    ?.map((row) => ({
      ...row.original,
      position: row.getParentRow()?.original.position || null,
    }));
