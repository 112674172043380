import { ChartOptions } from 'chart.js';

export const BAR_CHART_OPTIONS: ChartOptions<'bar'> = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
