import { QueryFunctionContext } from 'react-query';
import { AnnualRecurringIncome } from './types/annualRecurringIncome';
import client from '../../api/axiosConfig';
import { recurringIncomesAnnualApiUrl } from '../../utils/paths';
import numeral from 'numeral';
import { AnnualQueryKey } from '../../types/AnnualQueryKey';
import { GroupedSums } from '../../types/AnnualViews';

export const fetchRecurringIncomesAnnual = (
  context: QueryFunctionContext<AnnualQueryKey>
) => {
  const [, year = '', onlyAccepted] = context.queryKey;
  return client.get<{ results: AnnualRecurringIncome[] }>(
    `${recurringIncomesAnnualApiUrl}?year=${year}&include_predictions=${
      onlyAccepted ? 0 : 1
    }`
  );
};

export const getTotalAnnualSumsByName = (
  data?: AnnualRecurringIncome[]
): GroupedSums => {
  const allNames = [...new Set(data?.map(({ name }) => name))];

  const sums = Object.fromEntries(allNames.map((name) => [name, numeral(0)]));

  data?.forEach(({ name, totalAnnualAmount }) => {
    if (totalAnnualAmount == null) return;

    sums[name].add(totalAnnualAmount.value());
  });

  return sums;
};
