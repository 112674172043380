import { AnnualQueryKey } from '../../../types/AnnualQueryKey';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchNonPersonnelCostsAnnual } from '../utils';

export const useNonPersonnelCostsAnnualQuery = (
  year: number,
  onlyAccepted: boolean
) => {
  const queryKey: AnnualQueryKey = useMemo(
    () => ['nonPersonnelCostsAnnual', year, onlyAccepted],
    [onlyAccepted, year]
  );

  return useQuery(queryKey, fetchNonPersonnelCostsAnnual, {});
};
