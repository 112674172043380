import { Row as ReactRowType } from '@tanstack/react-table';
import { useCallback } from 'react';
import { DataFromCellEdit } from '../../../components/mainTable/types';
import { AxiosMutationResult, EntityWithId } from '../../../types';
import {
  RecurringIncomeForCreate,
  RecurringIncomeForEdit,
  RecurringIncomeForFetch,
} from '../types';

export const useRecurringIncomeActions = (
  deleteRecurringIncome: AxiosMutationResult<
    EntityWithId,
    RecurringIncomeForFetch
  >,
  changeRecurringIncome: AxiosMutationResult<
    RecurringIncomeForEdit,
    RecurringIncomeForFetch
  >,
  createRecurringIncome: AxiosMutationResult<
    RecurringIncomeForCreate,
    RecurringIncomeForFetch
  >,
  afterAction: () => void = () => undefined
) => {
  const mutateFromCellEdit = useCallback(
    (data: DataFromCellEdit<RecurringIncomeForFetch>) => {
      const isNewValueNotNumeric = isNaN(+data.newValue);
      if (data.editedColumnId === 'amountAmount' && isNewValueNotNumeric)
        return;
      if (
        data.editedColumnId === 'name' &&
        data.newValue === data.originalRow.name
      )
        return;

      const dataForMutate: Record<string, string> = {};
      dataForMutate[data.editedColumnId] = data.newValue;

      if (data.originalRow.id && data.editedColumnId !== 'name') {
        changeRecurringIncome.mutate({
          id: data.originalRow.id,
          ...dataForMutate,
        });
      } else {
        createRecurringIncome.mutate({
          ...data.originalRow,
          ...dataForMutate,
        });
      }

      afterAction();
    },
    [afterAction, changeRecurringIncome, createRecurringIncome]
  );

  const changeIsDeleted = useCallback(
    (
      row: ReactRowType<RecurringIncomeForFetch>,
      data: { isDeleted: boolean }
    ) => {
      if (row.original.id) {
        changeRecurringIncome.mutate({
          id: row.original.id,
          isDeleted: data.isDeleted,
        });
      } else {
        createRecurringIncome.mutate({
          ...row.original,
          ...data,
        });
      }

      afterAction();
    },
    [afterAction, changeRecurringIncome, createRecurringIncome]
  );

  const accept = useCallback(
    (row: ReactRowType<RecurringIncomeForFetch>) => {
      createRecurringIncome.mutate({
        ...row.original,
      });

      afterAction();
    },
    [afterAction, createRecurringIncome]
  );

  const unaccept = useCallback(
    (row: ReactRowType<RecurringIncomeForFetch>) => {
      if (row.original.id) {
        deleteRecurringIncome.mutate({
          id: row.original.id,
        });
      }

      afterAction();
    },
    [afterAction, deleteRecurringIncome]
  );

  return { mutateFromCellEdit, changeIsDeleted, accept, unaccept };
};
