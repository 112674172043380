import { Input } from '@chakra-ui/react';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputWrapper } from '../inputWrapper';
import { DefaultInputProps } from '../types';
import { defaultValidation } from '../validation';

export const TextInput: FC<DefaultInputProps> = ({
  name,
  autoFocus,
  placeholder,
  registerProps = defaultValidation,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <InputWrapper name={name} {...rest}>
      <Input
        autoFocus={autoFocus}
        autoComplete="off"
        placeholder={placeholder}
        type="text"
        {...register(name, registerProps)}
      />
    </InputWrapper>
  );
};
