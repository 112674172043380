import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import IconColumn, { IconColumnType } from './IconColumn';

const Sidebar: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([0]);

  // Reset indexes after each time drawer is closed
  useEffect(() => {
    if (!isOpen) {
      setExpandedRows([0]);
    }
  }, [isOpen]);

  return (
    <>
      <IconColumn
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        type={IconColumnType.simple}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={() => setIsOpen(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody padding={0}>
            <IconColumn
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
              type={IconColumnType.full}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Sidebar;
