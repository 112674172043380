import { QueryClient, useMutation } from 'react-query';
import { personnelCostsApiUrl } from '../../../utils/paths';
import {
  PersonnelCostForCreate,
  PersonnelCostForDelete,
  PersonnelCostForEdit,
  PersonnelCostForFetch,
  PersonnelCostQueryResult,
} from '../types/personnelCost';
import client from '../../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { AxiosMutationResult, EntityWithId } from '../../../types';
import { MonthlyQueryKey } from '../../../types/MonthlyQueryKey';
import { useMemo } from 'react';

export const useSinglePersonnelCostMutations = (
  queryClient: QueryClient,
  personnelCostsQuery: PersonnelCostQueryResult,
  year: number,
  month: number
) => {
  const queryKey: MonthlyQueryKey = useMemo(
    () => ['personnelCosts', year, month],
    [month, year]
  );

  const remove: AxiosMutationResult<EntityWithId, PersonnelCostForFetch> =
    useMutation(
      (dataForDelete: PersonnelCostForDelete) => {
        return client.delete<{ results: PersonnelCostForFetch }>(
          `${personnelCostsApiUrl}${dataForDelete.id}/`
        );
      },
      {
        onSuccess: () => {
          personnelCostsQuery.refetch().catch((e) => console.error(e));
        },
      }
    );

  const change: AxiosMutationResult<
    PersonnelCostForEdit,
    PersonnelCostForFetch
  > = useMutation(
    (dataForEdit: PersonnelCostForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: PersonnelCostForFetch }>(
        `${personnelCostsApiUrl}${id}/`,
        { ...data }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: PersonnelCostForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        oldData.data.results = oldData.data.results.map((personnelCost) =>
          personnelCost.id === data.data.results.id
            ? data.data.results
            : personnelCost
        );

        queryClient.setQueryData(queryKey, oldData);
      },
    }
  );

  const create: AxiosMutationResult<
    PersonnelCostForCreate,
    PersonnelCostForFetch
  > = useMutation(
    (dataForCreate: PersonnelCostForCreate) => {
      return client.post<{ results: PersonnelCostForFetch }>(
        personnelCostsApiUrl,
        { ...dataForCreate }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: PersonnelCostForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        const results = data.data.results;

        oldData.data.results = [
          ...oldData.data.results.filter(
            (personnelCost) =>
              personnelCost.employeeFirstName !== results.employeeFirstName ||
              personnelCost.employeeLastName !== results.employeeLastName ||
              personnelCost.type !== results.type
          ),
          results,
        ];

        queryClient.setQueryData(queryKey, oldData);
      },
    }
  );

  return {
    remove,
    change,
    create,
  };
};
