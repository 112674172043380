/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleError } from './handleError';
import { ApiError } from '../types/apiError';
import { UseFormReturn } from 'react-hook-form';

export enum ErrorValidationCode {
  // the backend codes regards concrete fields: those returned by validationerror
  InvalidName = 'invalid_name',
  InvalidAmount = 'invalid_amount',
  Invalid = 'invalid',
  Unique = 'unique',
  Required = 'required',
  Max = 'max_value',
  Min = 'min_value',
}

const errorValidationMessages = {
  // those are messages to display
  [ErrorValidationCode.InvalidName]: 'Błędna nazwa',
  [ErrorValidationCode.InvalidAmount]: 'Błędna ilość???',
  [ErrorValidationCode.Invalid]: 'Błędna wartość',
  [ErrorValidationCode.Unique]: 'Wartość musi być unikalna',
  [ErrorValidationCode.Required]: 'Wartość jest wymagana',
  [ErrorValidationCode.Max]: 'Wartość maxymalna przekroczona',
  [ErrorValidationCode.Min]: 'Wartość minimalna przekroczona',
  generalError: 'Wystąpił błąd',
};

export const handleValidationError = (
  error: ApiError,
  methods: UseFormReturn<any, any>,
  fields: string[]
) => {
  let errorMessage = '';
  type FieldName = typeof fields[number];
  const errorFields = error?.response?.data?.results?.details;
  if (errorFields) {
    for (let index = 0; index < errorFields.length; index++) {
      const errorField = errorFields[index].field as FieldName;
      const errorCode = errorFields[index].code;

      /*
      TODO: the implementation with switch is not great but, everything else was throwing linting errors
         probably something like joi or interface can be used to meet typescript requirements
     {
    "results": {
        "code": "invalid",
        "details": [
            {
                "field": "name",
                "code": ["unique"],
                "msg":["this is backend message"]
            },
            {
                "field": "billable",
                "code": ["max_value", "min_value"],
                "msg":["this is backend message1", "this is backend message1"]
            }
        ]
    }
}
      TODO: then resolution and the code can be done with: new Map([[code1, value1],[code2, value2]]).get(code1)


       */

      switch (errorCode) {
        case ErrorValidationCode.InvalidName:
          errorMessage = errorValidationMessages[errorCode];
          break;
        case ErrorValidationCode.InvalidAmount:
          errorMessage = errorValidationMessages[errorCode];
          break;
        case ErrorValidationCode.Invalid:
          errorMessage = errorValidationMessages[errorCode];
          break;
        case ErrorValidationCode.Unique:
          errorMessage = errorValidationMessages[errorCode];
          break;
        case ErrorValidationCode.Required:
          errorMessage = errorValidationMessages[errorCode];
          break;
        case ErrorValidationCode.Max:
          errorMessage = errorValidationMessages[errorCode];
          break;
        case ErrorValidationCode.Min:
          errorMessage = errorValidationMessages[errorCode];
          break;
        default:
          errorMessage = '';
          break;
      }

      //TODO: this should be able to handle multiple errors for input at once
      if (fields.includes(errorField)) {
        methods.setError(errorField, {
          type: 'custom',
          message: errorMessage,
        });
      }
    }
  }
  handleError(error);
};
