import {
  IconButton,
  HStack,
  VStack,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import {
  MdOutlineDoneAll,
  MdOutlineVisibility,
  MdVisibility,
  MdSafetyDivider,
} from 'react-icons/md';
import { FC } from 'react';
import { GeneralTableButtonTooltip } from './generalTableButtonTooltip';
import { buttonIconStyling } from '../../../pages/settings/settingsTable/styles';

interface Props {
  onClickAcceptAllButton: () => void;
  onClickShowHideButton: () => void;
  showDeletedItems: boolean;
  onClickSortByAcceptedButton: () => void;
}

export const GeneralTableActionsButtons: FC<Props> = ({
  onClickAcceptAllButton,
  onClickShowHideButton,
  showDeletedItems,
  onClickSortByAcceptedButton,
}) => {
  return (
    <HStack alignSelf="flex-start">
      <GeneralTableButtonTooltip label="Zaakceptuj wszystkie">
        <IconButton
          aria-label="accept-selected"
          sx={{ ...buttonIconStyling }}
          icon={<MdOutlineDoneAll />}
          onClick={() => onClickAcceptAllButton()}
        />
      </GeneralTableButtonTooltip>
      <GeneralTableButtonTooltip label="Pokaż ukryte/usunięte">
        <IconButton
          aria-label="unaccept-selected"
          sx={{ ...buttonIconStyling }}
          icon={showDeletedItems ? <MdVisibility /> : <MdOutlineVisibility />}
          onClick={() => onClickShowHideButton()}
        />
      </GeneralTableButtonTooltip>
      <GeneralTableButtonTooltip
        label={
          <VStack fontSize="0.875rem" alignItems="flex-start">
            <Text>Pokaż najpierw (sortowanie po akceptacji):</Text>
            <UnorderedList listStylePosition="inside">
              <ListItem>niezaakceptowane</ListItem>
              <ListItem>zaakceptowane</ListItem>
              <ListItem>widok normalny</ListItem>
            </UnorderedList>
          </VStack>
        }
      >
        <IconButton
          aria-label="delete-selected"
          icon={<MdSafetyDivider />}
          onClick={() => onClickSortByAcceptedButton()}
          sx={{ ...buttonIconStyling }}
        />
      </GeneralTableButtonTooltip>
    </HStack>
  );
};
