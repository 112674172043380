import { Tooltip } from '@chakra-ui/react';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  label: ReactNode;
}

export const GeneralTableButtonTooltip: FC<Props> = ({ children, label }) => {
  return (
    <Tooltip label={label} placement="top" openDelay={1500}>
      {children}
    </Tooltip>
  );
};
