import {
  PersonnelIncomeForCreate,
  PersonnelIncomeForDelete,
  PersonnelIncomeForEdit,
  PersonnelIncomeInterface,
} from '../../types';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../api/axiosConfig';
import { personnelIncomesApiUrl } from '../../../../utils/paths';
import { useMemo } from 'react';
import { MonthlyQueryKey } from 'types/MonthlyQueryKey';

const useSinglePersonnelIncomeMutations = (year: number, month: number) => {
  const queryClient = useQueryClient();

  const queryKey: MonthlyQueryKey = useMemo(
    () => ['personnelIncomes', year, month],
    [month, year]
  );

  const change = useMutation(
    (data: PersonnelIncomeForEdit) => {
      const { id, ...income } = data;

      return client.patch<{ results: PersonnelIncomeInterface }>(
        `${personnelIncomesApiUrl}${id}/`,
        { ...income }
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const remove = useMutation(
    (data: PersonnelIncomeForDelete) =>
      client.delete<{ results: PersonnelIncomeInterface }>(
        `${personnelIncomesApiUrl}${data.id}/`
      ),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const create = useMutation(
    (dataForCreate: PersonnelIncomeForCreate) => {
      return client.post<{ results: PersonnelIncomeInterface }>(
        personnelIncomesApiUrl,
        { ...dataForCreate }
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    remove,
    change,
    create,
  };
};

export default useSinglePersonnelIncomeMutations;
