import { Heading, Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { loginHeading } from '../../styles/login';

export const Unauthorized: FC = () => (
  <Flex height="100%">
    <Box alignSelf="center" width="100%">
      <Heading sx={loginHeading} size="lg">
        BRAK UPRAWNIEŃ
      </Heading>
    </Box>
  </Flex>
);
