import { useContext, useMemo } from 'react';
import { FinanceCardToDisplay } from '../../../../components/financeCard/types';
import CurrencyContext from 'src/contexts/CurrencyContext/currencyContext';

interface FinanceCardsNonPersonnelCostsData {
  PLN: {
    income: Array<number>;
    incomePotential: Array<number>;
    convertedToPLN: Array<number>;
    convertedToPLNPotential: Array<number>;
  };
  EUR: {
    income: Array<number>;
    incomePotential: Array<number>;
    convertedToPLN: Array<number>;
    convertedToPLNPotential: Array<number>;
  };
  USD: {
    income: Array<number>;
    incomePotential: Array<number>;
    convertedToPLN: Array<number>;
    convertedToPLNPotential: Array<number>;
  };
}

const useFinanceCards = (
  data: FinanceCardsNonPersonnelCostsData,
  filteredData: FinanceCardsNonPersonnelCostsData,
  selectedData: FinanceCardsNonPersonnelCostsData
) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const { PLN: basePLN, EUR: baseEUR, USD: baseUSD } = data;
  const { PLN: selectedPLN, EUR: selectedEUR, USD: selectedUSD } = selectedData;
  const { PLN: filteredPLN, EUR: filteredEUR, USD: filteredUSD } = filteredData;

  const sumAll: FinanceCardToDisplay = useMemo(
    () => ({
      id: 4,
      header: 'Suma całkowita w miesiącu',
      currencyData: undefined,
      income: basePLN.convertedToPLN,
      incomePotential: basePLN.convertedToPLNPotential,
      filteredIncome: filteredPLN.convertedToPLN,
      filteredIncomePotential: filteredPLN.convertedToPLNPotential,
      selectedIncome: selectedPLN.income,
      selectedIncomePotential: selectedPLN.incomePotential,
      alt: true,
    }),
    [
      basePLN.convertedToPLN,
      basePLN.convertedToPLNPotential,
      filteredPLN.convertedToPLN,
      filteredPLN.convertedToPLNPotential,
      selectedPLN.income,
      selectedPLN.incomePotential,
    ]
  );

  const sumPLN: FinanceCardToDisplay = useMemo(
    () => ({
      id: 1,
      header: 'Suma PLN',
      currencyData: undefined,
      income: basePLN.income,
      incomePotential: basePLN.incomePotential,
      filteredIncome: filteredPLN.income,
      filteredIncomePotential: filteredPLN.incomePotential,
      selectedIncome: selectedPLN.income,
      selectedIncomePotential: selectedPLN.incomePotential,
    }),
    [
      basePLN.income,
      basePLN.incomePotential,
      filteredPLN.income,
      filteredPLN.incomePotential,
      selectedPLN.income,
      selectedPLN.incomePotential,
    ]
  );

  const sumEUR: FinanceCardToDisplay = useMemo(
    () => ({
      id: 2,
      header: 'Suma EUR',
      currencyData: currencyDataEUR,
      currencyDataPassed: true,
      income: baseEUR.income,
      incomePotential: baseEUR.incomePotential,
      filteredIncome: filteredEUR.income,
      filteredIncomePotential: filteredEUR.incomePotential,
      selectedIncome: selectedEUR.income,
      selectedIncomePotential: selectedEUR.incomePotential,
    }),
    [
      baseEUR.income,
      baseEUR.incomePotential,
      currencyDataEUR,
      filteredEUR.income,
      filteredEUR.incomePotential,
      selectedEUR.income,
      selectedEUR.incomePotential,
    ]
  );

  const sumUSD: FinanceCardToDisplay = useMemo(
    () => ({
      id: 3,
      header: 'Suma USD',
      currencyData: currencyDataUSD,
      currencyDataPassed: true,
      income: baseUSD.income,
      incomePotential: baseUSD.incomePotential,
      filteredIncome: filteredUSD.income,
      filteredIncomePotential: filteredUSD.incomePotential,
      selectedIncome: selectedUSD.income,
      selectedIncomePotential: selectedUSD.incomePotential,
    }),
    [
      baseUSD.income,
      baseUSD.incomePotential,
      currencyDataUSD,
      filteredUSD.income,
      filteredUSD.incomePotential,
      selectedUSD.income,
      selectedUSD.incomePotential,
    ]
  );

  return [sumPLN, sumEUR, sumUSD, sumAll];
};

export default useFinanceCards;
