import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { filtersStyling } from 'components/mainTable/styles';
import { ErrorLabelProps } from './ErrorLabel.types';

const ErrorLabel: FC<ErrorLabelProps> = ({ error }) => (
  <Text sx={filtersStyling.error}>{error ?? ''}</Text>
);

export default ErrorLabel;
