import { PieChartData } from './types';
import { cloneDeep, isEqual } from 'lodash';

export const areTwoPieChartDataSetsEqual = (
  firstPieChart?: PieChartData,
  secondPieChart?: PieChartData
) => {
  return isEqual(
    sortPieChartData(firstPieChart),
    sortPieChartData(secondPieChart)
  );
};

export const sortPieChartData = (unsortedData?: PieChartData) => {
  let sortedPieCharData: { labels: string[]; income: number[] } = {
    labels: [],
    income: [],
  };

  if (unsortedData) {
    sortedPieCharData = {
      labels: cloneDeep(unsortedData).labels.sort(),
      income: cloneDeep(unsortedData).income.sort(),
    };
  }

  return sortedPieCharData;
};
