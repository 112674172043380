import { useMemo } from 'react';
import { DoughnutPieChartData } from '../../../components/Charts/DoughnutPieChart/types';
import { getSumsForDoughnutPieChart } from '../../../utils/annualViews';
import { AnnualNonPersonnelCost } from '../types';
import {
  getTotalAnnualSumsByCategoryName,
  getTotalAnnualSumsByCostName,
} from '../utils';

export const useDataForDoughnutPieChart = (
  dataWithTotalAmount?: AnnualNonPersonnelCost[],
  filteredDataWithTotalAmounts?: AnnualNonPersonnelCost[]
) => {
  const dataForDoughnutPieChart: DoughnutPieChartData<number> = useMemo(() => {
    const sumsByCostName = getTotalAnnualSumsByCostName(dataWithTotalAmount);
    const sumsByCategoryName =
      getTotalAnnualSumsByCategoryName(dataWithTotalAmount);

    return {
      doughnutData: getSumsForDoughnutPieChart(sumsByCategoryName),
      pieData: getSumsForDoughnutPieChart(sumsByCostName),
    };
  }, [dataWithTotalAmount]);

  const secondDataForDoughnutPieChart:
    | DoughnutPieChartData<number>
    | undefined = useMemo(() => {
    if (!filteredDataWithTotalAmounts?.length) return;

    const sumsByCostName = getTotalAnnualSumsByCostName(
      filteredDataWithTotalAmounts
    );
    const sumsByCategoryName = getTotalAnnualSumsByCategoryName(
      filteredDataWithTotalAmounts
    );

    return {
      doughnutData: getSumsForDoughnutPieChart(sumsByCategoryName),
      pieData: getSumsForDoughnutPieChart(sumsByCostName),
    };
  }, [filteredDataWithTotalAmounts]);

  return { dataForDoughnutPieChart, secondDataForDoughnutPieChart };
};
