import { FC } from 'react';
import { MainContainer } from '../../../components/mainContainer';
import { SettingsHeader } from './settingsHeader';

export const Settings: FC = () => {
  return (
    <MainContainer>
      <SettingsHeader />
    </MainContainer>
  );
};
