import { Box } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { CHART_COLORS, NO_BORDER_WIDTH } from '../consts';
import { doughnutPieChartStyling } from '../styles';
import {
  ChartDataStructure,
  MultiChartConfiguration,
  DoughnutPieChartData,
  DoughnutPieChartType,
} from './types';

const mainTitle = 'Suma roczna';
const secondaryTitle = 'Suma wyfiltrowanych';

const useMultiChart = (
  data: DoughnutPieChartData<number>,
  displaySecondChart: boolean,
  secondaryData?: DoughnutPieChartData<number>
) => {
  const getChartTitle = useCallback(
    (type: DoughnutPieChartType): string =>
      type === 'main' ? mainTitle : secondaryTitle,
    []
  );

  // This returns an object used to configure the doughnut chart and inner pie chart, like the position of the legend,
  // whether should the chart subheader be visible etc.
  const generateChartConfigurations = useCallback(
    (type: DoughnutPieChartType): MultiChartConfiguration => {
      return {
        doughnut: {
          plugins: {
            legend: { display: true, position: 'bottom' },
            title: {
              display: displaySecondChart,
              text: getChartTitle(type),
              position: 'bottom',
            },
          },
          cutout: 95,
          ...NO_BORDER_WIDTH,
        },
        pie: {
          plugins: {
            legend: {
              display: false,
            },
          },
          ...NO_BORDER_WIDTH,
        },
      };
    },
    [displaySecondChart, getChartTitle]
  );

  const generateChartData = useCallback((data: ChartDataStructure<number>) => {
    return {
      labels: data.labels,
      datasets: [
        {
          backgroundColor: CHART_COLORS,
          data: data.data,
        },
      ],
    };
  }, []);

  const createChartToDisplay = useCallback(
    (data: DoughnutPieChartData<number>, type: DoughnutPieChartType) => {
      const { doughnut, pie } = generateChartConfigurations(type);

      return (
        <Box sx={doughnutPieChartStyling.chart}>
          <Doughnut
            data={generateChartData(data.doughnutData)}
            options={doughnut}
          />
          <Box sx={doughnutPieChartStyling.pie(displaySecondChart)}>
            <Pie data={generateChartData(data.pieData)} options={pie} />
          </Box>
        </Box>
      );
    },
    [displaySecondChart, generateChartConfigurations, generateChartData]
  );

  const getSecondChart = useCallback(() => {
    if (displaySecondChart && secondaryData) {
      return createChartToDisplay(secondaryData, 'second');
    }
  }, [createChartToDisplay, displaySecondChart, secondaryData]);

  return {
    generateChartConfigurations,
    generateChartData,
    getSecondChart,
    createChartToDisplay,
  };
};

export default useMultiChart;
