import { QueryFunctionContext } from 'react-query';
import { AnnualPersonnelCost } from './types/annualPersonnelCost';
import client from '../../api/axiosConfig';
import { personnelCostsAnnualApiUrl } from '../../utils/paths';
import { AnnualQueryKey } from '../../types/AnnualQueryKey';

export const fetchpersonnelCostsAnnual = (
  context: QueryFunctionContext<AnnualQueryKey>
) => {
  const [, year = '', onlyAccepted] = context.queryKey;

  return client.get<{ results: AnnualPersonnelCost[] }>(
    `${personnelCostsAnnualApiUrl}?year=${year}&include_predictions=${
      onlyAccepted ? 0 : 1
    }`
  );
};

export const getTotalAnnualSumsByPosition = (
  annualPersonnelCostsData?: AnnualPersonnelCost[]
) => {
  const allpositionNames = [
    ...new Set(
      annualPersonnelCostsData?.map(({ positionName }) => positionName)
    ),
  ].filter((positionName) => positionName !== null) as string[];

  const sums = Object.fromEntries(
    allpositionNames.map((positionName) => [positionName, 0])
  );

  annualPersonnelCostsData?.forEach((annualPersonnelCost) => {
    const positionName = annualPersonnelCost.positionName;

    if (positionName === null) return;

    sums[positionName] =
      sums[positionName] + Number(annualPersonnelCost.totalAnnualAmount);
  });

  return sums;
};
