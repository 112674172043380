import { Position } from 'pages/settings/pageComponents/position/types';
import { useQuery } from 'react-query';
import { currencyOptions } from 'utils/index';
import { positionApiUrl, projectsApiUrl } from 'utils/paths';
import client from 'api/axiosConfig';
import { useMemo } from 'react';
import { PersonnelIncomeRow } from '../types';
import { Project } from 'pages/personnelIncomesByProjectAnnual/types/annualPersonnelIncomeByProject';
import { ColumnInfo } from 'components/mainTable/types';

export const usePersonnelIncomeColumns = () => {
  const positionsQuery = useQuery(
    'positions',
    () => client.get<{ results: Position[] }>(positionApiUrl),
    {}
  );

  const positionOptions = positionsQuery.data?.data.results.map((position) => ({
    value: position.name,
    label: position.name,
  }));

  const projectsQuery = useQuery(
    'projects',
    () => client.get<{ results: Project[] }>(projectsApiUrl),
    {}
  );

  const projectOptions = projectsQuery.data?.data.results.map((project) => ({
    value: project.name,
    label: project.name,
  }));

  const columnsInfo: Array<ColumnInfo<PersonnelIncomeRow>> = useMemo(
    () => [
      {
        id: 'lastNameFirstName',
        header: 'Nazwisko i imię',
        getRow: (row: PersonnelIncomeRow) => {
          if (row.lastName && row.firstName) {
            return `${row.lastName} ${row.firstName}`;
          }
        },
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'positionName',
        header: 'Klasa',
        getRow: (row: PersonnelIncomeRow) =>
          row.position ? row.position.name : '-',
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Klasa',
        columnOptions: positionOptions,
      },
      {
        id: 'isBillable',
        header: 'Billable',
        getRow: (row: PersonnelIncomeRow) => row.billable || '-',
        sortType: 'text' as const,
      },
      {
        id: 'name',
        header: 'Projekt',
        getRow: (row: PersonnelIncomeRow) => {
          if (row.project?.name) {
            return row.project.name;
          }

          if (row.subRows && row.subRows.length > 0) {
            return row.subRows.map((subRow) => subRow.project?.name);
          }
        },
        filterFn: 'arrIncludes' as const,
        filterFieldLabel: 'Projekt',
        columnOptions: projectOptions,
        editable: 'select' as const,
      },
      {
        id: 'hours',
        header: 'Godziny',
        getRow: (row: PersonnelIncomeRow) => row.projectHours || '',
        sortType: 'text' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres godzin roboczych',
        editable: 'numericInputField' as const,
      },
      {
        id: 'amountAmount',
        header: 'Stawka',
        getRow: (row: PersonnelIncomeRow) => row.projectAmountAmount || '',
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres stawek',
        editable: 'numericInputField' as const,
      },
      {
        id: 'amountCurrency',
        header: 'Waluta',
        getRow: (row: PersonnelIncomeRow) => row.projectAmountCurrency,
        columnOptions: currencyOptions,
        editable: 'select' as const,
        filterFieldLabel: 'Waluta',
        filterFn: 'arrIncludes' as const,
      },
    ],
    [positionOptions, projectOptions]
  );

  return { columnsInfo, reFetchProjects: projectsQuery.refetch };
};
