import { CurrencyData } from '../../api/currencyExchangeRates';
import { Nullable } from '../../types/nullable';
import { multiplyByCurrencyBidValue } from '../../utils';

export const prepareDataForCurrency = (
  incomeId: Nullable<number>,
  currencyIncome: Array<number>,
  currencyIncomePotential: Array<number>,
  incomeAmount: Nullable<string>,
  totalIncomeConvertedToPLN: Array<number>,
  totalIncomeConvertedToPLNPotential: Array<number>,
  currencyData?: CurrencyData
) => {
  let incomeMultipliedByCurrencyBid;
  if (currencyData?.bid) {
    incomeMultipliedByCurrencyBid = multiplyByCurrencyBidValue(
      incomeAmount,
      currencyData.bid
    );
  }

  const incomeToPush = Number(incomeAmount);

  if (incomeId) {
    currencyIncome.push(incomeToPush);
    totalIncomeConvertedToPLN.push(
      incomeMultipliedByCurrencyBid
        ? incomeMultipliedByCurrencyBid
        : Number(incomeAmount)
    );
  }

  currencyIncomePotential.push(incomeToPush);
  totalIncomeConvertedToPLNPotential.push(
    incomeMultipliedByCurrencyBid
      ? incomeMultipliedByCurrencyBid
      : Number(incomeAmount)
  );
};
