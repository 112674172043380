import { IconButton, IconButtonProps, Icon } from '@chakra-ui/react';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { FC } from 'react';
import { buttonIconStyling } from '../../../pages/settings/settingsTable/styles';

interface Props extends IconButtonProps {
  currentDate: Date;
  setCurrentDate: (currentDate: Date) => void;
  isForNextYear?: boolean;
}

export const ChangeMonthYearButton: FC<Props> = ({
  isForNextYear,
  currentDate,
  setCurrentDate,
  ...props
}) => (
  <IconButton
    fontSize="1.25rem"
    onClick={() => {
      const monthDelta = isForNextYear ? 1 : -1;
      const newDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + monthDelta)
      );
      setCurrentDate(newDate);
    }}
    sx={{ ...buttonIconStyling }}
    {...props}
  >
    <Icon
      color="black"
      as={isForNextYear ? MdNavigateNext : MdNavigateBefore}
    />
  </IconButton>
);
