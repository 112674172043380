import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import {
  BarChartData,
  BaseBarChartData,
} from '../../../components/Charts/BarChart/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';
import { formatAmountNumeral, sumNumerals } from '../../../utils';
import { getValuesFromNumeral } from '../../../utils/annualViews';
import {
  AnnualSummaryProfit,
  AnnualSummaryCost,
  AnnualSummaryIncome,
  ProfitCategory,
} from '../types';
import { getNormalProfit } from '../utils';
import { useAggregatedRecords } from './useAggregatedRecords';

export const useAnnualSummaryProfits = (
  costs?: AnnualSummaryCost[],
  incomes?: AnnualSummaryIncome[]
) => {
  const innerCosts = useMemo(() => cloneDeep(costs), [costs]);
  const innerIncomes = useMemo(() => cloneDeep(incomes), [incomes]);

  const aggregatedCosts = useAggregatedRecords(innerCosts);
  const aggregatedIncomes = useAggregatedRecords(innerIncomes);

  const profit = useMemo(
    () => getNormalProfit(aggregatedIncomes, aggregatedCosts),
    [aggregatedCosts, aggregatedIncomes]
  );

  const cumulativeProfit = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cloneDeep(profit).map((value, index) =>
        sumNumerals(profit.slice(0, index + 1))
      ),
    [profit]
  );

  const normalSummaryProfit: AnnualSummaryProfit = useMemo(
    () => ({
      category: ProfitCategory.normal,
      amount: profit.map((value) => ({
        amount: value,
        displayAmount: formatAmountNumeral(value),
      })),
      displayTotalAnnualAmount: formatAmountNumeral(sumNumerals(profit)),
    }),
    [profit]
  );

  const cumulativeSummaryProfit: AnnualSummaryProfit = useMemo(
    () => ({
      category: ProfitCategory.cumulative,
      amount: cumulativeProfit.map((value) => ({
        amount: value,
        displayAmount: formatAmountNumeral(value),
      })),
      displayTotalAnnualAmount: formatAmountNumeral(sumNumerals(profit)),
    }),
    [cumulativeProfit, profit]
  );

  const normalProfitBarChartData: BarChartData = useMemo(
    () => ({
      horizontalAxislabels: NUMERIC_MONTH_LABELS,
      datasets: [
        { label: 'Dochód normalny', data: getValuesFromNumeral(profit) },
      ],
    }),
    [profit]
  );

  const cumulativeProfitBarChartData: BaseBarChartData = useMemo(
    () => ({
      datasets: [
        {
          label: 'Dochód narastający',
          data: getValuesFromNumeral(cumulativeProfit),
        },
      ],
    }),
    [cumulativeProfit]
  );

  return {
    data: [normalSummaryProfit, cumulativeSummaryProfit],
    normalProfitBarChartData,
    cumulativeProfitBarChartData,
  };
};
