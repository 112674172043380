import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { currencyOptions } from '../../../utils';
import { categoryApiUrl } from '../../../utils/paths';
import { Category, NonPersonnelCostForFetch } from '../types';
import client from '../../../api/axiosConfig';
import { ColumnInfo } from '../../../components/mainTable/types';

export const useNonPersonnelCostsColumnDef = () => {
  const categoriesQuery = useQuery(
    'category',
    () => client.get<{ results: Category[] }>(categoryApiUrl),
    {}
  );

  const columnInfo: Array<ColumnInfo<NonPersonnelCostForFetch>> = useMemo(
    () => [
      {
        id: 'name',
        header: 'Nazwa',
        sortType: 'text' as const,
        getRow: (row: NonPersonnelCostForFetch): string => `${row.name}`,
        filterFn: 'includesString' as const,
        editable: 'textInputField' as const,
      },
      {
        id: 'categoryName',
        header: 'Kategoria',
        getRow: (row: NonPersonnelCostForFetch): string =>
          row.categoryName || '-',
        sortType: 'text' as const,
        columnOptions: categoriesQuery.data?.data.results.map((category) => {
          return { value: category.name, label: category.name };
        }),
        editable: 'select' as const,
      },
      {
        id: 'amountCurrency',
        header: 'Waluta',
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Waluta',
        columnOptions: currencyOptions,
        getRow: (row: NonPersonnelCostForFetch): string =>
          `${row.amountCurrency}`,
        editable: 'select' as const,
      },
      {
        id: 'amountAmount',
        header: 'Kwota',
        getRow: (row: NonPersonnelCostForFetch) => row.amountAmount,
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
        editable: 'numericInputField' as const,
      },
    ],
    [categoriesQuery.data?.data.results]
  );

  return { columnInfo };
};
