import { createContext, Dispatch, SetStateAction } from 'react';
import { CurrencyData } from '../../api/currencyExchangeRates';

interface IAppContext {
  currencyDataEUR: CurrencyData | undefined;
  currencyDataUSD: CurrencyData | undefined;
  setPastDate: Dispatch<SetStateAction<Date | undefined>>;
}

const CurrencyContext = createContext<IAppContext>({
  currencyDataEUR: undefined,
  currencyDataUSD: undefined,
  setPastDate: () => null,
});

export default CurrencyContext;
