import { useContext, useMemo } from 'react';
import { convertReactRowTypeToPersonnelIncome } from '../helpers';
import {
  FetchedPersonnelIncome,
  PersonnelIncomeRow,
  PersonnelIncomeRowType,
} from '../types';
import { processPersonnelIncomeRows } from './utils';
import {
  createFinanceCardMapping,
  createFinanceCardDataStructre,
} from './cards/helpers';
import CurrencyContext from 'src/contexts/CurrencyContext/currencyContext';

const mapForRows = (
  data: Array<FetchedPersonnelIncome>
): Array<PersonnelIncomeRow> =>
  data.map((row) => {
    const { employee, projects, billable } = row;

    return {
      ...employee,
      hasAddButton: true,
      isDeleted: false,
      isAmountChanged: false,
      project: null,
      projectAmountAmount: null,
      projectAmountCurrency: null,
      projectHours: null,
      employeeId: employee.id,
      billable,
      year: null,
      month: null,
      position: employee.position,
      subRows: projects.map(
        ({
          project,
          amountAmount,
          amountCurrency,
          hours,
          id,
          isDeleted,
          isAmountChanged,
          year,
          month,
        }) => ({
          id,
          firstName: '',
          lastName: '',
          project,
          projectAmountAmount: amountAmount,
          projectAmountCurrency: amountCurrency,
          projectHours: hours,
          hasAddButton: false,
          employeeId: employee.id,
          billable: null,
          isDeleted,
          isAmountChanged,
          position: null,
          year,
          month,
          subRows: [],
        })
      ),
    };
  });

const usePersonnelIncome = (
  queryData: Array<FetchedPersonnelIncome>,
  selectedData: Array<PersonnelIncomeRowType>,
  filteredData: Array<PersonnelIncomeRowType>
) => {
  const dataRows = useMemo(() => mapForRows(queryData), [queryData]);

  const selectedRows = useMemo(
    () => convertReactRowTypeToPersonnelIncome(selectedData),
    [selectedData]
  );
  const filteredRows = useMemo(
    () => convertReactRowTypeToPersonnelIncome(filteredData),
    [filteredData]
  );

  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const baseCurrencyMappings = createFinanceCardMapping(
    createFinanceCardDataStructre(),
    currencyDataEUR,
    currencyDataUSD
  );

  const selectedCurrencyMappings = createFinanceCardMapping(
    createFinanceCardDataStructre(),
    currencyDataEUR,
    currencyDataUSD
  );

  const filteredCurrencyMappings = createFinanceCardMapping(
    createFinanceCardDataStructre(),
    currencyDataEUR,
    currencyDataUSD
  );

  processPersonnelIncomeRows(dataRows, baseCurrencyMappings);
  selectedRows?.length &&
    processPersonnelIncomeRows(selectedRows, selectedCurrencyMappings);
  filteredData?.length &&
    processPersonnelIncomeRows(filteredRows, filteredCurrencyMappings);

  const baseFinanceCards = baseCurrencyMappings;
  const filteredFinanceCards = filteredCurrencyMappings;
  const selectedFinanceCards = selectedCurrencyMappings;

  return {
    dataRows,
    baseFinanceCards,
    filteredFinanceCards,
    selectedFinanceCards,
  };
};

export default usePersonnelIncome;
