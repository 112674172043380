import { useMemo } from 'react';
import { PieChartData } from '../../../components/Charts/PieChart/types';
import { AnnualPersonnelIncomeByProjectRow } from '../types/annualPersonnelIncomeByProject';
import { getTotalAnnualSumsByName } from '../utils';
import { getSumsForPieChart } from '../../../utils/annualViews';

export const useDataForPieChart = (
  dataWithTotalAmount?: AnnualPersonnelIncomeByProjectRow[],
  filteredDataWithTotalAmounts?: AnnualPersonnelIncomeByProjectRow[]
) => {
  const dataForPieChart: PieChartData = useMemo(() => {
    const sums = getTotalAnnualSumsByName(dataWithTotalAmount);

    return getSumsForPieChart(sums);
  }, [dataWithTotalAmount]);

  const secondDataForPieChart: PieChartData | undefined = useMemo(() => {
    if (!filteredDataWithTotalAmounts?.length) return;

    const sums = getTotalAnnualSumsByName(filteredDataWithTotalAmounts);

    return getSumsForPieChart(sums);
  }, [filteredDataWithTotalAmounts]);

  return { dataForPieChart, secondDataForPieChart };
};
