import { MonthlyQueryKey } from '../../../types/MonthlyQueryKey';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchPersonnelCosts } from '../utils';

export const usePersonnelCostsQuery = (year: number, month: number) => {
  const queryKey: MonthlyQueryKey = useMemo(
    () => ['personnelCosts', year, month],
    [month, year]
  );

  return useQuery(queryKey, fetchPersonnelCosts, {});
};
