import { Numeral } from 'numeral';
import { useMemo } from 'react';
import { sumNumeralArrays } from '../../../utils';
import { AnnualSummaryCost, AnnualSummaryIncome } from '../types';

export const useAggregatedRecords = (
  data?: (AnnualSummaryCost | AnnualSummaryIncome)[]
): Numeral[] =>
  useMemo(() => {
    if (!data) return [];

    const sums = data.map(({ amount }) => amount.map(({ amount }) => amount));
    const filteredSums = sums.map(
      (values) => values.filter((value) => value != undefined) as Numeral[]
    );

    return sumNumeralArrays(filteredSums);
  }, [data]);
