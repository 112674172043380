import { FC, useCallback } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { financeCardStyling } from './styles';
import { cloneDeep, isEqual } from 'lodash';
import { FinanceCardToDisplay } from './types';

interface Props {
  card: FinanceCardToDisplay;
}
/**
 * Finance card component, component that accepts two arrays of numbers (one is optional),
 * a boolean defining if the alt version should be shown
 * and currencyData for a currency that should be used inside the component
 */
const FinanceCard: FC<Props> = ({ card: financeCardToDisplay }) => {
  const getNumToShow = (numArray: Array<number>) => {
    let numToShow: number;
    if (numArray.length === 1) {
      numToShow = numArray[0];
    } else {
      numToShow = numArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    }

    return Math.floor(numToShow).toLocaleString();
  };

  const getFormattedCurrencyBid = (bidValue: number) => {
    return Number(bidValue).toFixed(2).replace('.', ',');
  };

  const getSecondSetOfNumberToShow = useCallback(() => {
    const {
      specialMessage,
      selectedIncome,
      selectedIncomePotential,
      filteredIncomePotential,
      filteredIncome,
      incomePotential,
      income,
    } = cloneDeep(financeCardToDisplay);

    if (specialMessage) {
      return '';
    }

    if (selectedIncome?.length || selectedIncomePotential?.length) {
      return (
        <Box>
          <Box sx={financeCardStyling.smallText}>Suma wybranych</Box>
          <Flex>
            <Box sx={financeCardStyling.confirmedContainer}>
              <Box sx={financeCardStyling.numericField}>
                {getNumToShow(selectedIncome)}
              </Box>
              {selectedIncomePotential && (
                <Box sx={financeCardStyling.smallText}>Potwierdzona</Box>
              )}
            </Box>
            {selectedIncomePotential && (
              <Box sx={financeCardStyling.potentialContainer}>
                <Box sx={financeCardStyling.numericField}>
                  {getNumToShow(selectedIncomePotential)}
                </Box>
                <Box sx={financeCardStyling.smallText}>Potencjalna</Box>
              </Box>
            )}
          </Flex>
        </Box>
      );
    }

    const shouldSecondFilteredSetBeVisible = () => {
      return (
        (filteredIncome?.length &&
          !isEqual(filteredIncome?.sort(), income.sort())) ||
        (filteredIncomePotential?.length &&
          !isEqual(
            filteredIncomePotential?.sort(),
            incomePotential ? incomePotential.sort() : []
          ))
      );
    };

    if (shouldSecondFilteredSetBeVisible()) {
      return (
        <Box>
          <Box sx={financeCardStyling.smallText}>Suma wyfiltrowanych</Box>
          <Flex>
            <Box sx={financeCardStyling.confirmedContainer}>
              <Box sx={financeCardStyling.numericField}>
                {getNumToShow(filteredIncome)}
              </Box>
              {filteredIncomePotential && (
                <Box sx={financeCardStyling.smallText}>Potwierdzona</Box>
              )}
            </Box>
            {filteredIncomePotential && (
              <Box sx={financeCardStyling.potentialContainer}>
                <Box sx={financeCardStyling.numericField}>
                  {getNumToShow(filteredIncomePotential)}
                </Box>
                <Box sx={financeCardStyling.smallText}>Potencjalna</Box>
              </Box>
            )}
          </Flex>
        </Box>
      );
    }
  }, [financeCardToDisplay]);

  return (
    <Box
      sx={
        financeCardToDisplay.alt
          ? financeCardStyling.financeCardContainerAlternate
          : financeCardStyling.financeCardContainer
      }
    >
      <Flex sx={financeCardStyling.header}>
        <Text>{financeCardToDisplay.header}</Text>

        <Box as="span" sx={{ color: 'unActive' }}>
          {financeCardToDisplay.currencyDataPassed && (
            <Text sx={financeCardStyling.subHeader}>
              {financeCardToDisplay.currencyData?.bid &&
              financeCardToDisplay.currencyData.code
                ? `KURS ${
                    financeCardToDisplay.currencyData.code
                  } ${getFormattedCurrencyBid(
                    financeCardToDisplay.currencyData.bid
                  )}`
                : 'Brak danych kursu'}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex>
        {!financeCardToDisplay.specialMessage ? (
          <>
            <Box sx={financeCardStyling.confirmedContainerMain}>
              <Box
                data-testid="income-input"
                sx={financeCardStyling.numericField}
              >
                {getNumToShow(financeCardToDisplay.income)}
              </Box>
              {financeCardToDisplay.incomePotential && (
                <Box sx={financeCardStyling.smallText}>Potwierdzona</Box>
              )}
            </Box>
            {financeCardToDisplay.incomePotential && (
              <Box sx={financeCardStyling.potentialContainerMain}>
                <Box
                  data-testid="potential-input"
                  sx={financeCardStyling.numericField}
                >
                  {getNumToShow(financeCardToDisplay.incomePotential)}
                </Box>
                <Box sx={financeCardStyling.smallText}>Potencjalna</Box>
              </Box>
            )}
          </>
        ) : (
          <>{financeCardToDisplay.specialMessage}</>
        )}
      </Flex>
      {getSecondSetOfNumberToShow()}
    </Box>
  );
};

export default FinanceCard;
