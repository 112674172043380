import { useContext, useMemo } from 'react';
import CurrencyContext from '../../contexts/CurrencyContext/currencyContext';
import {
  BarChartData,
  BaseBarChartData,
} from '../../components/Charts/BarChart/types';

import { NUMERIC_MONTH_LABELS } from '../../consts';
import { isBarChartDataIdentical } from '../../components/Charts/BarChart/utils';
import { SumsByCurrency } from '../../types/AnnualViews';
import {
  convertToCurrency,
  getValuesFromNumeral,
} from '../../utils/annualViews';

export const useDataForBarChart = (
  dataByCurrency: SumsByCurrency,
  filteredDataByCurrency: SumsByCurrency,
  anyFilteredData?: boolean
) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const dataForBarChart: BarChartData = useMemo(() => {
    const { pln, usd, eur } = dataByCurrency;

    const datasets = [
      {
        label: 'Suma miesięczna PLN',
        data: pln,
      },
    ];

    if (currencyDataEUR) {
      datasets[1] = {
        label: 'Suma miesięczna EUR',
        data: getValuesFromNumeral(convertToCurrency(eur, currencyDataEUR)),
      };
    }

    if (currencyDataUSD) {
      datasets[2] = {
        label: 'Suma miesięczna USD',
        data: getValuesFromNumeral(convertToCurrency(usd, currencyDataUSD)),
      };
    }

    return {
      horizontalAxislabels: NUMERIC_MONTH_LABELS,
      datasets,
    };
  }, [currencyDataEUR, currencyDataUSD, dataByCurrency]);

  const secondDataForBarChart: BaseBarChartData | undefined = useMemo(() => {
    if (!anyFilteredData) return;

    const { pln, usd, eur } = filteredDataByCurrency;

    const temp: BaseBarChartData = { datasets: [] };

    temp.datasets[0] = {
      label: 'Wyfiltrowana suma miesięczna PLN',
      data: pln,
    };

    if (currencyDataEUR) {
      temp.datasets[1] = {
        label: 'Wyfiltrowana suma miesięczna EUR',
        data: getValuesFromNumeral(convertToCurrency(eur, currencyDataEUR)),
      };
    }

    if (currencyDataUSD) {
      temp.datasets[2] = {
        label: 'Wyfiltrowana suma miesięczna USD',
        data: getValuesFromNumeral(convertToCurrency(usd, currencyDataUSD)),
      };
    }

    if (isBarChartDataIdentical(dataForBarChart, temp)) return;

    return temp;
  }, [
    anyFilteredData,
    filteredDataByCurrency,
    currencyDataEUR,
    currencyDataUSD,
    dataForBarChart,
  ]);

  return { dataForBarChart, secondDataForBarChart };
};
