import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { MainContainer } from '../../components/mainContainer';
import AnnualSummaryCosts from './costs';
import { useIncomeQueries } from './hooks/useIncomeQueries';
import { useCostQueries } from './hooks/useCostQueries';
import AnnualSummaryIncomes from './incomes';
import { basicContainer } from '../../styles/global';
import { YearControl } from '../../components/dateControl/yearControl';
import { useAnnualSummaryIncomes } from './hooks/useAnnualSummaryIncomes';
import { useAnnualSummaryCost } from './hooks/useAnnualSummaryCosts';
import { areQueriesLoading } from './utils';
import { useAnnualSummaryProfits } from './hooks/useAnnualSummaryProfits';
import AnnualSummaryProfits from './profit';
import { tabPanelStyling } from './styles';
import PageHeading from 'components/common/pageHeading';
import EmptyState from 'components/EmptyState/EmptyState';

export const SummaryAnnual: FC = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const incomes = useIncomeQueries(selectedYear);
  const costs = useCostQueries(selectedYear);

  const summaryIncomes = useAnnualSummaryIncomes(
    incomes.personnelIncomes.data?.data.results,
    incomes.potientialPersonnelIncomes.data?.data.results,
    incomes.recurringIncomes.data?.data.results,
    incomes.potientialRecurringIncomes.data?.data.results
  );

  const summaryCosts = useAnnualSummaryCost(
    costs.personnelCosts.data?.data.results,
    costs.potientialPersonnelCosts.data?.data.results,
    costs.nonPersonnelCosts.data?.data.results,
    costs.potientialNonPersonnelCosts.data?.data.results
  );

  const summaryProfit = useAnnualSummaryProfits(
    summaryCosts.data,
    summaryIncomes.data
  );

  return (
    <MainContainer>
      <VStack sx={basicContainer}>
        <PageHeading
          text="Podsumowanie"
          dateControl={
            <YearControl
              currentYear={selectedYear}
              setCurrentYear={setSelectedYear}
            />
          }
        />
        <Tabs width="100%">
          <TabList>
            <Tab>Koszty</Tab>
            <Tab>Przychody</Tab>
            <Tab>Dochody</Tab>
          </TabList>

          <TabPanels>
            <TabPanel sx={tabPanelStyling}>
              {!summaryCosts.data?.length ? (
                <EmptyState />
              ) : (
                <AnnualSummaryCosts
                  data={summaryCosts.data}
                  financeCardsToDisplay={summaryCosts.financeCardsToDisplay}
                  dataForBarChart={summaryCosts.dataForBarChart}
                  isLoading={areQueriesLoading(Object.values(costs))}
                />
              )}
            </TabPanel>
            <TabPanel sx={tabPanelStyling}>
              {!summaryIncomes.data?.length ? (
                <EmptyState />
              ) : (
                <AnnualSummaryIncomes
                  data={summaryIncomes.data}
                  financeCardsToDisplay={summaryIncomes.financeCardsToDisplay}
                  dataForBarChart={summaryIncomes.dataForBarChart}
                  isLoading={areQueriesLoading(Object.values(incomes))}
                />
              )}
            </TabPanel>
            <TabPanel sx={tabPanelStyling}>
              {!summaryProfit.data.length ? (
                <EmptyState />
              ) : (
                <AnnualSummaryProfits
                  data={summaryProfit.data}
                  dataForBarChart={summaryProfit.normalProfitBarChartData}
                  secondDataForBarChart={
                    summaryProfit.cumulativeProfitBarChartData
                  }
                  isLoading={
                    areQueriesLoading(Object.values(costs)) ||
                    areQueriesLoading(Object.values(incomes))
                  }
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </MainContainer>
  );
};
