import { displayErrorToast } from './toast';
import { ApiError } from '../types/apiError';

enum ErrorCode {
  ObjectDoesNotExist = 'object_not_exist',
  MultipleObjectsReturned = 'multiple_objects',
  CannotRemovedUsedObject = 'cannot_remove_used_object',
}

const errorMessages = {
  [ErrorCode.ObjectDoesNotExist]: 'Nie znaleziono obiektu',
  [ErrorCode.MultipleObjectsReturned]: 'Obiekt o tej nazwie juz istnieje',
  [ErrorCode.CannotRemovedUsedObject]: `Nie mozna usunąc - wybrany element jest w użyciu`,
  generalError: 'Wystąpił błąd',
};

export const handleError = (error: ApiError) => {
  // TODO: implementation as in handleValidationError will fits here, except that here new Map([[code1, value1],[code2, value2]])
  //  will contain the coarse grained codes (default_code from class ingerited from APIEXception)
  const errorCode = error?.response?.data?.results?.code;

  switch (errorCode) {
    case ErrorCode.ObjectDoesNotExist:
    case ErrorCode.MultipleObjectsReturned:
      displayErrorToast({ title: errorMessages[errorCode] });
      break;
    case ErrorCode.CannotRemovedUsedObject:
      displayErrorToast({ title: errorMessages[errorCode] });
      break;
    default:
      displayErrorToast({ title: errorMessages.generalError });
      break;
  }
};
