import { HStack, Tag, TagCloseButton, Text } from '@chakra-ui/react';
import { ActiveFilterTagsProps } from './ActiveFilterTags.types';
import { ColumnFilter } from '@tanstack/react-table';
import { FC } from 'react';

const ActiveFilterTags: FC<ActiveFilterTagsProps> = ({
  columnFilters,
  setColumnFilters,
}) => {
  const displayFilters = (columnFilter: ColumnFilter) => {
    const columnFilterValue = columnFilter.value;
    if (Array.isArray(columnFilterValue)) {
      const amountMinValue = columnFilterValue[0] as string;
      const amountMaxValue = columnFilterValue[1] as string;

      if (!amountMinValue || !amountMaxValue) {
        return amountMinValue ?? amountMaxValue;
      } else if (amountMinValue && amountMaxValue) {
        return `${amountMinValue} - ${amountMaxValue}`;
      }
    } else if (typeof columnFilterValue === 'string') {
      return `${columnFilterValue}`;
    }
  };

  return (
    <HStack alignSelf="flex-start">
      {columnFilters.length > 0 ? (
        <Text fontSize="1rem" fontWeight="bold" whiteSpace="nowrap">
          Zastosowane filtry:
        </Text>
      ) : undefined}
      {columnFilters.map((columnFilter, index) => (
        <Tag
          key={index}
          size="sm"
          variant="outline"
          colorScheme="blue"
          width="fit-content"
        >
          <Text whiteSpace="nowrap">{displayFilters(columnFilter)}</Text>
          <TagCloseButton
            onClick={() =>
              setColumnFilters(
                columnFilters.filter(
                  (oldColumnFilter) => oldColumnFilter.id !== columnFilter.id
                )
              )
            }
          />
        </Tag>
      ))}
    </HStack>
  );
};

export default ActiveFilterTags;
