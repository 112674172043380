import { createContext } from 'react';
import { User } from '../../types/user';

interface IAppContext {
  loggedUser?: User;
  isLoading: boolean;
}

const UserContext = createContext<IAppContext>({
  loggedUser: undefined,
  isLoading: false,
});

export default UserContext;
