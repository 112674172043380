import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import numeral from 'numeral';
import { Numeral } from 'numeral';
import { Nullable } from '../types/nullable';

export enum CurrencyCodes {
  UnitedStatesDollar = 'USD',
  Euro = 'EUR',
  PolishZloty = 'PLN',
}

export const currencyOptions = [
  { value: CurrencyCodes.PolishZloty, label: CurrencyCodes.PolishZloty },
  { value: CurrencyCodes.Euro, label: CurrencyCodes.Euro },
  {
    value: CurrencyCodes.UnitedStatesDollar,
    label: CurrencyCodes.UnitedStatesDollar,
  },
];

export const multiplyByCurrencyBidValue = (
  valueToMultiply: string | null,
  currencyBidValue: number
) => {
  return Number(valueToMultiply) * currencyBidValue;
};

export const sumArrays = (inputArays: number[][]) =>
  // Example - for input [[1, 2, 3], [1, 2, 3], [1, 2, 3]] output would be [3, 6, 9],
  // so for example first element from output array is 3 and it is a sum
  // of first elements from each input array (so 1, 1 and 1)
  inputArays.reduce((a, b) =>
    a.map((value, index) => (b[index] ? value + b[index] : value + 0))
  );

export const sumNumeralArrays = (inputArays: Numeral[][]) =>
  // Example - for input [[1, 2, 3], [1, 2, 3], [1, 2, 3]] output would be [3, 6, 9],
  // so for example first element from output array is 3 and it is a sum
  // of first elements from each input array (so 1, 1 and 1)
  inputArays.length
    ? inputArays.reduce((a, b) =>
        a.map((value, index) =>
          b[index] ? value.add(b[index].value()) : value
        )
      )
    : [];

export const sumNumerals = (inputNumerals: (Numeral | undefined | null)[]) =>
  (inputNumerals.filter((value) => value != undefined) as Numeral[]).reduce(
    (a, b) => a.add(b.value()),
    numeral(0)
  );

export const convertReactRowTypeToOriginal = <T,>(data: ReactRowType<T>[]) =>
  data.map((dataPortion) => dataPortion.original);

export const formatAmountNumeral = (input?: Nullable<Numeral>) =>
  input?.format('0.00');
