import { useMemo } from 'react';
import { ColumnInfo } from '../../../components/mainTable/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';
import { AnnualSummaryProfit } from '../types';

export const useAnnualSummaryProfitColumnDef = () => {
  const columnInfo: ColumnInfo<AnnualSummaryProfit>[] = useMemo(
    () => [
      {
        id: 'category',
        header: 'Kategoria',
        getRow: (row: AnnualSummaryProfit) => row.category,
        sortType: 'text' as const,
      },
      {
        id: 'totalAnnualAmount',
        header: 'Suma roczna',
        getRow: (row: AnnualSummaryProfit) => row.displayTotalAnnualAmount,
        sortType: 'alphanumeric' as const,
      },
      ...NUMERIC_MONTH_LABELS.map((monthLabel, index) => ({
        id: `amount${index}`,
        header: monthLabel,
        getRow: (row: AnnualSummaryProfit) => row.amount[index]?.displayAmount,
        sortType: 'alphanumeric' as const,
      })),
    ],
    []
  );

  return { columnInfo };
};
