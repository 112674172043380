const styles = {
  button: {
    width: '100%',
    gap: '1rem',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  nestedItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginLeft: '3rem',
  },
  active: (isActive: boolean) => ({
    color: isActive ? 'active' : 'unActive',

    _hover: {
      cursor: 'pointer',
      color: 'active',
    },
  }),
};

export default styles;
