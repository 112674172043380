import { useMemo } from 'react';
import { FinanceCardToDisplay } from '../../../components/financeCard/types';
import { sumArrays } from '../../../utils';
import { combineFinanceCardToDisplay, matchArrayLengths } from '../utils';

export const useIncomesFinanceCards = (
  personnel: FinanceCardToDisplay[],
  personnelPotential: FinanceCardToDisplay[],
  reccuring: FinanceCardToDisplay[],
  reccuringPotential: FinanceCardToDisplay[]
): FinanceCardToDisplay[] =>
  useMemo(() => {
    const cards = [
      combineFinanceCardToDisplay(
        personnel[3],
        reccuring[3],
        'Suma przychodów w miesiącach'
      ),
      reccuring[3],
      personnel[3],
    ]
      .filter((value) => value !== undefined)
      .map((value, index) => ({ ...value, id: index + 1 }));

    if (reccuringPotential[3] && personnelPotential[3]) {
      cards[0].incomePotential = sumArrays(
        matchArrayLengths(
          reccuringPotential[3].income,
          personnelPotential[3].income
        )
      );
    }

    if (cards[1]) {
      cards[1].header = 'Suma przychodów stałych w roku';
      cards[1].incomePotential = reccuringPotential[3].income || [];
    }

    if (cards[2]) {
      cards[2].header = 'Suma przychodów osobowych w roku';
      cards[2].incomePotential = personnelPotential[3].income || [];
    }

    return cards;
  }, [personnel, personnelPotential, reccuring, reccuringPotential]);
