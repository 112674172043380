import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../../../../components/form/inputs/textInput';
import { requiredValidation } from '../../../../../components/form/validation';
import { ApiError } from '../../../../../types/apiError';
import { handleError } from '../../../../../utils/handleError';
import { contractTypeApiUrl } from '../../../../../utils/paths';
import { ContractTypeForCreate, ContractType } from '../types';
import Drawer from '../../../../../components/common/drawer';
import DrawerOpeningButton from '../../../../../components/common/drawerButton';

export const AddContractType: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryClient = useQueryClient();
  const addContractType = useMutation(
    (data: ContractTypeForCreate) => {
      return client.post<{ results: ContractType }>(contractTypeApiUrl, {
        ...data,
      });
    },
    {
      onSuccess: (data) => {
        const oldData: AxiosResponse<{ results: ContractType[] }> | undefined =
          queryClient.getQueryData('contractTypes');

        closeDrawer();

        if (!oldData) return;

        oldData.data.results = [...oldData.data.results, data.data.results];

        queryClient.setQueryData('contractTypes', oldData);
      },
      onError: (error: ApiError) => {
        handleError(error);
      },
    }
  );

  const methods = useForm<ContractTypeForCreate>({});

  const handleSubmit = async (data: ContractTypeForCreate) =>
    await addContractType.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj nowy typ umowy" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj nowy typ umowy"
          isOpen={isOpen}
          onClose={closeDrawer}
          onSubmit={handleSubmit}
        >
          <TextInput
            name={'name'}
            label={'Rodzaj umowy'}
            autoFocus
            registerProps={requiredValidation}
          />
        </Drawer>
      </FormProvider>
    </>
  );
};
