import { useCallback, useEffect } from 'react';
import { FinanceCardsNonPersonnelCostsData } from '../nonPersonnelCosts/types';
import { useFinanceCards } from '../../components/financeCard/useFinanceCards';
import { PersonnelCostForFetch } from './types/personnelCost';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { convertReactRowTypeToOriginal } from '../../utils';

const usePersonnelCosts = (
  personnelCosts?: PersonnelCostForFetch[],
  personnelCostsFilteredData?: ReactRowType<PersonnelCostForFetch>[],
  personnelCostsSelectedData?: ReactRowType<PersonnelCostForFetch>[]
) => {
  const {
    setFinanceCardsData,
    setFinanceCardsSelectedData,
    setFinanceCardsFilteredData,
    sumFinanceCardToDisplay,
  } = useFinanceCards();

  const prepareDataForFinanceCards = useCallback(
    (data: PersonnelCostForFetch[]) => {
      const financeCardsData: FinanceCardsNonPersonnelCostsData = {
        PLN: {
          income: [],
          incomePotential: [],
          totalIncomeConvertedToPLN: [],
          totalIncomeConvertedToPLNPotential: [],
        },
        EUR: { income: [], incomePotential: [] },
        USD: { income: [], incomePotential: [] },
      };

      data.forEach((income) => {
        const { isDeleted, id, amountAmount } = income;
        const {
          totalIncomeConvertedToPLN,
          totalIncomeConvertedToPLNPotential,
        } = financeCardsData.PLN;
        if (!isDeleted) {
          if (id) {
            totalIncomeConvertedToPLN.push(Number(amountAmount));
          } else {
            totalIncomeConvertedToPLNPotential.push(Number(amountAmount));
          }
        }
      });

      return financeCardsData;
    },
    []
  );

  useEffect(() => {
    if (personnelCosts) {
      setFinanceCardsData(prepareDataForFinanceCards(personnelCosts));
    }
  }, [personnelCosts, prepareDataForFinanceCards, setFinanceCardsData]);

  useEffect(() => {
    if (personnelCostsFilteredData?.length) {
      const financeCardsFilteredData = prepareDataForFinanceCards(
        convertReactRowTypeToOriginal(personnelCostsFilteredData)
      );
      if (financeCardsFilteredData) {
        setFinanceCardsFilteredData(financeCardsFilteredData);
      }
    } else {
      setFinanceCardsFilteredData(undefined);
    }
  }, [
    personnelCostsFilteredData,
    prepareDataForFinanceCards,
    setFinanceCardsFilteredData,
  ]);

  useEffect(() => {
    if (personnelCostsSelectedData?.length) {
      const financeCardsSelectedData = prepareDataForFinanceCards(
        convertReactRowTypeToOriginal(personnelCostsSelectedData)
      );
      if (financeCardsSelectedData) {
        setFinanceCardsSelectedData(financeCardsSelectedData);
      }
    } else {
      setFinanceCardsSelectedData(undefined);
    }
  }, [
    personnelCostsSelectedData,
    prepareDataForFinanceCards,
    setFinanceCardsSelectedData,
  ]);

  return { financeCardsToDisplay: sumFinanceCardToDisplay };
};

export default usePersonnelCosts;
