import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { PersonnelIncomeRow } from './types';

export const convertReactRowTypeToPersonnelIncome = (
  data: ReactRowType<PersonnelIncomeRow>[]
) => {
  if (data.length) {
    return data.map((dataPortion) => {
      const { original, originalSubRows } = dataPortion;

      return {
        ...original,
        subRows: originalSubRows ? [] : [original],
      };
    });
  }
};
