import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../../../../../api/axiosConfig';
import { ColumnInfoSettings, SettingsTable } from '../../../settingsTable';
import { AxiosResponse } from 'axios';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { CenteredSpinner } from '../../../../../components/centeredSpinner';
import { AddCategory } from './addCategory';
import { Box } from '@chakra-ui/react';
import { categoryApiUrl } from '../../../../../utils/paths';
import { ApiError } from '../../../../../types/apiError';
import { handleError } from '../../../../../utils/handleError';
import { DataFromCellEdit } from '../../../../../components/mainTable/types';
import { Category, CategoryForEdit } from '../types';
import { basicContainer } from '../../../../../styles/global';
import EmptyState from 'components/EmptyState/EmptyState';

export const Categories: FC = () => {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(
    'categories',
    () => client.get<{ results: Category[] }>(categoryApiUrl),
    {}
  );

  const changeCategory = useMutation(
    (dataForEdit: CategoryForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: Category }>(`${categoryApiUrl}${id}/`, {
        ...data,
      });
    },
    {
      onSuccess: (data) => {
        const oldData: AxiosResponse<{ results: Category[] }> | undefined =
          queryClient.getQueryData('categories');
        if (!oldData) return;
        oldData.data.results = oldData.data.results.map((category) =>
          category.id === data.data.results.id ? data.data.results : category
        );
        queryClient.setQueryData('categories', oldData);
      },
      onError: (error: ApiError) => {
        handleError(error);
      },
    }
  );

  const mutateCategory = (
    row: ReactRowType<Category>,
    data: Partial<Category>
  ) =>
    changeCategory.mutate({
      id: row.original.id,
      isDeleted: data.isDeleted,
    });

  const mutateFromCellEditCategory = (data: DataFromCellEdit<Category>) => {
    const dataForMutate: Record<string, string> = {};

    dataForMutate[data.editedColumnId] = data.newValue;
    changeCategory.mutate({ id: data.originalRow.id, ...dataForMutate });
  };

  const filterDeletedRows = (
    table: ReactRowType<Category>[]
  ): ReactRowType<Category>[] => {
    return table.filter((row) => !row.original.isDeleted);
  };

  const columnInfo: ColumnInfoSettings<Category>[] = [
    {
      id: 'name',
      header: 'Nazwa kategorii',
      sortType: 'text',
      editable: 'textInputField',
      getRow: (row: Category) => `${row.name}`,
    },
  ];

  return isLoading || !data ? (
    <CenteredSpinner />
  ) : (
    <Box sx={basicContainer}>
      <AddCategory />
      {!data.data.results.length ? (
        <EmptyState />
      ) : (
        <SettingsTable<Category>
          data={data.data.results}
          columnInfo={columnInfo}
          updateFnForRowEdit={mutateCategory}
          updateFnForCellEdit={mutateFromCellEditCategory}
          filterDeletedRows={filterDeletedRows}
        />
      )}
    </Box>
  );
};
