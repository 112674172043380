const styles = {
  root: {
    py: '0.4rem',
    backgroundColor: 'white',
  },
  accordion: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'space-between',
  },
  accordionSection: {
    alignItems: 'strech',
  },
};

export default styles;
