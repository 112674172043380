import { useEffect, useState } from 'react';
import {
  HStack,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row as ReactRowType,
  SortingFnOption,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { InputFieldEditableTableCell } from './inputFieldEditableTableCell';
import { useKey } from 'react-use';
import {
  DataFromCellEdit,
  EditableColumnType,
} from '../../../components/mainTable/types';
import { tableBaseStyling } from '../../../components/mainTable/styles';

export interface ColumnInfoSettings<T> {
  id: string;
  header: string;
  sortType: SortingFnOption<T>;
  getRow: (row: T) => void;
  editable?: EditableColumnType;
}

interface Props<T> {
  data: T[];
  columnInfo: ColumnInfoSettings<T>[];
  updateFnForRowEdit?: (
    row: ReactRowType<T>,
    data: Partial<T> | { isDeleted?: boolean }
  ) => void;
  updateFnForCellEdit: (dataFromCellEdit: DataFromCellEdit<T>) => void;
  filterDeletedRows?: (row: ReactRowType<T>[]) => ReactRowType<T>[];
  tabFocusableColumnId?: string;
  isTabControlEnabled?: boolean;
  noDataInfo?: string;
}

export const SettingsTable = <T,>({
  data,
  columnInfo,
  updateFnForCellEdit,
  filterDeletedRows,
  isTabControlEnabled,
  tabFocusableColumnId,
  noDataInfo,
}: Props<T>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const columnHelper = createColumnHelper<T>();
  const [focusedRow, setFocusedRow] = useState(-1);

  const getCellRenderer = (
    editable: EditableColumnType
  ): Partial<ColumnDef<T>> | undefined => {
    if (editable === 'numericInputField' || editable === 'textInputField') {
      return {
        cell: ({ getValue, row, column }) => {
          return (
            <InputFieldEditableTableCell
              getValue={getValue}
              originalRow={row.original}
              tabFocusableColumnId={tabFocusableColumnId}
              columnId={column.id}
              onCellBlur={updateFnForCellEdit}
              isForNumericValues={editable === 'numericInputField'}
            />
          );
        },
      };
    }
  };

  const givenColumns = columnInfo.map(
    ({ id, header, getRow, sortType, editable }) =>
      columnHelper.accessor(getRow, {
        id: id,
        header: () => header,
        sortingFn: sortType,
        ...getCellRenderer(editable),
      })
  );

  const columns = [...givenColumns];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const originalTableRows = table.getRowModel().rows;
  const filterTableRows = filterDeletedRows
    ? filterDeletedRows(originalTableRows)
    : originalTableRows;

  // tab usage will be improved after merge INP-326
  useKey('Tab', (e: KeyboardEvent) => {
    if (isTabControlEnabled) {
      e.preventDefault();
      setFocusedRow((focusedRow) =>
        focusedRow < originalTableRows.length - 1 ? ++focusedRow : -1
      );
    }
  });

  useEffect(() => {
    if (isTabControlEnabled) {
      const tabFocusableInputs = document.querySelectorAll<HTMLInputElement>(
        '.tab-focusable-input'
      );
      tabFocusableInputs[focusedRow]?.focus();
    }
  }, [focusedRow, isTabControlEnabled]);

  return (
    <VStack>
      <TableContainer sx={{ ...tableBaseStyling.container }}>
        <Table sx={{ tableLayout: 'fixed' }} variant="unstyled">
          <Thead sx={{ ...tableBaseStyling.head }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <HStack
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() ? (
                              <Icon
                                as={
                                  header.column.getIsSorted() === 'desc'
                                    ? MdArrowDropDown
                                    : MdArrowDropUp
                                }
                              />
                            ) : undefined}
                          </HStack>
                        </>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {filterTableRows.length > 0 &&
              filterTableRows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            {filterTableRows.length === 0 && (
              <Tr>
                <Td>{noDataInfo}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
