import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { useMemo } from 'react';
import {
  AnnualPersonnelIncomeByEmployeeRow,
  AnnualPersonnelIncomeByEmployee,
} from '../types/annualPersonnelIncomesEmployee';
import { useAmountsInPLN } from './useAmountsInPLN';
import { getDisplaySums } from '../utils';
import { useTotalAmounts } from './useTotalAmounts';
import { useSumsForEmployees } from './useSumsForEmployees';
import { useDataForBarChart } from '../../../hooks/annualViews/useDataForBarChart';
import { useFinanceCardsToDisplay } from '../../../hooks/annualViews/useFinanceCardsToDisplay';
import { useSumsByCurrency } from '../../../hooks/annualViews/useSumsByCurrency';
import { useFlatProjects } from './useFlatProjects';
import { useDataForPieChart } from './useDataForPieChart';

export const useAnnualPersonnelIncomesByEmployee = (
  data?: AnnualPersonnelIncomeByEmployee[],
  filteredData?: ReactRowType<AnnualPersonnelIncomeByEmployeeRow>[]
) => {
  const filteredDataOriginal = useMemo(
    () =>
      filteredData?.map((annualPersonnelCost) => annualPersonnelCost.original),
    [filteredData]
  );

  const dataWithPLN = useAmountsInPLN(data);

  const dataRow = useSumsForEmployees(dataWithPLN);

  const dataProjects = useFlatProjects(dataRow);
  const filteredDataProjects = useFlatProjects(filteredDataOriginal);

  const dataByCurrency = useSumsByCurrency(dataProjects);
  const filteredDataByCurrency = useSumsByCurrency(filteredDataProjects);

  const totalAmounts = useTotalAmounts(dataRow);
  const totalAmountsFiltered = useTotalAmounts(filteredDataOriginal);

  const financeCardsToDisplay = useFinanceCardsToDisplay(
    dataByCurrency,
    filteredDataByCurrency,
    totalAmounts,
    totalAmountsFiltered,
    !!filteredDataOriginal?.length
  );

  const { dataForBarChart, secondDataForBarChart } = useDataForBarChart(
    dataByCurrency,
    filteredDataByCurrency,
    !!filteredDataOriginal?.length
  );

  const { dataForPieChart, secondDataForPieChart } = useDataForPieChart(
    dataRow,
    filteredDataOriginal
  );

  const displayData = useMemo(() => getDisplaySums(dataRow), [dataRow]);

  return {
    displayData,
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForPieChart,
    secondDataForPieChart,
  };
};
