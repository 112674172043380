import {
  PersonnelIncomeRow,
  PersonnelIncomeRowType,
} from 'pages/personnelIncomes/types';
import { useMemo } from 'react';
import { useAmountsInPLN } from './useAmountsInPLN';
import { useRowsSplitByIsPrediction } from './useRowsSplitByIsPrediction';
import { useSumsByPositions } from './useSumsByPositions';
import { useSumsByProjects } from './useSumsByProjects';
import {
  flattenPersonnelIncomeRows,
  flattenPersonnelIncomeTableRows,
} from './utils';

export const useDataForPieChart = (
  allData?: Array<PersonnelIncomeRow>,
  selectedData?: Array<PersonnelIncomeRowType>,
  returnSelectedDataByPositions?: boolean,
  returnSelectedDataByProjects?: boolean
) => {
  const flatAllData = useMemo(
    () => flattenPersonnelIncomeRows(allData),
    [allData]
  );
  const flatSelectedData = useMemo(
    () => flattenPersonnelIncomeTableRows(selectedData),
    [selectedData]
  );

  const allDataWithPLN = useAmountsInPLN(flatAllData);
  const selectedDataWithPLN = useAmountsInPLN(flatSelectedData);

  const splitAllData = useRowsSplitByIsPrediction(allDataWithPLN);
  const splitSelectedData = useRowsSplitByIsPrediction(selectedDataWithPLN);

  const confirmedAllSumsByPositions = useSumsByPositions(
    splitAllData?.confirmed
  );
  const potentialAllSumsByPositions = useSumsByPositions(
    splitAllData?.potential
  );
  const confirmedSelectedSumsByPositions = useSumsByPositions(
    splitSelectedData?.confirmed
  );
  const potentialSelectedSumsByPositions = useSumsByPositions(
    splitSelectedData?.potential
  );

  const confirmedAllSumsByProjects = useSumsByProjects(splitAllData?.confirmed);
  const potentialAllSumsByProjects = useSumsByProjects(splitAllData?.potential);
  const confirmedSelectedSumsByProjects = useSumsByProjects(
    splitSelectedData?.confirmed
  );
  const potentialSelectedSumsByProjects = useSumsByProjects(
    splitSelectedData?.potential
  );

  return {
    confirmedByProjects: returnSelectedDataByProjects
      ? confirmedSelectedSumsByProjects
      : confirmedAllSumsByProjects,
    potentialByProjects: returnSelectedDataByProjects
      ? potentialSelectedSumsByProjects
      : potentialAllSumsByProjects,
    confirmedByPositions: returnSelectedDataByPositions
      ? confirmedSelectedSumsByPositions
      : confirmedAllSumsByPositions,
    potentialByPositions: returnSelectedDataByPositions
      ? potentialSelectedSumsByPositions
      : potentialAllSumsByPositions,
  };
};
