import { AxiosResponse } from 'axios';
import { Employee } from 'pages/personnelCosts/types/addEmployee';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';

export const useEmployeesFromQuery = () => {
  const queryClient = useQueryClient();

  const employees =
    queryClient.getQueryData<AxiosResponse<{ results: Array<Employee> }>>(
      'employees'
    );

  return useMemo(
    () =>
      employees?.data.results.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: String(id),
      })) || [],
    [employees]
  );
};
