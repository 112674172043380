import { useMutation } from 'react-query';
import { UpdateFnForMainButtonInput } from '../../../components/mainTable/types';
import { personnelCostsBulkApiUrl } from '../../../utils/paths';
import {
  PersonnelCostForFetch,
  PersonnelCostBulk,
  PersonnelCostQueryResult,
} from '../types/personnelCost';
import client from '../../../api/axiosConfig';

export const useMultiplePersonnelCostsMutations = (
  query: PersonnelCostQueryResult
) => {
  const editSelectedMutation = useMutation(
    (bulkUpdate: UpdateFnForMainButtonInput<PersonnelCostForFetch>) => {
      const mappedPersonnelCostBulk: PersonnelCostBulk = {
        personnelCostDtos: bulkUpdate.dataForUpdate,
        updateTo: bulkUpdate.updateTo,
        actionToPerfom: bulkUpdate.actionToPerfom,
      };

      return client.post<{
        results: PersonnelCostBulk;
      }>(personnelCostsBulkApiUrl, mappedPersonnelCostBulk);
    },
    {
      onSuccess: () => {
        query.refetch().catch((e) => console.error(e));
      },
    }
  );

  const acceptAllMutation = useMutation(
    () => {
      const mappedPersonnelCostBulk: PersonnelCostBulk = {
        personnelCostDtos: query.data ? query.data.data.results : [],
        updateTo: true,
        actionToPerfom: 'make_accepted_or_unaccepted',
      };

      return client.post<{
        results: PersonnelCostBulk;
      }>(personnelCostsBulkApiUrl, mappedPersonnelCostBulk);
    },
    {
      onSuccess: () => {
        query.refetch().catch((e) => console.error(e));
      },
    }
  );

  return { editSelectedMutation, acceptAllMutation };
};
