import { getCookie } from 'typescript-cookie';
import {
  CSRF_HIDDEN_FORM_FIELD_NAME,
  CSRF_TOKEN_COOKIE_NAME,
} from '../../api/axiosConfig';
import { useEffect } from 'react';

export const Logout = () => {
  useEffect(() => {
    const form = document.getElementById('logout-form') as HTMLFormElement;
    form.submit();
  }, []);

  return (
    <form
      id="logout-form"
      method="POST"
      action={`${process.env.REACT_APP_BACKEND_URL || ''}/accounts/logout/`}
    >
      <input
        type="hidden"
        name={CSRF_HIDDEN_FORM_FIELD_NAME}
        value={getCookie(CSRF_TOKEN_COOKIE_NAME)}
      />
    </form>
  );
};
