import { QueryFunctionContext } from 'react-query';
import { MonthlyQueryKey } from 'types/MonthlyQueryKey';
import { personnelIncomesApiUrl } from 'utils/paths';
import { CurrencyData } from '../../../api/currencyExchangeRates';
import { Nullable } from '../../../types/nullable';
import { multiplyByCurrencyBidValue } from '../../../utils';
import {
  CurrencyMapped,
  FetchedPersonnelIncome,
  PersonnelIncomeRow,
  PersonnelIncomeRowType,
} from '../types';
import client from '../../../api/axiosConfig';

export const fetchPersonnelIncomes = (
  context: QueryFunctionContext<MonthlyQueryKey>
) => {
  const [, year = '', month = ''] = context.queryKey;

  return client.get<{ results: Array<FetchedPersonnelIncome> }>(
    `${personnelIncomesApiUrl}?month=${month}&year=${year}`
  );
};

export const getSubRows = (row: PersonnelIncomeRow) => row.subRows;

export const checkIsDeleted = (row: PersonnelIncomeRowType): boolean =>
  !!row.original.isDeleted;

export const checkRowIsPrediction = (row: PersonnelIncomeRowType): boolean =>
  checkIsPrediction(row.original);

export const checkIsPrediction = (row: PersonnelIncomeRow): boolean => !row.id;

export const processCurrencyData = (
  incomeId: Nullable<number>,
  income: Array<number>,
  incomePotential: Array<number>,
  incomeAmount: Nullable<string>,
  convertedToPLN: Array<number>,
  convertedToPLNPotential: Array<number>,
  currencyData?: CurrencyData
) => {
  const incomeToPush = Number(incomeAmount);
  const incomeMultipliedByCurrencyBid = currencyData?.bid
    ? multiplyByCurrencyBidValue(incomeAmount, currencyData.bid)
    : 0;

  if (incomeId) {
    income.push(incomeToPush);
    convertedToPLN.push(incomeMultipliedByCurrencyBid || incomeToPush);
  }

  incomePotential.push(incomeToPush);
  convertedToPLNPotential.push(incomeMultipliedByCurrencyBid || incomeToPush);
};

export const processPersonnelIncomeRows = (
  rows: Array<PersonnelIncomeRow> | undefined,
  currencyMappings: Record<string, CurrencyMapped>
) => {
  rows?.forEach((income) => {
    if (income.subRows) {
      income.subRows.forEach((subrow) => {
        if (subrow.projectAmountCurrency !== null) {
          const currencyMapping =
            currencyMappings[subrow.projectAmountCurrency];
          if (currencyMapping) {
            processCurrencyData(
              subrow.id,
              currencyMapping.income,
              currencyMapping.incomePotential,
              subrow.projectAmountAmount,
              currencyMapping.convertedToPLN,
              currencyMapping.convertedToPLNPotential,
              currencyMapping.currencyData
            );
          }
        }
      });
    }
  });
};
