import { FC } from 'react';
import { useMutation } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import client from '../../api/axiosConfig';
import {
  AddEmployeeFormProps,
  Employee,
  EmployeeForCreate,
} from './types/addEmployee';
import { TextInput } from '../../components/form/inputs/textInput';
import {
  amountRequiredValidation,
  requiredValidation,
} from '../../components/form/validation';
import { NumberInput } from '../../components/form/inputs/numberInput';
import { FormSelect } from '../../components/form/inputs/select';
import { employeeApiUrl } from '../../utils/paths';
import { currencyOptions } from '../../utils';
import Drawer from '../../components/common/drawer';
import DrawerOpeningButton from '../../components/common/drawerButton';
import { ApiError } from '../../types/apiError';
import { handleError } from '../../utils/handleError';

export const AddEmployee: FC<AddEmployeeFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  year,
  month,
  personnelCostsQuery,
  positions,
  contractTypes,
}) => {
  const addEmployee = useMutation(
    (data: EmployeeForCreate) => {
      const { positionId, contractTypeId, ...rest } = data;
      return client.post<{ results: Employee }>(employeeApiUrl, {
        ...rest,
        positionId: Number(positionId),
        contractTypeId: Number(contractTypeId),
        month,
        year,
      });
    },
    {
      onSuccess: () => {
        personnelCostsQuery
          .refetch()
          .catch((error: ApiError) => handleError(error));

        closeDrawer();
      },
    }
  );

  const methods = useForm<EmployeeForCreate>({
    defaultValues: { amountCurrency: 'PLN' },
  });

  const handleSubmit = async (data: EmployeeForCreate) =>
    await addEmployee.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj pracownika" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj pracownika"
          isOpen={isOpen}
          onClose={closeDrawer}
          onSubmit={handleSubmit}
        >
          <>
            <TextInput
              name={'firstName'}
              placeholder="Imię"
              autoFocus
              registerProps={requiredValidation}
            />
            <TextInput
              name={'lastName'}
              placeholder="Nazwisko"
              registerProps={requiredValidation}
            />
            <FormSelect
              options={
                positions.data?.data.results.map(({ name, id }) => ({
                  label: name,
                  value: String(id),
                })) || []
              }
              name={'positionId'}
              placeholder="Klasa"
              registerProps={requiredValidation}
            />
            <FormSelect
              options={
                contractTypes.data?.data.results.map(({ name, id }) => ({
                  label: name,
                  value: String(id),
                })) || []
              }
              name={'contractTypeId'}
              placeholder="Typ umowy"
              registerProps={requiredValidation}
            />
            <NumberInput
              name={'amount'}
              placeholder="Kwota"
              registerProps={amountRequiredValidation}
            />
            <FormSelect
              options={currencyOptions}
              name={'amountCurrency'}
              placeholder="Waluta"
              defaultValue={'PLN'}
              registerProps={requiredValidation}
            />
          </>
        </Drawer>
      </FormProvider>
    </>
  );
};
