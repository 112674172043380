import { FC, useState } from 'react';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { MainContainer } from '../../components/mainContainer';
import { Box, HStack, VStack } from '@chakra-ui/react';
import {
  basicContainer,
  pageControlsHStack,
  subHStack,
} from '../../styles/global';
import { YearControl } from '../../components/dateControl/yearControl';
import { MainTable } from '../../components/mainTable';
import { useNonPersonnelCostsAnnual } from './hooks/useNonPersonnelCostsAnnual';
import { AnnualNonPersonnelCost } from './types';
import { useAnnualNonPersonnelCostsColumnDef } from './hooks/useNonPersonnelCostsAnnualColumnDef';
import FinanceCard from '../../components/financeCard';
import BarChart from '../../components/Charts/BarChart';
import Switch from '../../components/Switch';
import { useNonPersonnelCostsAnnualQuery } from './hooks/useNonPersonnelCostsAnnualQuery';
import { CenteredSpinner } from '../../components/centeredSpinner';
import DoughnutPieChart from '../../components/Charts/DoughnutPieChart';
import PageHeading from 'components/common/pageHeading';
import EmptyState from 'components/EmptyState';

const NonPersonnelCostsAnnual: FC = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [currentTableRows, setCurrentTableRows] = useState<
    ReactRowType<AnnualNonPersonnelCost>[]
  >([]);
  const [onlyIncludeAccepted, setOnlyIncludeAccepted] = useState(false);

  const nonPersonnelCostsAnnualQuery = useNonPersonnelCostsAnnualQuery(
    selectedYear,
    onlyIncludeAccepted
  );

  const handleSwitch = () => {
    setOnlyIncludeAccepted(!onlyIncludeAccepted);
    setCurrentTableRows([]);
  };

  const {
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    displayData,
    dataForDoughnutPieChart,
    secondDataForDoughnutPieChart,
  } = useNonPersonnelCostsAnnual(
    nonPersonnelCostsAnnualQuery.data?.data.results,
    currentTableRows
  );

  const { columnInfo } = useAnnualNonPersonnelCostsColumnDef();

  if (nonPersonnelCostsAnnualQuery.isLoading) return <CenteredSpinner />;

  return (
    <MainContainer>
      <VStack sx={basicContainer}>
        <HStack sx={pageControlsHStack}>
          <PageHeading
            text="Koszty nieosobowe"
            dateControl={
              <YearControl
                currentYear={selectedYear}
                setCurrentYear={setSelectedYear}
              />
            }
          />
          <Switch
            label="Pokaż dla zatwierdzonych"
            isChecked={onlyIncludeAccepted}
            onChange={handleSwitch}
          />
        </HStack>
        {!displayData?.length ? (
          <EmptyState />
        ) : (
          <>
            <HStack sx={subHStack}>
              {financeCardsToDisplay.map((card) => (
                <Box key={card.id}>
                  <FinanceCard card={card} />
                </Box>
              ))}
            </HStack>
            <HStack sx={subHStack}>
              <Box>
                <BarChart
                  chartData={dataForBarChart}
                  secondChartData={secondDataForBarChart}
                  mainHeader="Suma kosztów nieosobowych w miesiącach PLN"
                />
              </Box>
              <Box>
                <DoughnutPieChart
                  data={dataForDoughnutPieChart}
                  secondaryData={secondDataForDoughnutPieChart}
                  header="Sumy dla kategorii PLN"
                />
              </Box>
            </HStack>
            <MainTable<AnnualNonPersonnelCost>
              data={displayData}
              columnInfo={columnInfo}
              rowsEditableByButtons={false}
              setCurrentTableRows={setCurrentTableRows}
            />
          </>
        )}
      </VStack>
    </MainContainer>
  );
};

export default NonPersonnelCostsAnnual;
