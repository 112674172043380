import { chartColors } from '../../styles/colors';

export const CHART_COLORS = Object.values(chartColors);

export const NO_BORDER_WIDTH = {
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};
