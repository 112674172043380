import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { useMemo } from 'react';
import {
  AnnualPersonnelIncomeByProjectRow,
  AnnualPersonnelIncomeByProject,
} from '../types/annualPersonnelIncomeByProject';
import { useAmountsInPLN } from './useAmountsInPLN';
import { useDataForBarChart } from '../../../hooks/annualViews/useDataForBarChart';
import { useFinanceCardsToDisplay } from '../../../hooks/annualViews/useFinanceCardsToDisplay';
import { useSumsByCurrency } from '../../../hooks/annualViews/useSumsByCurrency';
import { mapForTableWithSubRows } from '../utils';
import { useFlatRows } from './useFlatRows';
import { useTotalAmounts } from './useTotalAmounts';
import { useDisplaySums } from './useDisplaySums';
import { useDataForPieChart } from './useDataForPieChart';

export const useAnnualPersonnelIncomesByProject = (
  data?: AnnualPersonnelIncomeByProject[],
  filteredData?: ReactRowType<AnnualPersonnelIncomeByProjectRow>[]
) => {
  const dataRow = useMemo(() => mapForTableWithSubRows(data), [data]);

  const filteredDataOriginal = useMemo(
    () =>
      filteredData?.map((annualPersonnelCost) => annualPersonnelCost.original),
    [filteredData]
  );

  const dataWithPLN = useAmountsInPLN(dataRow);
  const filteredDataWithPLN = useAmountsInPLN(filteredDataOriginal);

  const flatData = useFlatRows(dataWithPLN);
  const flatFilteredData = useFlatRows(filteredDataWithPLN);

  const dataByCurrency = useSumsByCurrency(flatData);
  const filteredDataByCurrency = useSumsByCurrency(flatFilteredData);

  const totalAmounts = useTotalAmounts(flatData);
  const totalAmountsFiltered = useTotalAmounts(flatFilteredData);

  const financeCardsToDisplay = useFinanceCardsToDisplay(
    dataByCurrency,
    filteredDataByCurrency,
    totalAmounts,
    totalAmountsFiltered,
    !!filteredDataOriginal?.length
  );

  const { dataForBarChart, secondDataForBarChart } = useDataForBarChart(
    dataByCurrency,
    filteredDataByCurrency,
    !!filteredDataOriginal?.length
  );

  const { dataForPieChart, secondDataForPieChart } = useDataForPieChart(
    dataWithPLN,
    filteredDataWithPLN
  );

  const displayData = useDisplaySums(dataWithPLN);

  return {
    displayData,
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForPieChart,
    secondDataForPieChart,
  };
};
