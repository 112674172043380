import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  KeyboardEvent,
} from 'react';
import { Getter } from '@tanstack/react-table';
import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react';
import { DataFromCellEdit } from '../types';
import { CELL_KEYS_BLACKLIST } from 'consts/index';
import { toDecimal } from 'utils/numbers';

interface Props<T> {
  getValue: Getter<T>;
  originalRow: T;
  id: string;
  dynamicRowIndex: number;
  setFocusedRow: Dispatch<SetStateAction<number>>;
  columnId: string;
  tabFocusableColumnId?: string;
  onCellBlur: (dataFromCellEdit: DataFromCellEdit<T>) => void;
  isModalOpen?: boolean;
  isForNumericValues?: boolean;
  decimalPlaces?: number;
}

export const InputFieldEditableTableCell = <T,>({
  getValue,
  originalRow,
  id,
  columnId,
  tabFocusableColumnId,
  onCellBlur,
  isModalOpen,
  isForNumericValues,
  decimalPlaces = 2,
}: Props<T>) => {
  const initialValue = getValue<string>();
  const [value, setValue] = useState(initialValue);

  const onSubmitHandler = useCallback(
    (nextValue: string) => {
      const isNotEmpty = !isForNumericValues && String(nextValue).length !== 0;
      const isNotZero = isForNumericValues && Number(nextValue) !== 0;
      const shouldSave = (isNotEmpty || isNotZero) && !isModalOpen;

      if (shouldSave) {
        const isSame = isForNumericValues
          ? toDecimal(initialValue, decimalPlaces) ===
            toDecimal(value, decimalPlaces)
          : initialValue === value;

        if (isSame) return;

        onCellBlur({
          originalRow,
          editedColumnId: columnId,
          newValue: value,
        });
      } else {
        setValue(initialValue);
      }
    },
    [
      columnId,
      decimalPlaces,
      initialValue,
      isForNumericValues,
      isModalOpen,
      onCellBlur,
      originalRow,
      value,
    ]
  );

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    return (
      isForNumericValues &&
      CELL_KEYS_BLACKLIST.includes(e.key) &&
      e.preventDefault()
    );
  };

  return (
    <Editable value={value} onSubmit={onSubmitHandler}>
      <EditablePreview
        className={
          columnId === tabFocusableColumnId ? 'tab-focusable-input' : undefined
        }
      />
      <EditableInput
        id={id}
        value={value}
        type={isForNumericValues ? 'number' : 'text'}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyDownHandler}
      />
    </Editable>
  );
};
