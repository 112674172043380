import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { filtersStyling } from '../../styles';
import { StringFilterProps } from './StringFilter.types';

const StringFilter = <T,>({ column, label }: StringFilterProps<T>) => {
  const filterValue = column.getFilterValue() ? column.getFilterValue() : '';

  return (
    <VStack>
      {label ? (
        <Text sx={{ ...filtersStyling.header }}>{label}</Text>
      ) : undefined}
      <InputGroup>
        <Input
          id={`${column.id}-string-filter`}
          value={filterValue as string}
          placeholder={'Szukaj'}
          type="text"
          sx={{ ...filtersStyling.input }}
          onChange={(value) => {
            column.setFilterValue(
              value.target.value.trim() ? value.target.value : undefined
            );
          }}
        />
        <InputRightElement>
          <Icon as={MdSearch} />
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
};

export default StringFilter;
