import { categoryApiUrl } from '../../../utils/paths';
import client from '../../../api/axiosConfig';
import { ColumnInfo } from '../../../components/mainTable/types';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AnnualNonPersonnelCost } from '../types';
import { Category } from '../../settings/pageComponents/categories/types';
import { MONTH_LABELS } from '../../../consts';

export const useAnnualNonPersonnelCostsColumnDef = () => {
  const categoriesQuery = useQuery(
    'categories',
    () => client.get<{ results: Category[] }>(categoryApiUrl),
    {}
  );

  const columnInfo: Array<ColumnInfo<AnnualNonPersonnelCost>> = useMemo(
    () => [
      {
        id: 'costName',
        header: 'Nazwa',
        getRow: (row: AnnualNonPersonnelCost) => row.costName,
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'category',
        header: 'Kategoria',
        getRow: (row: AnnualNonPersonnelCost) => row.categoryName,
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Kategoria',
        columnOptions: categoriesQuery.data?.data.results.map((category) => ({
          value: category.name,
          label: category.name,
        })),
      },
      {
        id: 'totalAnnualAmount',
        header: 'Suma roczna',
        getRow: (row: AnnualNonPersonnelCost) => row.displayTotalAnnualAmount,
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
      },
      ...MONTH_LABELS.map((monthLabel, index) => ({
        id: `amountAmount${index}`,
        header: monthLabel,
        getRow: (row: AnnualNonPersonnelCost) =>
          row.amount[index].displayAmountPLN,
        sortType: 'alphanumeric' as const,
      })),
    ],
    [categoriesQuery]
  );

  return { columnInfo };
};
