import { VStack, HStack } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import Sidebar from './Sidebar';
import Header from 'components/Header';

export const MainContainer: FC<PropsWithChildren> = ({ children }) => (
  <HStack
    backgroundColor="mainBackground"
    alignItems="stretch"
    height="inherit"
  >
    <Sidebar />
    <VStack style={{ width: '100%', marginInlineStart: '0' }}>
      <Header />
      {children}
    </VStack>
  </HStack>
);
