import { QueryClient, useMutation } from 'react-query';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { nonPersonnelCostsApiUrl } from '../../../utils/paths';
import {
  NonPersonnelCostForCreate,
  NonPersonnelCostForDelete,
  NonPersonnelCostForEdit,
  NonPersonnelCostForFetch,
  NonPersonnelCostQueryResult,
} from '../types';
import client from '../../../api/axiosConfig';
import { AxiosMutationResult, EntityWithId } from '../../../types';
import { MonthlyQueryKey } from '../../../types/MonthlyQueryKey';

export const useSingleNonPersonnelCostMutations = (
  queryClient: QueryClient,
  nonPersonnelCostsQuery: NonPersonnelCostQueryResult,
  year: number,
  month: number
) => {
  const queryKey: MonthlyQueryKey = useMemo(
    () => ['nonPersonnelCosts', year, month],
    [month, year]
  );

  const remove: AxiosMutationResult<EntityWithId, NonPersonnelCostForFetch> =
    useMutation(
      (dataForDelete: NonPersonnelCostForDelete) => {
        return client.delete<{ results: NonPersonnelCostForFetch }>(
          `${nonPersonnelCostsApiUrl}${dataForDelete.id}/`
        );
      },
      {
        onSuccess: () => {
          nonPersonnelCostsQuery.refetch().catch((e) => console.error(e));
        },
      }
    );

  const change: AxiosMutationResult<
    NonPersonnelCostForEdit,
    NonPersonnelCostForFetch
  > = useMutation(
    (dataForEdit: NonPersonnelCostForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: NonPersonnelCostForFetch }>(
        `${nonPersonnelCostsApiUrl}${id}/`,
        { ...data }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: NonPersonnelCostForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        oldData.data.results = oldData.data.results.map((nonPersonnelCost) =>
          nonPersonnelCost.id === data.data.results.id
            ? data.data.results
            : nonPersonnelCost
        );

        queryClient.setQueryData(queryKey, oldData);
      },
    }
  );

  const create: AxiosMutationResult<
    NonPersonnelCostForCreate,
    NonPersonnelCostForFetch
  > = useMutation(
    (dataForCreate: NonPersonnelCostForCreate) => {
      return client.post<{ results: NonPersonnelCostForFetch }>(
        nonPersonnelCostsApiUrl,
        { ...dataForCreate }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: NonPersonnelCostForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        const results = data.data.results;

        oldData.data.results = [
          ...oldData.data.results.filter(
            (nonPersonnelCost) =>
              nonPersonnelCost.name !== results.name ||
              nonPersonnelCost.categoryName !== results.categoryName
          ),
          results,
        ];

        queryClient.setQueryData(queryKey, oldData);
      },
    }
  );

  return {
    remove,
    change,
    create,
  };
};
