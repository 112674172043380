import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { monthWorkingHoursStyles } from './style';
import useWorkingHoursQueries from '../../pages/personnelIncomes/hooks/queries/useWorkingHoursQueries';

interface Props {
  date: Date;
}

const MonthWorkingHours: FC<Props> = ({ date }) => {
  const { monthHours } = useWorkingHoursQueries(date);

  return (
    <Box sx={monthWorkingHoursStyles.container}>
      <Box sx={monthWorkingHoursStyles.header}>Godziny robocze w miesiącu</Box>
      <Box sx={monthWorkingHoursStyles.text}>{monthHours}</Box>
    </Box>
  );
};

export default MonthWorkingHours;
