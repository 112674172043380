import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { useMemo } from 'react';
import {
  BarChartData,
  BaseBarChartData,
} from '../../../components/Charts/BarChart/types';
import { isBarChartDataIdentical } from '../../../components/Charts/BarChart/utils';
import { PieChartData } from '../../../components/Charts/PieChart/types';
import { FinanceCardToDisplay } from '../../../components/financeCard/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';
import { sumArrays } from '../../../utils';
import { AnnualPersonnelCost } from '../types/annualPersonnelCost';
import { getTotalAnnualSumsByPosition } from '../utils';

export const useAnnualPersonnelCosts = (
  data?: AnnualPersonnelCost[],
  filteredData?: ReactRowType<AnnualPersonnelCost>[]
) => {
  const totalAmounts = useMemo(
    () =>
      data?.map((annualPersonnelCost) =>
        Number(annualPersonnelCost.totalAnnualAmount)
      ),
    [data]
  );

  const totalAmountsFiltered = useMemo(
    () =>
      filteredData?.map((annualPersonnelCost) =>
        Number(annualPersonnelCost.original.totalAnnualAmount)
      ),
    [filteredData]
  );

  const financeCardsToDisplay: FinanceCardToDisplay[] = [
    {
      id: 1,
      header: 'Suma całkowita w roku',
      income: totalAmounts || [],
      filteredIncome: totalAmountsFiltered || [],
      selectedIncome: [],
      alt: true,
    },
  ];

  const dataForBarChart: BarChartData = useMemo(
    () => ({
      horizontalAxislabels: NUMERIC_MONTH_LABELS,
      datasets: [
        {
          label: 'Suma miesięczna',
          data: data?.length
            ? sumArrays(
                data.map(({ amountAmount }) =>
                  amountAmount.map((value) => Number(value))
                )
              )
            : [],
        },
      ],
    }),
    [data]
  );

  const secondDataForBarChart: BaseBarChartData | undefined = useMemo(() => {
    if (!filteredData?.length) return;

    const temp = {
      datasets: [
        {
          label: 'Suma wyfiltrowanych',
          data: sumArrays(
            filteredData.map(({ original }) =>
              original.amountAmount.map((value) => Number(value))
            )
          ),
        },
      ],
    };

    if (isBarChartDataIdentical(dataForBarChart, temp)) return;

    return temp;
  }, [filteredData, dataForBarChart]);

  const dataForPieChart: PieChartData = useMemo(() => {
    const sums = getTotalAnnualSumsByPosition(data);

    return {
      labels: Object.keys(sums),
      income: Object.values(sums),
    };
  }, [data]);

  const secondDataForPieChart: PieChartData | undefined = useMemo(() => {
    if (!filteredData?.length) return;

    const sums = getTotalAnnualSumsByPosition(
      filteredData.map(({ original }) => original)
    );

    return {
      labels: Object.keys(sums),
      income: Object.values(sums),
    };
  }, [filteredData]);

  return {
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForPieChart,
    secondDataForPieChart,
  };
};
