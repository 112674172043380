import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useKey } from 'react-use';
import { ColumnHelper, Row as ReactRowType } from '@tanstack/react-table';
import { getDecrementedRowIndex, getIncrementedRowIndex } from './utils';
import {
  ColumnGroupInfo,
  ColumnInfo,
  DataFromCellEdit,
  SortingByIsAcceptedState,
  TableExtraButton,
} from './types';
import { getColumnDef } from './components/getColumnDef';
import { wrapGivenColumns } from './components/wrapGivenColumns';

export const useKeys = (
  setFocusedRow: Dispatch<SetStateAction<number>>,
  rowsLength: number,
  isModalOpen?: boolean
) => {
  useKey(
    (e: KeyboardEvent) => !e.shiftKey && e.code === 'Tab',
    (e: KeyboardEvent) => {
      if (isModalOpen) return;
      e.preventDefault();
      setFocusedRow((focusedRow) =>
        getIncrementedRowIndex(focusedRow, rowsLength - 1)
      );
    }
  );

  useKey(
    (e: KeyboardEvent) => e.shiftKey && e.code === 'Tab',
    (e: KeyboardEvent) => {
      if (isModalOpen) return;
      e.preventDefault();
      setFocusedRow((focusedRow) =>
        getDecrementedRowIndex(focusedRow, rowsLength - 1)
      );
    }
  );

  useKey(
    'ArrowUp',
    (e: KeyboardEvent) => {
      if (isModalOpen) return;
      e.preventDefault();
      setFocusedRow((focusedRow) =>
        getDecrementedRowIndex(focusedRow, rowsLength - 1)
      );
    },
    {},
    [isModalOpen]
  );

  useKey(
    'ArrowDown',
    (e: KeyboardEvent) => {
      if (isModalOpen) return;
      e.preventDefault();
      setFocusedRow((focusedRow) =>
        getIncrementedRowIndex(focusedRow, rowsLength - 1)
      );
    },
    {},
    [isModalOpen]
  );
};

export const useSortingByIsAccepted = <T>(
  rows: ReactRowType<T>[],
  checkIsPrediction: (row: ReactRowType<T>) => boolean
) => {
  const [sorting, setSorting] = useState<SortingByIsAcceptedState>(false);

  const onClickSortByAcceptedButton = useCallback(
    // flow is 'no sorting' -> 'not accepted records first' -> 'accepted records first' -> 'no sorting',
    // with consecutive button clicks
    (sorting: SortingByIsAcceptedState) => {
      if (!sorting) {
        setSorting('asc');
      } else if (sorting === 'asc') {
        setSorting('desc');
      } else if (sorting === 'desc') {
        setSorting(false);
      }
    },
    [setSorting]
  );

  const sortedTableRows = useMemo(() => {
    if (!sorting) {
      return rows;
    } else if (sorting === 'desc') {
      return [...rows].sort((tableRow) =>
        checkIsPrediction(tableRow) ? 1 : -1
      );
    } else if (sorting === 'asc') {
      return [...rows].sort((tableRow) =>
        checkIsPrediction(tableRow) ? -1 : 1
      );
    } else {
      return rows;
    }
  }, [sorting, rows, checkIsPrediction]);

  return {
    sortedTableRows,
    onClickSortByAcceptedButton,
    sortingByIsAccepted: sorting,
  };
};

export const useColumns = <T>(
  columnInfo: (ColumnInfo<T> | ColumnGroupInfo<T>)[],
  flatColumnInfo: ColumnInfo<T>[],
  columnHelper: ColumnHelper<T>,
  setFocusedRow: Dispatch<SetStateAction<number>>,
  updateFnForCellEdit: (dataFromCellEdit: DataFromCellEdit<T>) => void,
  checkIsPrediction: (row: ReactRowType<T>) => boolean,
  checkIsDeleted: (row: ReactRowType<T>) => boolean,
  rowsEditableByButtons: boolean,
  deleteInstance?: (row: ReactRowType<T>, data: { isDeleted: boolean }) => void,
  acceptInstance?: (row: ReactRowType<T>) => void,
  unacceptInstance?: (row: ReactRowType<T>) => void,
  tableExtraButton?: TableExtraButton<T>,
  tabFocusableColumnId?: string,
  isModalOpen?: boolean,
  isExpandable?: boolean
) => {
  const givenColumns = useMemo(
    () =>
      columnInfo.map((info) =>
        'columns' in info
          ? columnHelper.group({
              ...info,
              columns: info.columns.map((column) =>
                getColumnDef(
                  column,
                  flatColumnInfo,
                  columnHelper,
                  setFocusedRow,
                  updateFnForCellEdit,
                  tabFocusableColumnId,
                  isModalOpen,
                  isExpandable
                )
              ),
            })
          : getColumnDef(
              info,
              flatColumnInfo,
              columnHelper,
              setFocusedRow,
              updateFnForCellEdit,
              tabFocusableColumnId,
              isModalOpen,
              isExpandable
            )
      ),
    [
      columnHelper,
      columnInfo,
      flatColumnInfo,
      isExpandable,
      isModalOpen,
      setFocusedRow,
      tabFocusableColumnId,
      updateFnForCellEdit,
    ]
  );

  const columns = useMemo(
    () =>
      rowsEditableByButtons
        ? wrapGivenColumns<T>(
            givenColumns,
            checkIsPrediction,
            checkIsDeleted,
            deleteInstance,
            acceptInstance,
            unacceptInstance,
            tableExtraButton
          )
        : givenColumns,
    [
      acceptInstance,
      checkIsDeleted,
      checkIsPrediction,
      deleteInstance,
      givenColumns,
      rowsEditableByButtons,
      tableExtraButton,
      unacceptInstance,
    ]
  );

  return { columns };
};
