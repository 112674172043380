import { useMemo } from 'react';
import { FinanceCardToDisplay } from '../../../components/financeCard/types';
import { sumArrays } from '../../../utils';
import { combineFinanceCardToDisplay, matchArrayLengths } from '../utils';

export const useCostsFinanceCards = (
  personnel: FinanceCardToDisplay[],
  personnelPotential: FinanceCardToDisplay[],
  nonPersonnel: FinanceCardToDisplay[],
  nonPersonnelPotential: FinanceCardToDisplay[]
): FinanceCardToDisplay[] =>
  useMemo(() => {
    const cards = [
      combineFinanceCardToDisplay(
        personnel[0],
        nonPersonnel[3],
        'Suma kosztów w miesiącach'
      ),
      nonPersonnel[3],
      personnel[0],
    ]
      .filter((value) => value !== undefined)
      .map((value, index) => ({ ...value, id: index + 1 }));

    if (nonPersonnelPotential[3] && personnelPotential[0]) {
      cards[0].incomePotential = sumArrays(
        matchArrayLengths(
          nonPersonnelPotential[3].income,
          personnelPotential[0].income
        )
      );
    }

    if (cards[1]) {
      cards[1].header = 'Suma kosztów nieosobowych w roku';
      cards[1].incomePotential = nonPersonnelPotential[3].income || [];
    }

    if (cards[2]) {
      cards[2].header = 'Suma kosztów osobowych w roku';
      cards[2].incomePotential = personnelPotential[0].income || [];
    }

    return cards;
  }, [nonPersonnel, nonPersonnelPotential, personnel, personnelPotential]);
