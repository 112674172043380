import { FC } from 'react';
import { Box, Grid, HStack, VStack } from '@chakra-ui/react';
import { subHStack } from '../../styles/global';
import { MainTable } from '../../components/mainTable';
import { AnnualSummaryCost } from './types';
import FinanceCard from '../../components/financeCard';
import BarChart from '../../components/Charts/BarChart';
import { CenteredSpinner } from '../../components/centeredSpinner';
import { useAnnualSummaryCostsColumnDef } from './hooks/useAnnualSummaryCostsColumnDef';
import { FinanceCardToDisplay } from '../../components/financeCard/types';
import { BarChartData } from '../../components/Charts/BarChart/types';
import { chartsGridStyling } from './styles';

interface Props {
  data?: AnnualSummaryCost[];
  financeCardsToDisplay: FinanceCardToDisplay[];
  dataForBarChart: {
    combined: BarChartData;
    personnel: BarChartData;
    nonPersonnel: BarChartData;
  };
  isLoading: boolean;
}

const AnnualSummaryCosts: FC<Props> = ({
  data,
  financeCardsToDisplay,
  dataForBarChart,
  isLoading,
}) => {
  const { columnInfo } = useAnnualSummaryCostsColumnDef();

  if (isLoading || !data) return <CenteredSpinner />;

  return (
    <VStack width="100%">
      <HStack sx={subHStack}>
        {financeCardsToDisplay.map((card) => (
          <Box key={card.id}>
            <FinanceCard card={card} />
          </Box>
        ))}
      </HStack>
      <Grid sx={chartsGridStyling}>
        <Box>
          <BarChart
            chartData={dataForBarChart.combined}
            mainHeader={'Suma kosztów w miesiącach'}
          />
        </Box>
        <Box>
          <BarChart
            chartData={dataForBarChart.personnel}
            mainHeader={'Suma kosztów osobowych w miesiącach'}
          />
        </Box>
        <Box>
          <BarChart
            chartData={dataForBarChart.nonPersonnel}
            mainHeader={'Suma kosztów nieosobowych w miesiącach'}
          />
        </Box>
      </Grid>
      <MainTable<AnnualSummaryCost>
        data={data}
        columnInfo={columnInfo}
        rowsEditableByButtons={false}
      />
    </VStack>
  );
};

export default AnnualSummaryCosts;
