import { QueryFunctionContext } from 'react-query';
import numeral from 'numeral';
import {
  AnnualPersonnelIncomeByEmployee,
  AnnualPersonnelIncomeByEmployeeRow,
} from './types/annualPersonnelIncomesEmployee';
import client from '../../api/axiosConfig';
import { personnelIncomesAnnualByEmployeeApiUrl } from '../../utils/paths';
import { AnnualQueryKey } from '../../types/AnnualQueryKey';
import { GroupedSums } from '../../types/AnnualViews';
import { sumNumerals, formatAmountNumeral } from '../../utils';

export const fetchPersonnelIncomesAnnualEmployee = (
  context: QueryFunctionContext<AnnualQueryKey>
) => {
  const [, year = '', onlyAccepted] = context.queryKey;
  return client.get<{ results: AnnualPersonnelIncomeByEmployee[] }>(
    `${personnelIncomesAnnualByEmployeeApiUrl}?year=${year}&include_predictions=${
      onlyAccepted ? 0 : 1
    }`
  );
};

export const getDisplaySums = (
  data?: AnnualPersonnelIncomeByEmployeeRow[]
): AnnualPersonnelIncomeByEmployeeRow[] | undefined =>
  data?.map((income) => ({
    ...income,
    displayAverageBillable: income.averageBillable?.format('0'),
    displayTotalAnnualAmount: formatAmountNumeral(income.totalAnnualAmount),
    amount: income.amount.map((value) => ({
      ...value,
      displayAmountPLN: formatAmountNumeral(value.amountPLN),
    })),
  }));

export const getTotalAnnualSumsByName = (
  data?: AnnualPersonnelIncomeByEmployeeRow[]
): GroupedSums => {
  const projects = data?.map(({ projects }) => projects).flat();
  const allNames = [...new Set(projects?.map(({ project }) => project.name))];

  const sums = Object.fromEntries(allNames.map((name) => [name, numeral(0)]));

  projects?.forEach(({ project, totalAnnualAmount }) => {
    if (totalAnnualAmount == null) return;

    sums[project.name].add(totalAnnualAmount.value());
  });

  return sums;
};

export const getAverageBillable = (data?: AnnualPersonnelIncomeByEmployee) => {
  const billables = data?.employee.billable;

  if (!billables?.length) return numeral(0);

  return sumNumerals(billables.map((value) => numeral(value))).divide(
    billables.length
  );
};
