import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  CurrencyData,
  ExchangeRatesTableCResponse,
  exchangeRateTableCEndpointURL,
} from '../../api/currencyExchangeRates';
import { noCredentialsInstance } from '../../api/axiosConfig';
import CurrencyContext from './currencyContext';
import { AxiosError } from 'axios';
import { handleError } from '../../utils/handleError';
import { ApiError } from '../../types/apiError';
import { CurrencyCodes } from '../../utils';

const ALLOWED_NUMBER_OF_RETRIES = 7;

/**
 * Currency provider - provider responsible for downloading currency data from the NBP API, if there is no past date set
 * it tries to download the currency data from today. If there is a past date set, it will download the currency data
 * from the 30th of the given month in the past. If any request fails, the date from the failed request is set back one day
 * and the request is retried, the maximum number of days to go back is 7.
 */
const CurrencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [exchangeRatesProcessed, setExchangeRatesProcessed] = useState<
    CurrencyData[]
  >([]);
  const [currencyDataEUR, setCurrencyDataEUR] = useState<
    CurrencyData | undefined
  >();
  const [currencyDataUSD, setCurrencyDataUSD] = useState<
    CurrencyData | undefined
  >();
  const [lastFailedFetchDate, setLastFailedFetchDate] = useState<
    Date | undefined
  >();
  const [pastDate, setPastDate] = useState<Date | undefined>(undefined);
  const [numberOfRetries, setNumberOfRetries] = useState<number>(0);

  // eslint sees this as unused, which is wrong, as the result is used in onSuccess
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: exchangeRates, refetch: refetchExchangeRates } = useQuery(
    'exchangeRates',
    () =>
      noCredentialsInstance.get<ExchangeRatesTableCResponse[]>(
        `${exchangeRateTableCEndpointURL}${getDateForCurrencyRequest()}/`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (result) => {
        setNumberOfRetries(0);
        setPastDate(undefined);
        if (result?.data) {
          const rates = result.data[0].rates;
          setExchangeRatesProcessed(rates);
          setLastFailedFetchDate(undefined);
        }
      },
      onError: (err: AxiosError) => {
        const dateInRequestUrl = err.config.url
          ?.split('/')
          .filter((element) => {
            return element !== '';
          })
          .pop();
        if (dateInRequestUrl) {
          setLastFailedFetchDate(new Date(dateInRequestUrl));
        }
      },
    }
  );

  const getDateForCurrencyRequest = () => {
    const date = new Date();
    if (lastFailedFetchDate) {
      date.setDate(lastFailedFetchDate.getDate() - 1);
      return date.toISOString().substring(0, 10);
    }

    if (!pastDate) {
      return date.toISOString().substring(0, 10);
    } else {
      const lastDayOfMonthOfPastDate = new Date(
        pastDate.getFullYear(),
        pastDate.getMonth() + 1,
        0
      );
      return lastDayOfMonthOfPastDate.toISOString().substring(0, 10);
    }
  };

  const updateRatesForEURAndUSD = useCallback(() => {
    if (exchangeRatesProcessed) {
      const rateForEUR = exchangeRatesProcessed.find((rate) => {
        return rate.code === CurrencyCodes.Euro;
      });
      if (rateForEUR) {
        setCurrencyDataEUR(rateForEUR);
      }
      const rateForUSD = exchangeRatesProcessed.find((rate) => {
        return rate.code === CurrencyCodes.UnitedStatesDollar;
      });
      if (rateForUSD) {
        setCurrencyDataUSD(rateForUSD);
      }
    }
  }, [exchangeRatesProcessed]);

  useEffect(() => {
    updateRatesForEURAndUSD();
  }, [exchangeRatesProcessed, updateRatesForEURAndUSD]);

  useEffect(() => {
    const fetchData = async () => {
      setNumberOfRetries((prevState) => prevState + 1);
      await refetchExchangeRates();
    };
    if (
      (lastFailedFetchDate || pastDate) &&
      numberOfRetries <= ALLOWED_NUMBER_OF_RETRIES
    ) {
      fetchData().catch((err) => handleError(err as ApiError));
    }
  }, [lastFailedFetchDate, refetchExchangeRates, pastDate, numberOfRetries]);

  return (
    <CurrencyContext.Provider
      value={{
        currencyDataEUR,
        currencyDataUSD,
        setPastDate,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
