import { Box } from '@chakra-ui/react';
import { ReactComponent as RevolveLogo } from '../../assets/images/revolveLogo.svg';
import { colors } from '../../styles/colors';

const Header = () => {
  return (
    <Box height={'4.375rem'} width={'100%'} background={colors.white}>
      <RevolveLogo style={{ margin: '1.2rem 5rem 1rem 1.2rem' }} />
    </Box>
  );
};

export default Header;
