import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../../../../api/axiosConfig';
import { ColumnInfoSettings, SettingsTable } from '../../settingsTable';
import { CenteredSpinner } from '../../../../components/centeredSpinner';
import { Box } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { YearControl } from '../../../../components/dateControl/yearControl';
import { SynchronizeWorkingHours } from './synchronizeWorkingHours';
import { MONTH_LABELS } from '../../../../consts';
import { DataFromCellEdit } from '../../../../components/mainTable/types';
import { WorkingHourForFetch, WorkingHourForEdit } from './types';
import { workingHoursApiUrl } from '../../../../utils/paths';
import { basicContainer } from '../../../../styles/global';

export const WorkingHours: FC = () => {
  const queryClient = useQueryClient();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const workingHoursQuery = useQuery(
    `workingHours - ${selectedYear}`,
    () =>
      client.get<{ results: WorkingHourForFetch[] }>(
        `${workingHoursApiUrl}?year=${selectedYear}`
      ),
    { retry: false }
  );

  const columnInfo: ColumnInfoSettings<WorkingHourForFetch>[] = [
    {
      id: 'month',
      header: 'Nazwa miesiąca',
      sortType: 'text',
      getRow: (row: WorkingHourForFetch) =>
        `${MONTH_LABELS[new Date(row.dateFor).getMonth()]}`,
    },
    {
      id: 'internalNumberOfWorkingHours',
      header: 'Liczba godzin roboczych',
      sortType: 'alphanumeric',
      editable: 'numericInputField' as const,
      getRow: (row: WorkingHourForFetch) =>
        `${row.internalNumberOfWorkingHours} (${row.externalNumberOfWorkingHours})`,
    },
  ];

  const changeWorkingHours = useMutation(
    (dataForEdit: WorkingHourForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: WorkingHourForFetch }>(
        `${workingHoursApiUrl}${id}/`,
        {
          ...data,
        }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: WorkingHourForFetch[] }>
          | undefined = queryClient.getQueryData(
          `workingHours - ${selectedYear}`
        );

        if (!oldData) return;

        oldData.data.results = oldData.data.results.map((workingHours) =>
          workingHours.id === data.data.results.id
            ? data.data.results
            : workingHours
        );

        queryClient.setQueryData(`workingHours - ${selectedYear}`, oldData);
      },
    }
  );

  const mutateFromCellEditWorkingHours = (
    data: DataFromCellEdit<WorkingHourForFetch>
  ) => {
    const dataForMutate: Record<string, string> = {};

    dataForMutate[data.editedColumnId] = data.newValue;
    changeWorkingHours.mutate({ id: data.originalRow.id, ...dataForMutate });
  };

  return workingHoursQuery.isLoading || !workingHoursQuery.data ? (
    <CenteredSpinner />
  ) : (
    <Box sx={basicContainer}>
      <YearControl
        currentYear={selectedYear}
        setCurrentYear={(currentYear) => {
          setSelectedYear(currentYear);
        }}
      />
      <SynchronizeWorkingHours selectedYear={selectedYear} />
      <SettingsTable<WorkingHourForFetch>
        data={workingHoursQuery.data.data.results}
        tabFocusableColumnId="internalNumberOfWorkingHours"
        columnInfo={columnInfo}
        updateFnForCellEdit={mutateFromCellEditWorkingHours}
        isTabControlEnabled={true}
        noDataInfo="Dane nie zsynchronizowane."
      />
    </Box>
  );
};
