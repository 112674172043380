import { useMemo } from 'react';

// adding +1 because getMonth indexing from 0.
export const useYearMonth = (date: Date) =>
  useMemo(
    () => ({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    }),
    [date]
  );
