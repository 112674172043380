import {
  Box,
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Button,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormWrapper } from '../../form/formWrapper';
import {
  saveButtonStyling,
  drawerStyling,
  cancelButtonStyling,
} from './styles';

interface Props<T> extends PropsWithChildren {
  header: string;
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: SubmitHandler<T extends FieldValues ? any : any>;
}

const Drawer = <T,>({
  header,
  isOpen,
  onClose,
  onSubmit,
  children,
}: Props<T>) => (
  <ChakraDrawer
    isOpen={isOpen}
    placement="right"
    onClose={onClose}
    returnFocusOnClose={false}
  >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>{header}</DrawerHeader>
      <DrawerBody>
        <Box sx={{ ...drawerStyling.container }}>
          <FormWrapper onSubmit={onSubmit}>
            {children}
            <Box sx={{ ...drawerStyling.containerButton }}>
              <Button
                variant="outline"
                sx={cancelButtonStyling}
                onClick={onClose}
              >
                Anuluj
              </Button>
              <Button sx={saveButtonStyling} type="submit">
                Zapisz
              </Button>
            </Box>
          </FormWrapper>
        </Box>
      </DrawerBody>
    </DrawerContent>
  </ChakraDrawer>
);

export default Drawer;
