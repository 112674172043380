import { useMemo } from 'react';
import { ColumnInfo } from '../../../components/mainTable/types';
import { currencyOptions } from '../../../utils';
import { RecurringIncomeForFetch } from '../types';

export const useRecurringIncomesColumnDef = () => {
  const columnInfo: Array<ColumnInfo<RecurringIncomeForFetch>> = useMemo(
    () => [
      {
        id: 'name',
        header: 'Nazwa',
        sortType: 'text' as const,
        getRow: (row: RecurringIncomeForFetch) => `${row.name}`,
        filterFn: 'includesString' as const,
        editable: 'textInputField' as const,
      },
      {
        id: 'amountCurrency',
        header: 'Waluta',
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Waluta',
        columnOptions: currencyOptions,
        getRow: (row: RecurringIncomeForFetch) => `${row.amountCurrency}`,
        editable: 'select' as const,
      },
      {
        id: 'amountAmount',
        header: 'Kwota',
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
        getRow: (row: RecurringIncomeForFetch) => row.amountAmount,
        editable: 'numericInputField' as const,
      },
    ],
    []
  );

  return { columnInfo };
};
