import {
  CellContext as TanCellContext,
  ColumnDef,
  RowData,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';
import { InputFieldEditableTableCell } from './inputFieldEditableTableCell';
import { SelectFieldEditableTableCell } from './selectEditableTableCell';
import { ColumnInfo, DataFromCellEdit, EditableColumnType } from '../types';
import { getInputFieldId, getSelectFieldId } from '../utils';

type ExtendedCellContext<TData extends RowData, TValue> = TanCellContext<
  TData,
  TValue
> & {
  dynamicRowIndex?: number;
};

type ExtendedColumnDef<T> = Omit<ColumnDef<T>, 'cell'> & {
  cell: (props: ExtendedCellContext<T, unknown>) => JSX.Element;
};

export function getCellRenderer<T>(
  editable: EditableColumnType,
  setFocusedRow: Dispatch<SetStateAction<number>>,
  updateFnForCellEdit: (dataFromCellEdit: DataFromCellEdit<T>) => void,
  columnInfo: ColumnInfo<T>[],
  tabFocusableColumnId?: string,
  isModalOpen?: boolean
): Partial<ExtendedColumnDef<T>> | undefined {
  if (editable === 'numericInputField' || editable === 'textInputField') {
    return {
      cell: ({ getValue, row, column, dynamicRowIndex }) => (
        <InputFieldEditableTableCell
          getValue={getValue}
          originalRow={row.original}
          id={getInputFieldId<T>(row, column)}
          dynamicRowIndex={dynamicRowIndex === undefined ? -1 : dynamicRowIndex}
          setFocusedRow={setFocusedRow}
          tabFocusableColumnId={tabFocusableColumnId}
          columnId={column.id}
          onCellBlur={updateFnForCellEdit}
          isModalOpen={isModalOpen}
          isForNumericValues={editable === 'numericInputField'}
        />
      ),
    };
  } else if (editable === 'select') {
    return {
      cell: ({ getValue, row, column }) => (
        <SelectFieldEditableTableCell
          getValue={getValue}
          originalRow={row}
          id={getSelectFieldId<T>(row, column)}
          columnId={column.id}
          onCellBlur={updateFnForCellEdit}
          options={
            columnInfo.find((columnInfo) => columnInfo.id === column.id)
              ?.columnOptions || []
          }
        />
      ),
    };
  }
}
