import { extendTheme } from '@chakra-ui/react';
import { blue, colors, gray } from './colors';

export const inputStyle = {
  sizes: {
    lg: {
      field: {
        px: 0,
        height: 10,
        fontSize: 'size9',
        fontWeight: 300,
        option: {
          fontSize: 'size6',
        },
      },
    },
    md: {
      field: {
        height: 6,
        pl: 1.5,
        fontSize: 'size6',
        fontWeight: 300,
      },
    },
  },
  baseStyle: {
    field: {
      borderColor: 'unActiveInput',
      border: 'none',
      _disabled: {
        opacity: 0.6,
      },
      _focusWithin: {
        borderColor: 'activeInput',
        borderWidth: '2px',
      },
      _invalid: {
        borderColor: 'invalidInput',
        borderWidth: '2px',
      },
    },
  },
  defaultProps: {
    variant: 'flushed',
  },
};

export const fonts = {
  heading: 'Poppins, sans-serif',
  body: 'Poppins, sans-serif',
};

export const theme = extendTheme({
  colors: { ...colors, blue, gray },
  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
        backgroundColor: 'mainBackground',
      },
    },
  },
  fonts,
});
