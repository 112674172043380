import { useNonPersonnelCostsAnnual } from '../../nonPersonnelCostsAnnual/hooks/useNonPersonnelCostsAnnual';
import { AnnualNonPersonnelCost } from '../../nonPersonnelCostsAnnual/types';
import { useAnnualPersonnelCosts } from '../../personnelCostsAnnual/hooks/useAnnualPersonnelCosts';
import { AnnualPersonnelCost } from '../../personnelCostsAnnual/types/annualPersonnelCost';
import { useCostsBarChartData } from './useCostsBarChartData';
import { useCostsFinanceCards } from './useCostsFinanceCards';
import { useMappedAnnualSummaryCost } from './useMappedAnnualSummaryCost';

export const useAnnualSummaryCost = (
  personnel?: AnnualPersonnelCost[],
  potentialPersonnel?: AnnualPersonnelCost[],
  nonPersonnel?: AnnualNonPersonnelCost[],
  potentialNonPersonnel?: AnnualNonPersonnelCost[]
) => {
  const personnelTransformed = useAnnualPersonnelCosts(personnel);
  const nonPersonnelTransformed = useNonPersonnelCostsAnnual(nonPersonnel);

  const potentialPersonnelTransformed =
    useAnnualPersonnelCosts(potentialPersonnel);
  const potentialNonPersonnelTransformed = useNonPersonnelCostsAnnual(
    potentialNonPersonnel
  );

  const summaryCosts = useMappedAnnualSummaryCost(
    personnel,
    nonPersonnelTransformed.displayData
  );

  const financeCardsToDisplay = useCostsFinanceCards(
    personnelTransformed.financeCardsToDisplay,
    potentialPersonnelTransformed.financeCardsToDisplay,
    nonPersonnelTransformed.financeCardsToDisplay,
    potentialNonPersonnelTransformed.financeCardsToDisplay
  );

  const dataForBarChart = useCostsBarChartData(
    personnelTransformed.dataForBarChart,
    nonPersonnelTransformed.dataForBarChart
  );

  return {
    data: summaryCosts,
    dataForBarChart,
    financeCardsToDisplay,
  };
};
