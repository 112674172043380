import { CurrencyData } from 'api/currencyExchangeRates';
import { FinanceCardToDisplay } from 'components/financeCard/types';
import { FinanceCardsNonPersonnelCostsData } from 'pages/nonPersonnelCosts/types';
import { CurrencyCodes } from 'utils/index';

export const checkIfContainsFiltered = (arr: FinanceCardToDisplay[]): boolean =>
  arr.some((obj) => obj.filteredIncome.length > 0);

export const createFinanceCardDataStructre =
  (): FinanceCardsNonPersonnelCostsData => ({
    PLN: {
      income: [],
      incomePotential: [],
      totalIncomeConvertedToPLN: [],
      totalIncomeConvertedToPLNPotential: [],
    },
    EUR: { income: [], incomePotential: [] },
    USD: { income: [], incomePotential: [] },
  });

export const createFinanceCardMapping = (
  financeCardData: FinanceCardsNonPersonnelCostsData,
  currencyDataEUR?: CurrencyData,
  currencyDataUSD?: CurrencyData
) => ({
  [CurrencyCodes.PolishZloty]: {
    income: financeCardData.PLN.income,
    incomePotential: financeCardData.PLN.incomePotential,
    convertedToPLN: financeCardData.PLN.totalIncomeConvertedToPLN,
    convertedToPLNPotential:
      financeCardData.PLN.totalIncomeConvertedToPLNPotential,
  },
  [CurrencyCodes.UnitedStatesDollar]: {
    income: financeCardData.USD.income,
    incomePotential: financeCardData.USD.incomePotential,
    convertedToPLN: financeCardData.PLN.totalIncomeConvertedToPLN,
    convertedToPLNPotential:
      financeCardData.PLN.totalIncomeConvertedToPLNPotential,
    currencyData: currencyDataUSD,
  },
  [CurrencyCodes.Euro]: {
    income: financeCardData.EUR.income,
    incomePotential: financeCardData.EUR.incomePotential,
    convertedToPLN: financeCardData.PLN.totalIncomeConvertedToPLN,
    convertedToPLNPotential:
      financeCardData.PLN.totalIncomeConvertedToPLNPotential,
    currencyData: currencyDataEUR,
  },
});
