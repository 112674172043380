import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { DoughnutPieChartProps } from './types';
import useMultiChart from './useMultiChart';
import { doughnutPieChartStyling } from '../styles';
import { isSecondChartVisible } from './utils';

const DoughnutPieChart: FC<DoughnutPieChartProps> = ({
  data,
  secondaryData,
  header,
}) => {
  const displaySecondChart = useMemo(
    () => isSecondChartVisible(data, secondaryData),
    [data, secondaryData]
  );

  const { generateChartConfigurations, generateChartData, getSecondChart } =
    useMultiChart(data, displaySecondChart, secondaryData);

  const doughnutData = generateChartData(data.doughnutData);
  const pieData = generateChartData(data.pieData);
  const { doughnut: doughnutConfig, pie: pieConfig } =
    generateChartConfigurations('main');

  return data.doughnutData.data.length && data.pieData.data.length ? (
    <Box sx={doughnutPieChartStyling.container}>
      <Box sx={doughnutPieChartStyling.containerHeader}>
        {header && <Text sx={doughnutPieChartStyling.header}>{header}</Text>}
      </Box>
      {data && (
        <Flex>
          <Box sx={doughnutPieChartStyling.chart}>
            <Doughnut data={doughnutData} options={doughnutConfig} />
            <Box sx={doughnutPieChartStyling.pie(displaySecondChart)}>
              <Pie data={pieData} options={pieConfig} />
            </Box>
          </Box>
          {getSecondChart()}
        </Flex>
      )}
    </Box>
  ) : null;
};

export default DoughnutPieChart;
