import { FC } from 'react';
import { Box, Grid, HStack, VStack } from '@chakra-ui/react';
import { subHStack } from '../../styles/global';
import { MainTable } from '../../components/mainTable';
import { AnnualSummaryIncome } from './types';
import FinanceCard from '../../components/financeCard';
import BarChart from '../../components/Charts/BarChart';
import { CenteredSpinner } from '../../components/centeredSpinner';
import { useAnnualSummaryIncomesColumnDef } from './hooks/useAnnualSummaryIncomesColumnDef';
import { BarChartData } from '../../components/Charts/BarChart/types';
import { FinanceCardToDisplay } from '../../components/financeCard/types';
import { chartsGridStyling } from './styles';

interface Props {
  data?: AnnualSummaryIncome[];
  financeCardsToDisplay: FinanceCardToDisplay[];
  dataForBarChart: {
    combined: BarChartData;
    personnel: BarChartData;
    reccuring: BarChartData;
  };
  isLoading: boolean;
}

const AnnualSummaryIncomes: FC<Props> = ({
  data,
  financeCardsToDisplay,
  dataForBarChart,
  isLoading,
}) => {
  const { columnInfo } = useAnnualSummaryIncomesColumnDef();

  if (isLoading || !data) return <CenteredSpinner />;

  return (
    <VStack width="100%">
      <HStack sx={subHStack}>
        {financeCardsToDisplay.map((card) => (
          <Box key={card.id}>
            <FinanceCard card={card} />
          </Box>
        ))}
      </HStack>
      <Grid sx={chartsGridStyling}>
        <Box>
          <BarChart
            chartData={dataForBarChart.combined}
            mainHeader={'Suma przychodów w miesiącach'}
          />
        </Box>
        <Box>
          <BarChart
            chartData={dataForBarChart.personnel}
            mainHeader={'Suma przychodów osobowych w miesiącach'}
          />
        </Box>
        <Box>
          <BarChart
            chartData={dataForBarChart.reccuring}
            mainHeader={'Suma przychodów stałych w miesiącach'}
          />
        </Box>
      </Grid>
      <MainTable<AnnualSummaryIncome>
        data={data}
        columnInfo={columnInfo}
        rowsEditableByButtons={false}
      />
    </VStack>
  );
};

export default AnnualSummaryIncomes;
