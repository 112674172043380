import { ChangeEvent, FC, useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Box, Flex, Switch, Text } from '@chakra-ui/react';
import { PieChartProps } from './types';
import usePieChart from './usePieChart';
import { pieChartStyling } from '../styles';

/**
 * PieChart component that accepts three sets of data (one is required). One is required and used to show the main pie chart,
 * the second and alternate second data sets are used for showing the second pie chart. If there are 3 sets of data given,
 * 2 charts are shown and there is a possibility of switching between the second chart shown.
 */

const PieChart: FC<PieChartProps> = ({
  chartData,
  secondChartData,
  mainHeader,
  chartSubHeader,
  secondPieChartSubHeader,
  alternateSecondChartData,
  alternateSecondPieChartSubHeader,
  alternateToggleSourceValue,
  alternateToggleText,
  alternateToggleFunction,
}) => {
  const [reversedShowingOfOtherPieChart, setReversedShowingOfOtherPieChart] =
    useState<boolean>(false);

  const handleReversedShowingOfOtherPieChartChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    alternateToggleFunction
      ? alternateToggleFunction(event.target.checked)
      : setReversedShowingOfOtherPieChart(!event.target.checked);
  };

  const {
    isToggleButtonVisible,
    getOptionsObj,
    getDataObj,
    getSecondPieChart,
    isPieChartDataValid,
  } = usePieChart(
    chartData,
    reversedShowingOfOtherPieChart,
    mainHeader,
    chartSubHeader,
    secondChartData,
    secondPieChartSubHeader,
    alternateSecondPieChartSubHeader,
    alternateSecondChartData
  );

  const shouldComponentBeRendered = useMemo(() => {
    if (isPieChartDataValid(chartData)) {
      return true;
    }

    return !!(secondChartData && isPieChartDataValid(secondChartData));
  }, [chartData, isPieChartDataValid, secondChartData]);

  return shouldComponentBeRendered ? (
    <Box sx={pieChartStyling.container}>
      <Box sx={pieChartStyling.containerHeader}>
        {mainHeader && <Text sx={pieChartStyling.header}>{mainHeader}</Text>}
        {isToggleButtonVisible(
          alternateToggleSourceValue,
          alternateToggleFunction
        ) && (
          <Flex sx={pieChartStyling.toggleContainer}>
            <Switch
              id="switch"
              isChecked={
                alternateToggleSourceValue !== undefined
                  ? alternateToggleSourceValue
                  : !reversedShowingOfOtherPieChart
              }
              onChange={handleReversedShowingOfOtherPieChartChange}
            />
            <Text sx={pieChartStyling.toggleTextContainer}>
              {alternateToggleText
                ? alternateToggleText
                : 'Pokaż dla zaznaczonych'}
            </Text>
          </Flex>
        )}
      </Box>

      <Flex gap="1rem">
        <Box sx={pieChartStyling.chart}>
          <Pie
            datasetIdKey="pie-unfiltered"
            options={getOptionsObj('main')}
            data={getDataObj(chartData)}
          />
        </Box>
        {getSecondPieChart()}
      </Flex>
    </Box>
  ) : null;
};

export default PieChart;
