import { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../../../../../api/axiosConfig';
import { ColumnInfoSettings, SettingsTable } from '../../../settingsTable';
import { AxiosResponse } from 'axios';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { CenteredSpinner } from '../../../../../components/centeredSpinner';
import { Box } from '@chakra-ui/react';
import { AddPosition } from './addPosition';
import { positionApiUrl } from '../../../../../utils/paths';
import { ApiError } from '../../../../../types/apiError';
import { handleError } from '../../../../../utils/handleError';
import { DataFromCellEdit } from '../../../../../components/mainTable/types';
import { Position, PositionForEdit } from '../types';
import { basicContainer } from '../../../../../styles/global';
import EmptyState from 'components/EmptyState/EmptyState';

export const Positions: FC = () => {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(
    'positions',
    () => client.get<{ results: Position[] }>(positionApiUrl),
    {}
  );

  const changePosition = useMutation(
    (dataForEdit: PositionForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: Position }>(`${positionApiUrl}${id}/`, {
        ...data,
      });
    },
    {
      onSuccess: (data) => {
        const oldData: AxiosResponse<{ results: Position[] }> | undefined =
          queryClient.getQueryData('positions');
        if (!oldData) return;
        oldData.data.results = oldData.data.results.map((category) =>
          category.id === data.data.results.id ? data.data.results : category
        );
        queryClient.setQueryData('positions', oldData);
      },
      onError: (error: ApiError) => {
        handleError(error);
      },
    }
  );

  const mutatePosition = (
    row: ReactRowType<Position>,
    data: Partial<Position>
  ) =>
    changePosition.mutate({
      id: row.original.id,
      isDeleted: data.isDeleted,
    });

  const mutateFromCellEditPosition = (data: DataFromCellEdit<Position>) => {
    const dataForMutate: Record<string, string> = {};

    dataForMutate[data.editedColumnId] = data.newValue;
    changePosition.mutate({ id: data.originalRow.id, ...dataForMutate });
  };

  const filterDeletedRows = (
    table: ReactRowType<Position>[]
  ): ReactRowType<Position>[] => {
    return table.filter((row) => !row.original.isDeleted);
  };

  const columnInfo: ColumnInfoSettings<Position>[] = [
    {
      id: 'name',
      header: 'Nazwa klasy',
      sortType: 'text',
      editable: 'textInputField',
      getRow: (row: Position) => `${row.name}`,
    },
    {
      id: 'billable',
      header: 'Billable',
      sortType: 'alphanumeric',
      editable: 'numericInputField',
      getRow: (row: Position) => `${row.billable}`,
    },
  ];

  return isLoading || !data ? (
    <CenteredSpinner />
  ) : (
    <Box sx={basicContainer}>
      <AddPosition />
      {!data.data.results.length ? (
        <EmptyState />
      ) : (
        <SettingsTable<Position>
          data={data.data.results}
          columnInfo={columnInfo}
          updateFnForRowEdit={mutatePosition}
          updateFnForCellEdit={mutateFromCellEditPosition}
          filterDeletedRows={filterDeletedRows}
        />
      )}
    </Box>
  );
};
