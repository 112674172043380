import { FC, useCallback, useState } from 'react';
import { Box, Flex, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { CenteredSpinner } from '../../components/centeredSpinner';
import { MainContainer } from '../../components/mainContainer';
import { MonthYearControl } from '../../components/dateControl/monthYearControl';
import { PersonnelIncomeRow, PersonnelIncomeRowType } from './types';
import { MainTable } from '../../components/mainTable';
import { basicContainer, pageControlsHStack } from '../../styles/global';
import { usePersonnelIncomeColumns } from './hooks/useColumnDef';
import useFinanceCards from './hooks/cards/useFinanceCards';
import usePersonnelIncome from './hooks/usePersonnelIncome';
import usePersonnelIncomeQuery from './hooks/queries/useQuery';
import {
  checkIsDeleted,
  checkRowIsPrediction,
  getSubRows,
} from './hooks/utils';
import { AddIncome } from './addIncome';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { TableExtraButton } from 'components/mainTable/types';
import { doesRowHaveExtraButton } from './utils';
import { financeCardContainer } from '../recurringIncome/styles';
import FinanceCard from '../../components/financeCard';
import { FinanceCardsContainer } from '../../components/financeCard/financeCardsContainer';
import EmptyState from 'components/EmptyState/EmptyState';
import { useYearMonth } from 'hooks/useYearMonth';
import useSinglePersonnelIncomeMutations from './hooks/queries/useSingleRecordMutations';
import useMultiplePersonnelIncomeMutations from './hooks/queries/useMultipleRecordMutations';
import { usePersonnelIncomesActions } from './hooks/queries/useActions';
import PageHeading from 'components/common/pageHeading';
import useEmployeeQuery from './hooks/queries/useEmployeeQuery';
import { useDataForPieChart } from './hooks/chart/useDataForPieChart';
import MonthWorkingHours from 'components/monthWorkingHours';
import { personnelIncomesStyles } from './styles';
import PieChart from 'components/Charts/PieChart';

export const PersonnelIncomes: FC = () => {
  const disclosure = useDisclosure();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedRows, setSelectedRows] = useState<
    Array<PersonnelIncomeRowType>
  >([]);
  const [filteredRows, setFilteredRows] = useState<
    Array<PersonnelIncomeRowType>
  >([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const [showSelectedDataByProjects, setShowSelectedDataByProjects] =
    useState(false);
  const [showSelectedDataByPositions, setShowSelectedDataByPositions] =
    useState(false);

  const { year, month } = useYearMonth(selectedDate);

  const query = usePersonnelIncomeQuery(year, month);
  useEmployeeQuery();

  const afterAction = useCallback(() => {
    setFilteredRows([]);
    setSelectedRows([]);
  }, []);

  const { remove, change, create } = useSinglePersonnelIncomeMutations(
    year,
    month
  );

  const { mutateFromCellEdit, changeIsDeleted, accept, unaccept } =
    usePersonnelIncomesActions(remove, change, create, afterAction);

  const { editSelectedMutation, acceptAllMutation } =
    useMultiplePersonnelIncomeMutations(year, month, query);

  const {
    dataRows,
    baseFinanceCards,
    selectedFinanceCards,
    filteredFinanceCards,
  } = usePersonnelIncome(
    query.data?.data.results ?? [],
    selectedRows,
    filteredRows
  );

  const { columnsInfo, reFetchProjects } = usePersonnelIncomeColumns();

  const cards = useFinanceCards(
    baseFinanceCards,
    filteredFinanceCards,
    selectedFinanceCards
  );

  const {
    confirmedByProjects,
    potentialByProjects,
    confirmedByPositions,
    potentialByPositions,
  } = useDataForPieChart(
    dataRows,
    selectedRows,
    showSelectedDataByPositions,
    showSelectedDataByProjects
  );

  const extraButtonFunc = (row: PersonnelIncomeRowType) => {
    if (row.original.employeeId) {
      setSelectedEmployee(JSON.stringify(row.original.employeeId));
      disclosure.onOpen();
    }
  };

  const checkIsAmountHasEverChanged = (row: PersonnelIncomeRowType): boolean =>
    !!row.original.isAmountChanged;

  const tableExtraButton: TableExtraButton<PersonnelIncomeRow> = {
    doesRowHaveExtraButton,
    extraButtonLabel: 'Dodaj dochód',
    extraButtonFunc,
    buttonIcon: <MdOutlineLibraryAdd />,
  };

  return query.isLoading || !query.data ? (
    <CenteredSpinner />
  ) : (
    <MainContainer>
      <VStack sx={basicContainer}>
        <HStack sx={pageControlsHStack}>
          <PageHeading
            text="Przychody osobowe"
            dateControl={
              <MonthYearControl
                currentDate={selectedDate}
                setCurrentDate={(currentDate) => {
                  setSelectedDate(currentDate);
                  afterAction();
                }}
              />
            }
          />
          <AddIncome
            selectedMonth={month}
            selectedYear={year}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
            afterIncomeCreate={async () => {
              await reFetchProjects();
              void query.refetch();
            }}
            {...disclosure}
          />
        </HStack>
        {!query.data.data.results.length ? (
          <EmptyState />
        ) : (
          <>
            <FinanceCardsContainer>
              {cards &&
                cards.map((card) => (
                  <Box key={card.id} sx={financeCardContainer}>
                    <FinanceCard card={card} />
                  </Box>
                ))}
            </FinanceCardsContainer>
            {confirmedByProjects && confirmedByPositions && (
              <Flex sx={personnelIncomesStyles.chartContainer}>
                <Box sx={personnelIncomesStyles.chartSubContainer}>
                  <PieChart
                    mainHeader="Sumy dla projektów"
                    chartSubHeader="Potwierdzona"
                    secondPieChartSubHeader="Potencjalna"
                    chartData={confirmedByProjects}
                    secondChartData={potentialByProjects}
                    alternateToggleSourceValue={showSelectedDataByProjects}
                    alternateToggleFunction={setShowSelectedDataByProjects}
                  />
                </Box>
                <Box sx={personnelIncomesStyles.chartSubContainer}>
                  <PieChart
                    mainHeader="Sumy dla klas"
                    chartSubHeader="Potwierdzona"
                    secondPieChartSubHeader="Potencjalna"
                    chartData={confirmedByPositions}
                    secondChartData={potentialByPositions}
                    alternateToggleSourceValue={showSelectedDataByPositions}
                    alternateToggleFunction={setShowSelectedDataByPositions}
                  />
                </Box>
                <Box>
                  <MonthWorkingHours date={selectedDate} />
                </Box>
              </Flex>
            )}
            {dataRows && (
              <MainTable<PersonnelIncomeRow>
                data={dataRows}
                columnInfo={columnsInfo}
                tabFocusableColumnId="amountAmount"
                onClickAcceptAllButton={acceptAllMutation.mutate}
                updateFnForMainButton={editSelectedMutation.mutate}
                acceptInstance={accept}
                unacceptInstance={unaccept}
                checkIsAmountHasEverChanged={checkIsAmountHasEverChanged}
                deleteInstance={changeIsDeleted}
                updateFnForCellEdit={mutateFromCellEdit}
                checkIsPrediction={checkRowIsPrediction}
                checkIsDeleted={checkIsDeleted}
                isModalOpen={disclosure.isOpen}
                initialSorting={[{ id: 'lastNameFirstName', desc: false }]}
                isExpandable
                getSubRowsPassed={getSubRows}
                setCurrentTableRows={setFilteredRows}
                setSelectedTableRows={setSelectedRows}
                tableExtraButton={tableExtraButton}
              />
            )}
          </>
        )}
      </VStack>
    </MainContainer>
  );
};
