import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useFormContext, get, FieldError } from 'react-hook-form';
import { InputWrapperProps } from '../types';

export const InputWrapper: FC<InputWrapperProps> = ({
  name,
  label,
  helperTxt,
  isStandalone,
  children,
  variant = 'floating',
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const isInvalid = !!get(errors, name);

  return (
    <FormControl variant={variant} isInvalid={isInvalid}>
      {children}
      <FormLabel color={!isInvalid ? 'inputError' : undefined}>
        {label}
      </FormLabel>
      {!isStandalone && (
        <>
          <FormErrorMessage
            alignItems="top"
            minH={5}
            fontSize="12px"
            m={0}
            mb={'10px'}
          >
            {(get(errors, name) as FieldError)?.message}
          </FormErrorMessage>
          {!get(errors, name) && (
            <FormHelperText minH={5} fontSize="size2" m={0}>
              {helperTxt}
            </FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
};
