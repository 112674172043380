import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AnnualPersonnelIncomeByProjectRow } from '../types/annualPersonnelIncomeByProject';
import {
  ColumnInfo,
  ColumnGroupInfo,
} from '../../../components/mainTable/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';
import client from '../../../api/axiosConfig';
import { Position } from '../../settings/pageComponents/position/types';
import { positionApiUrl } from '../../../utils/paths';

export const useAnnualPersonnelIncomesByProjectColumnDef = () => {
  const positionsQuery = useQuery(
    'positions',
    () => client.get<{ results: Position[] }>(positionApiUrl),
    {}
  );

  const columnInfo: Array<
    | ColumnInfo<AnnualPersonnelIncomeByProjectRow>
    | ColumnGroupInfo<AnnualPersonnelIncomeByProjectRow>
  > = useMemo(
    () => [
      {
        id: 'name',
        header: 'Projekt',
        getRow: (row: AnnualPersonnelIncomeByProjectRow) => row.project?.name,
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'lastNameFirstName',
        header: 'Nazwisko i imię',
        getRow: (row: AnnualPersonnelIncomeByProjectRow) => {
          if (row.lastName && row.firstName) {
            return `${row.lastName} ${row.firstName}`;
          }
        },
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'positionName',
        header: 'Klasa',
        getRow: (row: AnnualPersonnelIncomeByProjectRow) =>
          row.positionName || '',
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Klasa',
        columnOptions: positionsQuery.data?.data.results.map((position) => ({
          value: position.name,
          label: position.name,
        })),
      },
      {
        id: 'totalAnnualAmount',
        header: 'Suma roczna',
        getRow: (row: AnnualPersonnelIncomeByProjectRow) =>
          row.displayTotalAnnualAmount,
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
      },
      ...NUMERIC_MONTH_LABELS.map((monthLabel, index) => ({
        header: monthLabel,
        columns: [
          {
            id: `amountAmount${index}`,
            header: 'Przychód',
            getRow: (row: AnnualPersonnelIncomeByProjectRow) =>
              row.amount[index]?.displayAmountPLN || '',
            sortType: 'alphanumeric' as const,
          },
          {
            id: `amountBillable${index}`,
            header: 'Billable',
            getRow: (row: AnnualPersonnelIncomeByProjectRow) =>
              row.amount[index]?.billable == null
                ? ''
                : `${row.amount[index].billable as number} %`,
            sortType: 'alphanumeric' as const,
          },
        ],
      })),
    ],
    [positionsQuery.data?.data.results]
  );

  return { columnInfo };
};
