import { Dispatch, SetStateAction } from 'react';

export enum IconColumnType {
  simple,
  full,
}

export interface IconColumnProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  expandedRows: Array<number>;
  setExpandedRows: Dispatch<SetStateAction<Array<number>>>;
  type: IconColumnType;
}
