import { Row as ReactRowType, Column } from '@tanstack/react-table';
import { ColumnGroupInfo, ColumnInfo } from './types';

export const flattenColumnInfo = <T>(
  columnInfo: (ColumnInfo<T> | ColumnGroupInfo<T>)[]
) => columnInfo.map((info) => ('columns' in info ? info.columns : info)).flat();

const getFormFieldId = <T>(row: ReactRowType<T>, column: Column<T>) => {
  const parentRow = row.getParentRow();

  return parentRow
    ? `${column.id}-${parentRow.id}-${row.index}`
    : `${column.id}-${row.index}`;
};

export const getInputFieldId = <T>(row: ReactRowType<T>, column: Column<T>) =>
  `input-field-${getFormFieldId(row, column)}`;

export const getSelectFieldId = <T>(row: ReactRowType<T>, column: Column<T>) =>
  `select-field-${getFormFieldId(row, column)}`;

export const getIncrementedRowIndex = (
  currentFocusedRow: number,
  maxRowIndex: number
) => (currentFocusedRow < maxRowIndex ? ++currentFocusedRow : -1);

export const getDecrementedRowIndex = (
  currentFocusedRow: number,
  maxRowIndex: number
) => (currentFocusedRow >= 0 ? --currentFocusedRow : maxRowIndex);
