export const blue = {
  900: '#04153A',
  800: '#062460',
  700: '#083286',
  600: '#083286',
  500: '#0C47C0',
  400: '#195FF1',
  300: '#5287F4',
  200: '#A9C3FA',
  100: '#E2EBFD',
  50: '#F3F7FE',
};

export const gray = {
  300: '#676767',
  200: '#BFBFBF',
  100: '#EEEEEE',
};

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  active: '#0A399A',
  selectBackground: '#E8E8E8',
  unActive: gray[300],
  inputBorder: gray[200],
  text: {
    active: '#2B6CB0',
    unactive: '#4A5568',
  },
  borders: {
    financeCard: blue[100],
    container: blue[100],
  },
  filters: { focus: blue[600], active: blue[100] },
  hover: { button: blue[800], table: blue[100] },
  blue,
  gray,
  error: '#FF0033',
  validationError: '#E53E3E',
  mainBackground: '#F7F9FB',
};

export const chartColors = {
  chartColor1: '#062460',
  chartColor2: '#195FF1',
  chartColor3: '#0C47C0',
  chartColor4: '#0A399A',
  chartColor5: '#04153A',
  chartColor6: '#5287F4',
  chartColor7: '#A9C3FA',
  chartColor8: '#E2EBFD',
};
