import { useQuery } from 'react-query';
import { Position } from '../../settings/pageComponents/position/types';
import { ContractType } from '../../settings/pageComponents/contractType/types';
import { contractTypeApiUrl, positionApiUrl } from '../../../utils/paths';
import client from '../../../api/axiosConfig';
import { useMemo } from 'react';
import { AnnualPersonnelCost } from '../types/annualPersonnelCost';
import { ColumnInfo } from '../../../components/mainTable/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';

export const useAnnualPersonnelCostsColumnDef = () => {
  const positionsQuery = useQuery(
    'positions',
    () => client.get<{ results: Position[] }>(positionApiUrl),
    {}
  );

  const contractTypeQuery = useQuery(
    'contractTypes',
    () => client.get<{ results: ContractType[] }>(contractTypeApiUrl),
    {}
  );

  const columnInfo: Array<ColumnInfo<AnnualPersonnelCost>> = useMemo(
    () => [
      {
        id: 'lastNameFirstName',
        header: 'Nazwisko i imię',
        getRow: (row: AnnualPersonnelCost) =>
          `${row.employeeLastName} ${row.employeeFirstName}`,
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'positionName',
        header: 'Klasa',
        getRow: (row: AnnualPersonnelCost) => row.positionName,
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Klasa',
        columnOptions: positionsQuery.data?.data.results.map((position) => ({
          value: position.name,
          label: position.name,
        })),
      },
      {
        id: 'billable',
        header: 'Billable',
        getRow: (row: AnnualPersonnelCost) =>
          row.positionBillable ? `${row.positionBillable}%` : '-',
        sortType: 'alphanumeric' as const,
      },
      {
        id: 'type',
        header: 'Typ',
        getRow: (row: AnnualPersonnelCost) => row.type,
        sortType: 'text' as const,
        filterFn: 'equalsString' as const,
        filterFieldLabel: 'Typ umowy',
        columnOptions: contractTypeQuery.data?.data.results.map(
          (contractType) => ({
            value: contractType.name,
            label: contractType.name,
          })
        ),
      },
      {
        id: 'totalAnnualAmount',
        header: 'Suma roczna',
        getRow: (row: AnnualPersonnelCost) => row.totalAnnualAmount,
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
      },
      ...NUMERIC_MONTH_LABELS.map((monthLabel, index) => ({
        id: `amountAmount${index}`,
        header: monthLabel,
        getRow: (row: AnnualPersonnelCost) => row.amountAmount[index],
        sortType: 'alphanumeric' as const,
      })),
    ],
    [contractTypeQuery, positionsQuery]
  );

  return { columnInfo };
};
