import { ColumnHelper } from '@tanstack/react-table';
import { ColumnInfo, DataFromCellEdit } from '../types';
import { getCellRenderer } from './getCellRenderer';
import { Dispatch, SetStateAction } from 'react';

export function getColumnDef<T>(
  columnInfo: ColumnInfo<T>,
  allColumnInfo: ColumnInfo<T>[],
  columnHelper: ColumnHelper<T>,
  setFocusedRow: Dispatch<SetStateAction<number>>,
  updateFnForCellEdit: (dataFromCellEdit: DataFromCellEdit<T>) => void,
  tabFocusableColumnId?: string,
  isModalOpen?: boolean,
  isExpandable?: boolean
) {
  const { id, header, getRow, sortType, filterFn, editable } = columnInfo;
  const headerText = header;

  return columnHelper.accessor(getRow, {
    id: id,
    header: ({ table, header }) => {
      if (header.index === 1 && isExpandable) {
        return (
          <>
            <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            />
            {headerText}
          </>
        );
      } else {
        return headerText;
      }
    },
    sortingFn: sortType,
    filterFn: filterFn,
    ...getCellRenderer<T>(
      editable,
      setFocusedRow,
      updateFnForCellEdit,
      allColumnInfo,
      tabFocusableColumnId,
      isModalOpen
    ),
  });
}
