import { CSSObjectWithLabel } from 'react-select';
import { CustomStyleConfig, CustomStylesI } from './types';
import { colors } from 'styles/colors';

const commonStyles: Record<string, CSSObjectWithLabel> = {
  control: {
    'fontSize': '1rem',
    ':hover': { cursor: 'pointer' },
  },
  singleValue: {
    'fontSize': '1rem',
    ':hover': { cursor: 'pointer' },
  },
  menu: { color: 'black' },
  indicatorSeparator: { display: 'none' },
  valueContainer: { padding: 0, display: 'flex' },
  input: { margin: 0, padding: 0 },
};

const tableField: CustomStyleConfig = {
  control: (base) => ({
    ...base,
    ...commonStyles.control,
    'borderColor': 'transparent',
    ':hover': {
      ...base[':hover'],
      ...commonStyles.control[':hover'],
      borderColor: 'transparent',
      backgroundColor: `${colors.selectBackground}`,
    },
    'backgroundColor': 'transparent',
  }),
  singleValue: (base) => ({
    ...base,
    ...commonStyles.singleValue,
    'color': 'inherit',
    'margin': 0,
    ':hover': { ...base[':hover'], ...commonStyles.singleValue[':hover'] },
  }),
  menu: (base) => ({ ...base, ...commonStyles.menu }),
  indicatorSeparator: (base) => ({
    ...base,
    ...commonStyles.indicatorSeparator,
  }),
  valueContainer: (base) => ({ ...base, ...commonStyles.valueContainer }),
  input: (base) => ({ ...base, ...commonStyles.input }),
  dropdownIndicator: (base) => ({
    ...base,
    'color': 'transparent',
    ':hover': { ...base[':hover'], color: 'inherit' },
  }),
};

const filter: CustomStyleConfig = {
  container: (base) => ({ ...base, width: '100%' }),
  control: (base) => ({
    ...base,
    ...commonStyles.control,
    'borderWidth': '1px',
    'borderColor': 'var(--chakra-colors-filters-focus)',
    'borderRadius': 'var(--chakra-radii-md)',
    ':hover': {
      ...base[':hover'],
      ...commonStyles.control[':hover'],
      borderWidth: '1px',
      borderColor: 'var(--chakra-colors-filters-focus)',
      backgroundColor: 'transparent',
    },
  }),
  singleValue: (base) => ({
    ...base,
    ...commonStyles.singleValue,
    'padding': '0 0 0 1rem',
    ':hover': { ...base[':hover'], ...commonStyles.singleValue[':hover'] },
  }),
  menu: (base) => ({ ...base, ...commonStyles.menu }),
  indicatorSeparator: (base) => ({
    ...base,
    ...commonStyles.indicatorSeparator,
  }),
  valueContainer: (base) => ({ ...base, ...commonStyles.valueContainer }),
  input: (base) => ({ ...base, ...commonStyles.input }),
  dropdownIndicator: (base) => ({
    ...base,
    'color': 'var(--chakra-colors-filters-focus)',
    ':hover': {
      ...base[':hover'],
      color: 'var(--chakra-colors-filters-focus)',
    },
  }),
};

export const customStyles: CustomStylesI = { tableField, filter };
