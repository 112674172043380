import { QueryClient, useMutation } from 'react-query';
import { recurringIncomesApiUrl } from '../../../utils/paths';
import client from '../../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { MonthlyQueryKey } from '../../../types/MonthlyQueryKey';
import { useMemo } from 'react';
import {
  RecurringIncomeForCreate,
  RecurringIncomeForDelete,
  RecurringIncomeForFetch,
  RecurringIncomeQueryResult,
  RecurringIncomeForEdit,
} from '../types';

export const useSingleRecurringIncomeMutations = (
  queryClient: QueryClient,
  recurringIncomesQuery: RecurringIncomeQueryResult,
  year: number,
  month: number
) => {
  const queryKey: MonthlyQueryKey = useMemo(
    () => ['recurringIncomes', year, month],
    [month, year]
  );

  const remove = useMutation(
    (dataForDelete: RecurringIncomeForDelete) => {
      return client.delete<{ results: RecurringIncomeForFetch }>(
        `${recurringIncomesApiUrl}${dataForDelete.id}/`
      );
    },
    {
      onSuccess: () => {
        recurringIncomesQuery.refetch().catch((e) => console.error(e));
      },
    }
  );

  const change = useMutation(
    (dataForEdit: RecurringIncomeForEdit) => {
      const { id, ...data } = dataForEdit;
      return client.patch<{ results: RecurringIncomeForFetch }>(
        `${recurringIncomesApiUrl}${id}/`,
        { ...data }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: RecurringIncomeForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        oldData.data.results = oldData.data.results.map((recurringIncome) =>
          recurringIncome.id === data.data.results.id
            ? data.data.results
            : recurringIncome
        );
        queryClient.setQueryData(queryKey, oldData);
      },
    }
  );

  const create = useMutation(
    (dataForCreate: RecurringIncomeForCreate) => {
      return client.post<{ results: RecurringIncomeForFetch }>(
        recurringIncomesApiUrl,
        { ...dataForCreate }
      );
    },
    {
      onSuccess: (data) => {
        const oldData:
          | AxiosResponse<{ results: RecurringIncomeForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        const results = data.data.results;

        oldData.data.results = [
          ...oldData.data.results.filter(
            (recurringIncome) => recurringIncome.name !== results.name
          ),
          results,
        ];

        queryClient.setQueryData(queryKey, oldData);
      },
    }
  );

  return {
    remove,
    change,
    create,
  };
};
