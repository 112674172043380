import { AxiosResponse } from 'axios';
import { Position } from 'pages/settings/pageComponents/position/types';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { PersonnelIncomeRowWithPLN } from './types';
import { getSumsByEmployeePosition } from './utils';

export const useSumsByPositions = (data?: PersonnelIncomeRowWithPLN[]) => {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const positions =
      queryClient.getQueryData<AxiosResponse<{ results: Array<Position> }>>(
        'positions'
      )?.data?.results;

    if (!positions) return;

    const sums = getSumsByEmployeePosition(data);

    const labels: Array<string> = [];
    const income: Array<number> = [];

    Object.keys(sums).forEach((positionId) => {
      labels.push(
        positions.find((position) => position.id.toString() === positionId)
          ?.name || ''
      );

      income.push(sums[positionId].value() || 0);
    });

    return { labels, income };
  }, [data, queryClient]);
};
