import { useQuery } from 'react-query';
import { MonthlyQueryKey } from 'types/MonthlyQueryKey';
import { useMemo } from 'react';
import { fetchPersonnelIncomes } from '../utils';

const usePersonnelIncomeQuery = (year: number, month: number) => {
  const queryKey: MonthlyQueryKey = useMemo(
    () => ['personnelIncomes', year, month],
    [month, year]
  );

  return useQuery(queryKey, fetchPersonnelIncomes, {});
};

export default usePersonnelIncomeQuery;
