import { IconButton, Icon } from '@chakra-ui/react';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { FC } from 'react';
import { ChangeYearButtonProps } from '../../../pages/settings/pageComponents/workingHours/types';
import { buttonIconStyling } from '../../../pages/settings/settingsTable/styles';

export const ChangeYearButton: FC<ChangeYearButtonProps> = ({
  isForNextYear,
  currentYear,
  setCurrentYear,
  ...props
}) => {
  return (
    <IconButton
      fontSize="1.25rem"
      onClick={() => {
        setCurrentYear(isForNextYear ? currentYear + 1 : currentYear - 1);
      }}
      sx={{ ...buttonIconStyling }}
      {...props}
    >
      <Icon
        color="black"
        as={isForNextYear ? MdNavigateNext : MdNavigateBefore}
      />
    </IconButton>
  );
};
