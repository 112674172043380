import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { useMemo } from 'react';
import { AnnualNonPersonnelCost } from '../types';
import { useAmountsInPLN } from './useAmountsInPLN';
import { useSumsByCurrency } from '../../../hooks/annualViews/useSumsByCurrency';
import { useTotalAmounts } from '../../../hooks/annualViews/useTotalAmounts';
import { useFinanceCardsToDisplay } from '../../../hooks/annualViews/useFinanceCardsToDisplay';
import { useDataForBarChart } from '../../../hooks/annualViews/useDataForBarChart';
import { getDisplaySums } from '../../../utils/annualViews';
import { useDataForDoughnutPieChart } from './useDataForDoughnutPieChart';

export const useNonPersonnelCostsAnnual = (
  data?: AnnualNonPersonnelCost[],
  filteredData?: ReactRowType<AnnualNonPersonnelCost>[]
) => {
  const filteredDataOriginal = useMemo(
    () =>
      filteredData?.map(
        (annualNonPersonnelCost) => annualNonPersonnelCost.original
      ),
    [filteredData]
  );

  const dataWithPLN = useAmountsInPLN(data);
  const filteredDataWithPLN = useAmountsInPLN(filteredDataOriginal);

  const dataByCurrency = useSumsByCurrency(data);
  const filteredDataByCurrency = useSumsByCurrency(filteredDataOriginal);

  const totalAmounts = useTotalAmounts(dataWithPLN);
  const totalAmountsFiltered = useTotalAmounts(filteredDataWithPLN);

  const financeCardsToDisplay = useFinanceCardsToDisplay(
    dataByCurrency,
    filteredDataByCurrency,
    totalAmounts,
    totalAmountsFiltered,
    !!filteredDataOriginal?.length
  );

  const { dataForBarChart, secondDataForBarChart } = useDataForBarChart(
    dataByCurrency,
    filteredDataByCurrency,
    !!filteredDataOriginal?.length
  );

  const { dataForDoughnutPieChart, secondDataForDoughnutPieChart } =
    useDataForDoughnutPieChart(dataWithPLN, filteredDataWithPLN);

  const displayData = useMemo(() => getDisplaySums(dataWithPLN), [dataWithPLN]);

  return {
    displayData,
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForDoughnutPieChart,
    secondDataForDoughnutPieChart,
  };
};
