import CurrencyContext from 'contexts/CurrencyContext/currencyContext';
import numeral from 'numeral';
import { PersonnelIncomeRow } from 'pages/personnelIncomes/types';
import { useContext, useMemo } from 'react';
import { CurrencyCodes } from '../../../../utils';
import { PersonnelIncomeRowWithPLN } from './types';

export const useAmountsInPLN = (data?: PersonnelIncomeRow[]) => {
  const { currencyDataEUR, currencyDataUSD } = useContext(CurrencyContext);

  const newData: PersonnelIncomeRowWithPLN[] | undefined = useMemo(
    () =>
      data?.map((row) => {
        const result: PersonnelIncomeRowWithPLN = { ...row };

        if (row.projectAmountCurrency === CurrencyCodes.PolishZloty) {
          result.amountPLN = numeral(row.projectAmountAmount);
        } else if (
          row.projectAmountCurrency === CurrencyCodes.UnitedStatesDollar
        ) {
          if (currencyDataUSD) {
            result.amountPLN = numeral(row.projectAmountAmount).multiply(
              currencyDataUSD.bid
            );
          }
        } else if (row.projectAmountCurrency === CurrencyCodes.Euro) {
          if (currencyDataEUR) {
            result.amountPLN = numeral(row.projectAmountAmount).multiply(
              currencyDataEUR.bid
            );
          }
        }

        return result;
      }),
    [currencyDataEUR, currencyDataUSD, data]
  );

  return newData;
};
