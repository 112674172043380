import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { BarChartProps } from './types';
import { barChartStyling } from '../styles';
import { getBarChartToDisplay, isBarChartDataValid } from './utils';

/**
 * BarChart component that accepts three sets of data (one is required).
 */

const BarChart: FC<BarChartProps> = ({
  chartData,
  secondChartData,
  mainHeader,
  alternateSecondChartData,
  displayAlternateData,
}) =>
  isBarChartDataValid(chartData) ? (
    <Box sx={barChartStyling.container}>
      <Box sx={barChartStyling.containerHeader}>
        {mainHeader && <Text sx={barChartStyling.header}>{mainHeader}</Text>}
      </Box>
      <Flex>
        {getBarChartToDisplay(
          chartData,
          displayAlternateData ? alternateSecondChartData : secondChartData
        )}
      </Flex>
    </Box>
  ) : null;

export default BarChart;
