import { FC } from 'react';
import { useMutation } from 'react-query';
import client from '../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerOpeningButton from '../../components/common/drawerButton';
import { FormSelect } from '../../components/form/inputs/select';
import { TextInput } from '../../components/form/inputs/textInput';
import {
  requiredValidation,
  amountRequiredValidation,
} from '../../components/form/validation';
import { ApiError } from '../../types/apiError';
import { currencyOptions } from '../../utils';
import { handleValidationError } from '../../utils/handleValidationError';
import { recurringIncomesApiUrl } from '../../utils/paths';
import {
  AddRecurringIncomeForm,
  AddRecurringIncomeFormProps,
  RecurringIncomeForFetch,
} from './types';
import Drawer from '../../components/common/drawer';
import { NumberInput } from '../../components/form/inputs/numberInput';
import { MonthlyQueryKey } from '../../types/MonthlyQueryKey';

export const AddRecurringIncome: FC<AddRecurringIncomeFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  queryClient,
  year,
  month,
}) => {
  const addRecurringIncome = useMutation(
    (data: AddRecurringIncomeForm) => {
      return client.post<{ results: RecurringIncomeForFetch }>(
        recurringIncomesApiUrl,
        {
          ...data,
          year,
          month,
        }
      );
    },
    {
      onSuccess: (data) => {
        const queryKey: MonthlyQueryKey = ['recurringIncomes', year, month];

        const oldData:
          | AxiosResponse<{ results: RecurringIncomeForFetch[] }>
          | undefined = queryClient.getQueryData(queryKey);

        if (!oldData) return;

        oldData.data.results = [...oldData.data.results, data.data.results];

        queryClient.setQueryData(queryKey, oldData);

        closeDrawer();
      },
      onError: (error: ApiError) => {
        const fields = ['name', 'amountCurrency', 'amountAmount'];
        handleValidationError(error, methods, fields);
      },
    }
  );

  const methods = useForm<AddRecurringIncomeForm>({
    defaultValues: { amountCurrency: 'EUR' },
  });

  const handleSubmit = async (data: AddRecurringIncomeForm) =>
    await addRecurringIncome.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj nowy przychód" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj nowy przychód"
          isOpen={isOpen}
          onClose={closeDrawer}
          onSubmit={handleSubmit}
        >
          <TextInput
            name="name"
            placeholder="Nazwa przychodu"
            autoFocus
            registerProps={requiredValidation}
          />
          <FormSelect
            name="amountCurrency"
            placeholder="Waluta"
            options={currencyOptions}
            defaultValue="EUR"
            registerProps={requiredValidation}
          />
          <NumberInput
            name="amountAmount"
            placeholder="Kwota"
            registerProps={amountRequiredValidation}
          />
        </Drawer>
      </FormProvider>
    </>
  );
};
