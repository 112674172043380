import { CONTAINERS_PADDING } from 'styles/global';

export const tableBaseStyling = {
  container: { width: '100%', overflow: 'visible' },
  table: { tableLayout: 'auto' },
  head: {
    borderBottomColor: 'black',
    borderBottomWidth: '2px',
  },
  th: { paddingInlineStart: '0.5rem', paddingBlockEnd: '0.5rem' },
  td: { paddingInlineStart: '0.5rem', paddingBlockEnd: '0.5rem' },
  tr: (isPrediction: boolean, isSelected: boolean) => ({
    color: isPrediction ? 'gray.500' : undefined,
    borderBottomWidth: '1px',
    borderBottomColor: 'gray.100',
    backgroundColor: isSelected ? 'blue.100' : 'inherit',
  }),
  iconContainer: { marginRight: '10px' },
};

export const tableContainerStyling = {
  table: {
    width: '100%',
    backgroundColor: 'white',
    padding: CONTAINERS_PADDING,
    borderWidth: '0.0625rem',
    borderRadius: '0.75rem',
    borderColor: 'borders.container',
    minHeight: '31.25rem',
  },
  filters: {
    width: '100%',
    backgroundColor: 'white',
    padding: CONTAINERS_PADDING,
    borderWidth: '0.0625rem',
    borderRadius: '0.75rem',
    borderColor: 'borders.container',
  },
};

export const hstackStyling = (tableButtonsVisible?: boolean) => ({
  width: '100%',
  justifyContent: tableButtonsVisible ? 'space-between' : 'flex-end',
});

export const filtersStyling = {
  container: {
    alignSelf: 'flex-start',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, 1fr)',
    columnGap: '5%',
    maxWidth: '60rem',
  },
  header: { alignSelf: 'flex-start', fontSize: '0.875rem' },
  error: { alignSelf: 'flex-start', fontSize: '0.7rem', color: 'error' },
  input: {
    fontSize: '1rem',
    minWidth: '5rem',
    borderColor: 'filters.active',
    _focus: {
      borderColor: 'filters.focus',
    },
    _hover: {
      borderColor: 'filters.focus',
    },
  },
  inputContainer: { width: 'fit-content' },
  searchFieldContainer: { alignSelf: 'flex-end', width: 'fit-content' },
};

export const tableHeadInnerStyling = {
  whiteSpace: 'nowrap',
  textAlign: 'center',
  width: '100%',
};
