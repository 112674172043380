import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { defaultValidation } from '../../validation';
import { AutocompleteInputProps, Option } from './AutocompleteInput.types';
import { InputWrapper } from '../../inputWrapper';
import { createOption } from './AutocompleteInput.helpers';
import {
  NO_OPTIONS_DEFAULT,
  PLACEHOLDER_DEFAULT,
} from './AutocompleteInput.consts';
import { colors } from 'styles/colors';

const AutocompleteInput: FC<AutocompleteInputProps> = ({
  name,
  options,
  onCreateOption,
  placeholder = PLACEHOLDER_DEFAULT,
  noOptionsPlaceholder = NO_OPTIONS_DEFAULT,
  label,
  registerProps = defaultValidation,
  onChangeHandler,
}) => {
  const { control, setValue } = useFormContext();

  return (
    <InputWrapper name={name} label={label}>
      <Controller
        rules={registerProps}
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CreatableSelect
            options={options}
            value={value as Option}
            onCreateOption={(inputValue) => {
              onCreateOption(inputValue);
              setValue(name, createOption(inputValue));
              if (onChangeHandler) onChangeHandler(inputValue);
            }}
            onChange={(newValue) => {
              onChange(newValue);
              if (onChangeHandler) onChangeHandler(newValue);
            }}
            formatCreateLabel={(inputValue) => `Dodaj "${inputValue}"`}
            noOptionsMessage={() => noOptionsPlaceholder}
            placeholder={placeholder}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: error
                  ? `${colors.validationError}`
                  : `${colors.inputBorder}`,
                boxShadow: error
                  ? `0 0 0 1px ${colors.validationError}`
                  : 'none',
              }),
            }}
          />
        )}
      />
    </InputWrapper>
  );
};

export default AutocompleteInput;
