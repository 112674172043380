import { useMemo } from 'react';
import { AnnualRecurringIncome } from '../types/annualRecurringIncome';
import { ColumnInfo } from '../../../components/mainTable/types';
import { NUMERIC_MONTH_LABELS } from '../../../consts';

export const useAnnualRecurringIncomesColumnDef = () => {
  const columnInfo: Array<ColumnInfo<AnnualRecurringIncome>> = useMemo(
    () => [
      {
        id: 'name',
        header: 'Nazwa',
        getRow: (row: AnnualRecurringIncome) => row.name,
        sortType: 'text' as const,
        filterFn: 'includesString' as const,
      },
      {
        id: 'totalAnnualAmount',
        header: 'Suma roczna',
        getRow: (row: AnnualRecurringIncome) => row.displayTotalAnnualAmount,
        sortType: 'alphanumeric' as const,
        filterFn: 'inNumberRange' as const,
        filterFieldLabel: 'Zakres kwot',
      },
      ...NUMERIC_MONTH_LABELS.map((monthLabel, index) => ({
        id: `amountAmount${index}`,
        header: monthLabel,
        getRow: (row: AnnualRecurringIncome) =>
          row.amount[index].displayAmountPLN,
        sortType: 'alphanumeric' as const,
      })),
    ],
    []
  );

  return { columnInfo };
};
