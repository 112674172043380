import { FC, PropsWithChildren } from 'react';
import { useQuery } from 'react-query';

import UserContext from './userContext';
import client from '../../api/axiosConfig';
import { loggedUserApiUrl } from '../../utils/paths';
import { User } from '../../types/user';

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading } = useQuery(
    'loggedUser',
    () => client.get<{ results: User }>(loggedUserApiUrl),
    {}
  );

  return (
    <UserContext.Provider
      value={{
        loggedUser: data?.data.results,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
