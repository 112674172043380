import { FC, useState } from 'react';
import { Row as ReactRowType } from '@tanstack/table-core/build/lib/types';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { AnnualPersonnelIncomeByEmployeeRow } from './types/annualPersonnelIncomesEmployee';
import { MainContainer } from '../../components/mainContainer';
import { YearControl } from '../../components/dateControl/yearControl';
import { useAnnualPersonnelIncomeByEmployeeRowColumnDef } from './hooks/useAnnualPersonnelIncomeEmployeeColumnDef';
import { MainTable } from '../../components/mainTable';
import { useAnnualPersonnelIncomesByEmployee } from './hooks/useAnnualPersonnelIncomesEmployee';
import { usePersonnelIncomesAnnualEmployeeQuery } from './hooks/usePersonnelIncomesAnnualEmployeeQuery';
import { mainVStack, pageControlsHStack, subHStack } from '../../styles/global';
import Switch from '../../components/Switch';
import FinanceCard from '../../components/financeCard';
import PieChart from '../../components/Charts/PieChart';
import BarChart from '../../components/Charts/BarChart';
import { CenteredSpinner } from '../../components/centeredSpinner';
import PageHeading from 'components/common/pageHeading';
import EmptyState from 'components/EmptyState/EmptyState';

export const PersonnelIncomesByEmployeeAnnual: FC = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [onlyIncludeAccepted, setOnlyIncludeAccepted] = useState(false);
  const [currentTableRows, setCurrentTableRows] = useState<
    ReactRowType<AnnualPersonnelIncomeByEmployeeRow>[]
  >([]);
  const data = usePersonnelIncomesAnnualEmployeeQuery(
    selectedYear,
    onlyIncludeAccepted
  );

  const { columnInfo } = useAnnualPersonnelIncomeByEmployeeRowColumnDef();

  const {
    displayData,
    financeCardsToDisplay,
    dataForBarChart,
    secondDataForBarChart,
    dataForPieChart,
    secondDataForPieChart,
  } = useAnnualPersonnelIncomesByEmployee(
    data.data?.data.results,
    currentTableRows
  );

  if (data.isLoading || !displayData) return <CenteredSpinner />;

  return (
    <MainContainer>
      <VStack sx={mainVStack}>
        <HStack sx={pageControlsHStack}>
          <PageHeading
            text="Przychody osobowe"
            dateControl={
              <YearControl
                currentYear={selectedYear}
                setCurrentYear={setSelectedYear}
              />
            }
          />
          <Switch
            label="Pokaż dla zatwierdzonych"
            isChecked={onlyIncludeAccepted}
            onChange={() => {
              setOnlyIncludeAccepted(!onlyIncludeAccepted);
              setCurrentTableRows([]);
            }}
          />
        </HStack>
        {!displayData.length ? (
          <EmptyState />
        ) : (
          <>
            <HStack sx={subHStack}>
              {financeCardsToDisplay.map((card) => (
                <Box key={card.id}>
                  <FinanceCard card={card} />
                </Box>
              ))}
            </HStack>
            <HStack sx={subHStack}>
              <Box>
                <BarChart
                  chartData={dataForBarChart}
                  secondChartData={secondDataForBarChart}
                  mainHeader="Suma przychodów osobowych w miesiącach PLN"
                />
              </Box>
              <Box>
                <PieChart
                  chartData={dataForPieChart}
                  secondChartData={secondDataForPieChart}
                  mainHeader="Sumy dla projektów PLN"
                  chartSubHeader="Suma roczna"
                  secondPieChartSubHeader="Suma wyfiltrowanych"
                />
              </Box>
            </HStack>
            <MainTable<AnnualPersonnelIncomeByEmployeeRow>
              data={displayData}
              columnInfo={columnInfo}
              rowsEditableByButtons={false}
              setCurrentTableRows={setCurrentTableRows}
            />
          </>
        )}
      </VStack>
    </MainContainer>
  );
};
