import { useMemo } from 'react';
import { checkIsPrediction } from '../utils';
import { PersonnelIncomeRowWithPLN } from './types';

export const useRowsSplitByIsPrediction = (
  data?: Array<PersonnelIncomeRowWithPLN>
) =>
  useMemo(
    () =>
      data && {
        confirmed: data.filter((row) => !checkIsPrediction(row)),
        potential: data,
      },
    [data]
  );
