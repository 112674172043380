import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../../api/axiosConfig';
import { AxiosResponse } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../../../../components/form/inputs/textInput';
import { requiredValidation } from '../../../../../components/form/validation';
import { ApiError } from '../../../../../types/apiError';
import { handleValidationError } from '../../../../../utils/handleValidationError';
import { categoryApiUrl } from '../../../../../utils/paths';
import { CategoryForCreate, Category } from '../types';
import Drawer from '../../../../../components/common/drawer';
import DrawerOpeningButton from '../../../../../components/common/drawerButton';

export const AddCategory: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryClient = useQueryClient();
  const addCategory = useMutation(
    (data: CategoryForCreate) => {
      return client.post<{ results: Category }>(categoryApiUrl, {
        ...data,
      });
    },
    {
      onSuccess: (data) => {
        const oldData: AxiosResponse<{ results: Category[] }> | undefined =
          queryClient.getQueryData('categories');

        if (!oldData) return;

        oldData.data.results = [...oldData.data.results, data.data.results];
        queryClient.setQueryData('categories', oldData);

        closeDrawer();
      },
      onError: (error: ApiError) => {
        const fields = ['name'];
        handleValidationError(error, methods, fields);
      },
    }
  );

  const methods = useForm<CategoryForCreate>({});

  const handleSubmit = async (data: CategoryForCreate) =>
    await addCategory.mutateAsync(data);

  const closeDrawer = () => {
    onClose();
    methods.reset();
  };

  return (
    <>
      <DrawerOpeningButton text="Dodaj nową kategorię" onClick={onOpen} />
      <FormProvider {...methods}>
        <Drawer
          header="Dodaj nową kategorię"
          onSubmit={handleSubmit}
          isOpen={isOpen}
          onClose={closeDrawer}
        >
          <TextInput
            name={'name'}
            label={'Nazwa kategorii'}
            autoFocus
            registerProps={requiredValidation}
          />
        </Drawer>
      </FormProvider>
    </>
  );
};
